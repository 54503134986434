import { rest } from "msw";
import { api_url } from "@constants/base_url";
import { data, addCourse } from "../data/courses";

const getAllUrl = api_url + "course/all"; // api_url + "course/all?page=1";
const getAllHandler = rest.get(getAllUrl, (req, res, ctx) => {
  return res(ctx.json({ data }));
});

const createCourseUrl = api_url + "course/store";
const createCourseHandler = rest.post(createCourseUrl, (req, res, ctx) => {
  console.error("body", req.body);
  addCourse(req.body);
  return res(ctx.status(201));
});

const deleteCourseUrl = api_url + "course/delete/:id";
const deleteCourseHandler = rest.post(deleteCourseUrl, (req, res, ctx) => {
  const { id } = req.params;
  const course = data.find((course) => course.id === Number(id));
  if (course == null) return res(ctx.status(404));
  const index = data.indexOf(course);
  data.splice(index, 1);
  return res(ctx.status(200));
});

const editCourseUrl = api_url + "course/update";
const editCourseHandler = rest.post(editCourseUrl, (req, res, ctx) => {
  const { id } = req.body;
  const course = data.find((course) => course.id === Number(id));
  if (course == null) return res(ctx.status(404));
  const index = data.indexOf(course);

  for (const [key, value] of Object.entries(req.body)) {
    data[index][key] = value;
  }

  return res(ctx.status(200));
});

export const handlers = [
  getAllHandler,
  createCourseHandler,
  deleteCourseHandler,
  editCourseHandler,
];
