import { Box, Typography } from "@mui/material";
import learning from "@assets/images/Learning-bro.png";
import { COLORS } from "@constants/Colors/colors";

export default function Introduction() {
  return (
    <>
      <Box position={"relative"} sx={{ overflowX: "hidden", color: "white" }}>
        <div
          style={
            window.innerWidth > 600
              ? { display: "flex", width: "100%" }
              : {
                  display: "flex",
                  flexDirection: "column-reverse",
                  width: "100%",
                }
          }
        >
          <Box
            sx={
              window.innerWidth > 600
                ? {
                    width: "60%",
                    bgcolor: COLORS.SECONDARY.MAIN,
                    padding: "40px",
                  }
                : { bgcolor: COLORS.SECONDARY.MAIN, padding: "10px" }
            }
          >
            <Typography fontSize={window.innerWidth > 600 ? 60 : 40} mb={6}>
              من الصفر حنقفل لك على الإنجليزي
            </Typography>
            <Typography
              fontSize={window.innerWidth > 600 ? 18 : 16}
              lineHeight={2.5}
            >
              فوّق في تعلّم اللغة الإنجليزية مع مدرّسين خبراء ومحترفين! لدينا
              فريق من المعلّمين الذين يتحدثون اللغة الإنجليزية كلغتهم الأولى
              ويمتلكون خبرة واسعة في تعليمها للطلاب حول العالم
            </Typography>
          </Box>
          <Box
            sx={
              window.innerWidth > 600
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "40%",
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
            }
          >
            <img src={learning} width={window.innerWidth > 600 ? 400 : 300} />
          </Box>
        </div>
      </Box>
    </>
  );
}
