import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { COLORS } from "@constants/Colors/colors";
import { HashLoader } from "react-spinners";
import { ExamCreator } from "../ExamCreator/ExamCreator";
import { CourseExamBox } from "./CourseExamBox/CourseExamBox";

export const CourseExams = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const { data, isPending, isError } = useCustomQuery({
    url: `exam/getCourseExamsAdmin?course_id=${id}`,
    key: [`exam of course`, id],
    select: (data) => data?.data?.data?.data,
  });

  // console.log(data)
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: 30,
          color: COLORS.PRIMARY.MAIN,
          fontFamily: "Noor-bold",
        }}
      >
        الإمتحانات
      </Typography>
      <Button
        onClick={() => setOpen(true)}
        sx={{
          bgcolor: COLORS.PRIMARY.MAIN,
          color: COLORS.WHITE,
          "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
        }}
      >
        أضف إمتحان
      </Button>
      {isPending ? (
        <Box marginTop={2}>
          <HashLoader size={20} color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : isError ? (
        <></>
      ) : data?.length == 0 ? (
        <Typography sx={{ fontSize: 30, fontFamily: "Noor-bold" }}>
          لا يوجد إمتحانات
        </Typography>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gap: 1,
            width: "100%",
          }}
        >
          {data?.map((item) => (
            <CourseExamBox item={item} />
          ))}
        </Box>
      )}
      <ExamCreator
        type={"Course"}
        open={open}
        setOpen={setOpen}
        closeParent={() => setOpen(false)}
        id={id}
      />
    </Box>
  );
};
