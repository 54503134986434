import { api_url } from "@constants/base_url";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import examIcon from "@assets/adminImages/record.png";
import recordIcon from "@assets/adminImages/record-icon.jpg";

export const RecordPicker = ({ exam, setExam, index, defaultImage }) => {
  const [recordSrc, setRecordSrc] = useState({
    url: exam?.record ? examIcon : recordIcon,
  });
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "audio/mpeg": [".mp3"],
    },
    onDrop: (acceptedFiles) => {
      setExam((prevExam) => ({
        ...prevExam,
        record: acceptedFiles,
      }));
      setRecordSrc({
        file: acceptedFiles[0],
      });
    },
  });
  return (
    <>
      <div
        style={{
          width: "250px",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
        {...getRootProps()}
      >
        <img
          src={acceptedFiles[0] ? recordIcon : examIcon}
          style={{ width: 100, cursor: "pointer", objectFit: "cover" }}
          alt=""
        />
      </div>
      <input {...getInputProps()} />
    </>
  );
};
