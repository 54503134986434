import { createBrowserRouter } from "react-router-dom";
import Login from "@/Admin/Pages/Auth/Login/Login";
import Error from "./Error";
import SideNav from "@/Admin/Layout/SideNav/SideNav";
import CoursesHomePage from "@/Admin/Pages/Courses/CoursesHomePage";
import StudentsHomePage from "@/Admin/Pages/Students/StudentsHomePage";
import { BookStorePage } from "@/Admin/Pages/BookStore/BookStorePage";
import CoursesCodesPage from "@/Admin/Pages/CoursesCodes/CoursesCodesPage";
import NotificationsPage from "@/Admin/Pages/Notifications/NotificationsPage";
import SupervisorsPage from "@/Admin/Pages/Supervisors/SupervisorsPage";
import { Home } from "@/Admin/Pages/Home/Home";
import { DashboardHome } from "@/Admin/Pages/DahsboardHome/DashboardHome";
import CourseDetails from "@/Admin/Pages/CoursesCodes/CourseDetails";
import PrintCodes from "@/Admin/Pages/CoursesCodes/printCodes";
import { UserHome } from "@/Admin/User/UserHome/UserHome";
import { Main } from "@/Admin/User/Main/Main";
import { UserCourse } from "@/Admin/User/UserCourse/UserCourse";
import { MySubscriptions } from "@/Admin/User/MySubscriptions/MySubscriptions";
import { AllCourses } from "@/Admin/User/AllCourses/AllCourses";
import { Module } from "@/Admin/User/Module/Module";
import { Course } from "@/Admin/Pages/Course/Course";
import BookOrders from "@/Admin/Pages/BookStore/BookOrders/BookOrders";
import { CourseOverallExam } from "@/Admin/User/CourseOverallExam/CourseOverallExam";
import MyStats from "@/Admin/User/MyStats/MyStats";
import { MyDetails } from "@/Admin/User/MyDetails/MyDetails";
import { UserBookStore } from "@/Admin/User/UserBookStore/UserBookStore";
import { UserBookOrders } from "@/Admin/User/UserBookOrders/UserBookOrders";
import { features } from "./features";
import DeleteAccountFake from "@/Admin/User/DeleteAccountFake";
import PrivacyPolicy from "@/Admin/User/Privacy";
import Signup from "@/Admin/Pages/Auth/Signup/Signup";
import PlacementTestPage from "@/Admin/Pages/PlacementTest/PlacementTestPage";
import LevelQuestion from "@/Admin/Pages/PlacementTest/LevelQuestion";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <Error />,
  },

  { path: "privacy", element: <PrivacyPolicy />, errorElement: <Error /> },
  {
    path: "user-profile",
    element: <DeleteAccountFake />,
    errorElement: <Error />,
  },
  {
    path: "/dashboard",
    element: <SideNav />,
    errorElement: <Error />,
    children: [
      { path: "", element: <DashboardHome /> },
      { path: "courses", element: <CoursesHomePage /> },
      { path: "courses/course/:id", element: <Course /> },
      { path: "students", element: <StudentsHomePage /> },
      { path: "book-store", element: <BookStorePage /> },
      { path: "book-store/order/:id", element: <BookOrders /> },
      { path: "coursesCodes", element: <CoursesCodesPage /> },
      { path: "coursesCodes/course/:id", element: <CourseDetails /> },
      { path: "notifications", element: <NotificationsPage /> },
      { path: "supervisors", element: <SupervisorsPage /> },
      { path: "placement-test", element: <PlacementTestPage /> },
      { path: "levels/:id", element: <LevelQuestion /> },
      {
        path: "coursesCodes/course/:id/printCodes",
        element: <PrintCodes />,
      },
    ],
  },
  ...(() => {
    if (features.student) {
      return [
        {
          path: "/home",
          element: <UserHome child={Main} />,
          errorElement: <Error />,
        },
        {
          path: "/signup",
          element: <Signup />,
          errorElement: <Error />,
        },
        {
          path: "/my-subscriptions",
          element: <UserHome child={MySubscriptions} />,
          errorElement: <Error />,
        },
        {
          path: "/all-courses",
          element: <UserHome child={AllCourses} />,
          errorElement: <Error />,
        },
        {
          path: "/my-stats",
          element: <UserHome child={MyStats} />,
          errorElement: <Error />,
        },
        {
          path: "/my-details",
          element: <UserHome child={MyDetails} />,
          errorElement: <Error />,
        },
        {
          path: "/my-orders",
          element: <UserHome child={UserBookOrders} />,
          errorElement: <Error />,
        },
        {
          path: "/book-store",
          element: <UserHome child={UserBookStore} />,
          errorElement: <Error />,
        },
        {
          path: "/course/:id",
          element: <UserHome child={UserCourse} />,
          errorElement: <Error />,
        },
        {
          path: "/course/:id/course-taps/:mid",
          element: <UserHome child={Module} />,
          errorElement: <Error />,
        },
        {
          path: "/course/:id/course-overall-exam/:eid",
          element: <UserHome child={CourseOverallExam} />,
          errorElement: <Error />,
        },
      ];
    } else {
      return [];
    }
  })(),
]);
