import { COLORS } from '@constants/Colors/colors'
import { Box, Typography, IconButton } from '@mui/material'
import React, { useState } from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { EditBar } from './EditBar';
import { DeleteBar } from './DeleteBar';

export const Bar = ({message}) => {
    const [edit, setEdit] = useState(false)
    const [remove, setRemove] = useState(false)
    return (
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between",width: "100%", padding:2, bgcolor: COLORS.BOX, marginY:2, borderRadius:2, boxShadow: `0px 6px 0px ${COLORS.PRIMARY.MAIN}`}}>
            <Typography fontSize={20} color={COLORS.DARK_GRAY}>{message?.body}</Typography>
            <Box sx={{display:"flex", gap:1}}>
            <IconButton
                onClick={()=>{
                    setEdit(true)
                }}
                sx={{
                    bgcolor: "#01c46e",
                    "&:hover": { bgcolor: "#01c46e" },
                    width: 28,
                    height: 28,
                }}
                >
                <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
                sx={{
                bgcolor: COLORS.DANGER,
                "&:hover": { bgcolor: COLORS.DANGER },
                width: 28,
                height: 28,
                }}
                onClick={()=>{
                    setRemove(true)
                }}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
            </Box>
            <EditBar open={edit} setOpen={setEdit} id={message?.id} body={message?.body}/>
            <DeleteBar open={remove} setOpen={setRemove} id={message?.id} />
        </Box>
    )
}
