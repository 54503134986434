import React from "react";
import Chart from "./Chart/Chart";
import Numbers from "./Numbers/Numbers";
import Notifications from "./Notifications/Notifications";
import { Box } from "@mui/material";
import { Bars } from "./Bars/Bars";
import { features } from "@config/features";

export const DashboardHome = () => {
  return (
    <Box sx={{ fontFamily: "Noor-regular" }}>
      <Chart />
      <Numbers />
      <Box sx={{ display: "flex", gap: 2 }}>
        <Bars />
        {features.notifications && <Notifications />}
      </Box>
    </Box>
  );
};
