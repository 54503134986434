import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import React, { createContext, useContext, useEffect, useState } from 'react';

const UserSubsContext = createContext();

export const useUserSubsContext = () => {
  return useContext(UserSubsContext);
};

export const UserSubsContextProvider = ({ children }) => {

  const [page, setPage] = useState(1)
  const [ids, setIds] = useState([])
  const { data, isPending, isSuccess } = useCustomQuery({
    key: ["mySubscription", page],
    url:`subscription/getUserSubscriptions?page=${page}`,
    select: (data) => data,
  });
  useEffect(()=>{
    if(isSuccess){
      setIds(ids.concat(data?.data?.data?.data?.map(item => item?.course_id)))
    }
  },[isSuccess])

  // You can include additional state or functions related to the context here

  const contextValue = {
    data,
    isPending,
    ids: ids.concat(data?.data?.data?.data?.map(item => item?.course_id)),
    setPage,
    page,
    totalPages: data?.data?.data?.last_page
    // Add any other values or functions you want to expose
  };

  return <UserSubsContext.Provider value={contextValue}>{children}</UserSubsContext.Provider>;
};
