import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { Item } from "@shared/Item/Item";
import { useRecoilState } from "recoil";
import { levelAtom } from "../levelAtom";

export function LevelBox({ level, path, edit }) {
  const navigate = useNavigate();
  const [_, setActiveLevel] = useRecoilState(levelAtom);

  return (
    <Item
      onClick={() => {
        navigate(path);
        setActiveLevel(level);
      }}
      sx={{
        marginY: 2,
        padding: 3,
        cursor: "pointer",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // flexDirection: "column",
        "&:hover": {
          bgcolor: "#f2f2f2",
          transition: "background-color 0.3s",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            color: "black",
            fontSize: 24,
            fontFamily: "Noor-bold",
          }}
        >
          {level?.name}
        </Typography>
        <p>عدد الاسئلة: {level?.questionsNumber}</p>
      </Box>
      <ArrowBackIosNewIcon />
    </Item>
  );
}
