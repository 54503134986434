import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api_url } from "@constants/base_url";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { useEffect } from "react";
import { HashLoader } from "react-spinners";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import BookTeachers from "./BookTeachers/BookTeachers";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDropzone } from "react-dropzone";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { COLORS } from "@constants/Colors/colors";

export default function EditBook({
  noteBook,
  closeNote,
  refetch,
  bookId,
  title,
  price,
  teacher,
  num,
  img,
  isOpenForReservation,
}) {
  const [imageEditSrc, setImageEditSrc] = useState({});
  const [listOrInput, setListOrInput] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState(teacher);
  const [updateImage, setUpdateImage] = useState({});

  const getSelectedTeacher = (name) => {
    setSelectedTeacher(name);
  };

  // console.log(updateImage);
  // console.log(imageEditSrc);

  const [titleName, setTitleName] = useState(title);
  const [priceNum, setPriceNum] = useState(price);
  const [teacherName, setTeacherName] = useState(teacher);
  const [numReserve, setNumReserve] = useState(num);
  const [isOpenForReservationState, setIsOpenForReservationState] = useState(
    isOpenForReservation == "1" ? true : false
  );

  useEffect(() => {
    setTitleName(title);
    setPriceNum(price);
    setTeacherName(teacher);
    setSelectedTeacher(teacher);
    setNumReserve(num);
    setIsOpenForReservationState(isOpenForReservation == "1" ? true : false);
  }, [bookId]);

  const { mutate, isPending, error, isError } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}book/updateBook`, formData, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser()?.token}`,
        },
      }),
    onSuccess: () => {
      closeNote();
      refetch();
      setImageEditSrc({});
      setUpdateImage({});
      notifySuccess("تم تعديل بيانات الكتاب");
    },
    onError: () => {
      notifyError("حدث خطأ اثناء تنفيذ المهمة");
    },
  });

  const onSubmit = () => {
    console.log("submit data", isOpenForReservationState ? 1 : 0);

    const formData = new FormData();
    formData.append("book_id", bookId);
    formData.append("title", titleName);
    formData.append("price", priceNum);
    formData.append("reservation_number", numReserve);
    formData.append("isOpenForReservation", isOpenForReservationState ? 1 : 0);
    if (updateImage?.file) {
      formData.append("image", updateImage?.file);
    }
    if (selectedTeacher != teacher) {
      formData.append("instructor_name", selectedTeacher);
      mutate(formData);
    } else if (teacherName != teacher) {
      formData.append("instructor_name", teacherName);
      mutate(formData);
    } else {
      formData.append("instructor_name", selectedTeacher || teacherName);
      mutate(formData);
    }
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFile) => {
      console.log("acceptedFile", acceptedFile);
      setImageEditSrc({
        url: URL.createObjectURL(acceptedFile[0]),
        file: acceptedFile[0],
      });
      setUpdateImage({
        url: URL.createObjectURL(acceptedFile[0]),
        file: acceptedFile[0],
      });
    },
  });

  const listOrInputFunction = () => {
    setListOrInput(!listOrInput);
  };

  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100%",
        zIndex: "1500",
        display: "flex",
        // justifyContent: 'center',
        padding: "80px 0px",
        alignItems: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: COLORS.PRIMARY.MAIN,
        scrollbarWidth: "thin",
        overflow: "hidden",
        opacity: noteBook === "edit-book" ? 1 : 0,
        visibility: noteBook === "edit-book" ? "visible" : "hidden",
        justifyContent: "center",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: "#F5F5F5",
          borderRadius: "10px",
          width: "70%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: noteBook === "edit-book" ? 1 : 0,
          transform: noteBook === "edit-book" ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              closeNote();
              setImageEditSrc({});
              setUpdateImage({});
            }}
            sx={{
              color: "white",
              padding: "8px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "primary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          />
        </div>

        <Typography
          fontFamily={"Noor-bold"}
          mb={6}
          mt={4}
          fontSize={32}
          sx={{
            textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}`,
            textAlign: "center",
          }}
        >
          تعديل بيانات الكتاب
        </Typography>

        <form onSubmit={onSubmit} encType="multipart/form-data">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                marginBottom: "40px",
                textAlign: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  {...getRootProps()}
                  style={{
                    border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                    padding: "20px 10px 5px 10px",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "-20px",
                      right: "40px",
                      fontSize: "15px",
                      color: COLORS.PRIMARY.MAIN,
                      background: "#F5F5F5",
                      padding: "5px 20px",
                    }}
                  >
                    صورة الكتاب
                  </span>

                  <img
                    src={imageEditSrc?.url || img}
                    style={{
                      width: "400px",
                      height: "300px",
                      cursor: "pointer",
                      borderRadius: "10px",
                    }}
                    alt=""
                  />
                </div>
                <input {...getInputProps()} />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                marginRight: "30px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "-15px",
                      right: "40px",
                      fontSize: "15px",
                      color: COLORS.PRIMARY.MAIN,
                      background: "#F5F5F5",
                      padding: "0px 20px",
                    }}
                  >
                    عنوان الكتاب
                  </span>
                  <input
                    type="text"
                    placeholder="عنوان الكتاب"
                    value={titleName}
                    style={{
                      background: "#F5F5F5",
                      border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                      padding: "20px 15px",
                      borderRadius: "5px",
                      outline: "none",
                      fontSize: "16px",
                      width: "100%",
                      color: "black",
                      transition: "0.4s",
                    }}
                    onChange={(e) => setTitleName(e.target.value)}
                  />
                </Box>
              </Box>

              {listOrInput ? (
                <Box
                  sx={{
                    marginBottom: "30px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <BookTeachers
                      getSelectedTeacher={getSelectedTeacher}
                      selectedTeacher={selectedTeacher}
                      create={"cutomCreate"}
                    />

                    <Button
                      onClick={listOrInputFunction}
                      sx={{
                        background: COLORS.PRIMARY.MAIN,
                        width: "100%",
                        padding: "10px 25px",
                        height: "100%",
                        color: "white",
                        cursor: "pointer",
                        marginRight: "15px",
                        transition: "0.4s",
                        "&:hover": {
                          background: "white",
                          color: COLORS.PRIMARY.MAIN,
                        },
                      }}
                    >
                      اكتب اسم المدرس
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    marginBottom: "30px",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "-15px",
                        right: "40px",
                        fontSize: "15px",
                        color: COLORS.PRIMARY.MAIN,
                        background: "#F5F5F5",
                        padding: "0px 20px",
                      }}
                    >
                      اسم المدرس
                    </span>
                    <input
                      type="text"
                      value={teacherName}
                      placeholder="اسم المدرس"
                      style={{
                        background: "#F5F5F5",
                        border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                        padding: "20px 15px",
                        borderRadius: "5px",
                        outline: "none",
                        fontSize: "16px",
                        width: "100%",
                        color: "black",
                        transition: "0.4s",
                      }}
                      onChange={(e) => setTeacherName(e.target.value)}
                    />

                    <Button
                      onClick={listOrInputFunction}
                      sx={{
                        background: COLORS.PRIMARY.MAIN,
                        width: "50%",
                        padding: "10px 25px",
                        height: "100%",
                        color: "white",
                        cursor: "pointer",
                        marginRight: "15px",
                        transition: "0.4s",
                        "&:hover": {
                          background: "white",
                          color: COLORS.PRIMARY.MAIN,
                        },
                      }}
                    >
                      اختر من المدرسين
                    </Button>
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginBottom: "30px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "40px",
                    fontSize: "15px",
                    color: COLORS.PRIMARY.MAIN,
                    background: "#F5F5F5",
                    padding: "0px 20px",
                  }}
                >
                  سعر الكتاب
                </span>
                <input
                  type="text"
                  value={priceNum}
                  min={1}
                  placeholder="سعر الكتاب"
                  style={{
                    background: "#F5F5F5",
                    border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: "black",
                    transition: "0.4s",
                  }}
                  onChange={(e) => setPriceNum(e.target.value)}
                />
              </Box>

              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "40px",
                    fontSize: "15px",
                    color: COLORS.PRIMARY.MAIN,
                    background: "#F5F5F5",
                    padding: "0px 20px",
                  }}
                >
                  رقم الحجز
                </span>
                <input
                  type="text"
                  value={numReserve}
                  placeholder="رقم الحجز"
                  style={{
                    background: "#F5F5F5",
                    border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: "black",
                    transition: "0.4s",
                  }}
                  onChange={(e) => setNumReserve(e.target.value)}
                />
              </Box>

              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  sx={{
                    my: 2,
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    mr: -1,
                    color: COLORS.PRIMARY.MAIN,
                  }}
                  control={
                    <Checkbox
                      onClick={() => {
                        console.log(isOpenForReservation);
                        setIsOpenForReservationState((x) => !x);
                      }}
                      checked={isOpenForReservationState}
                    />
                  }
                  label="متاح للحجز"
                />
              </Box>
            </Box>
          </Box>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              onClick={onSubmit}
              sx={{
                background: COLORS.PRIMARY.MAIN,
                width: "250px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "white",
                  color: COLORS.PRIMARY.MAIN,
                },
              }}
            >
              {isPending ? <HashLoader color="#ffffff" size={24} /> : "تعديل"}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}
