import Empty from "@shared/Layout/Empty/Empty";
import { Typography, FormHelperText } from "@mui/material";
import Column from "@shared/Layout/Column/Column";
import Row from "@shared/Layout/Row/Row";
import { useRecoilState } from "recoil";
import { activeCourseAtom } from "@/Admin/Pages/Courses/RecoilAtoms/ActiveCourse.atom";
import CourseStateIconSmall from "@shared/Course/CourseStateIconSmall/CourseStateIconSmall";
import student from "@assets/adminImages/student.png";
import course from "@assets/adminImages/course.png";
import getImageUrl from "@shared/Helpers/image/getImageUrl";
import money from "@assets/adminImages/money.png";
import book from "@assets/adminImages/book.png";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import EditButton from "@shared/Buttons/EditButton/EditButton";
import { FONTS } from "@constants/Fonts/fonts";
import TextButton from "@shared/Buttons/TextButton/TextButton";
import { useState } from "react";
import { FONT_SIZES } from "@constants/Fonts/font_sizes";
import { COLORS } from "@constants/Colors/colors";
import Modal from "@shared/Modal/Modal";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { FormProvider, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import BorderedInput from "@shared/Form/BorderedInput/BorderedInput";
import emptyImage from "@assets/adminImages/emptyImg.png";
import CloseButton from "@shared/Buttons/CloseButton/CloseButton";
import { dropzoneConfig } from "@shared/Helpers/hooks/useDropZoneConfig";
import useAddCourse from "./hooks/useAddCourse.hook";
import { addCourseAtom } from "@/Admin/Pages/Courses/RecoilAtoms/addCourseAtom";
import useUpdateCourse from "./hooks/useUpdateCourse";
import useDeleteCourse from "./hooks/useDeleteCourse";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateCourseSchema } from "./schema/updateCourse.schema";
import { createCourseSchema } from "./schema/createCourse.schema";

export default function CourseDetailsSideBar() {
  const [activeCourse, setActiveCourse] = useRecoilState(activeCourseAtom);
  const [addCourse, setAddCourse] = useRecoilState(addCourseAtom);
  const [isEditing, setIsEditing] = useState(false);

  function close() {
    setActiveCourse(null);
    setAddCourse(false);
    setIsEditing(false);
  }

  return (
    <Empty sx={{ p: 2, overflowY: "auto" }}>
      {(activeCourse || addCourse) && <CloseButton onClick={close} />}

      {(() => {
        if (isEditing) return <AddOrEditCourseUI close={close} />;
        else if (activeCourse)
          return (
            <CourseDataUI
              setIsEditing={setIsEditing}
              activeCourse={activeCourse}
            />
          );
        else if (addCourse) return <AddOrEditCourseUI close={close} />;
        else if (!activeCourse) return null;
      })()}
    </Empty>
  );
}

function AddOrEditCourseUI({ close }) {
  const [add] = useRecoilState(addCourseAtom);
  const [activeCourse] = useRecoilState(activeCourseAtom);

  const methods = useForm({
    resolver: zodResolver(add ? createCourseSchema : updateCourseSchema),
  });
  const { setValue, handleSubmit, formState, clearErrors } = methods;
  const [imageSrc, setImageSrc] = useState(null);
  const { getRootProps, getInputProps } = useDropzone(
    dropzoneConfig({
      setValue,
      setImageSrc,
      clearErrors,
    })
  );

  function initData() {
    setImageSrc({ url: getImageUrl(activeCourse.image) });
    setValue("name", activeCourse.name);
    setValue("description", activeCourse.description);
    setValue("duration", activeCourse.duration);
    setValue("price", activeCourse.price);
  }

  if (activeCourse && !imageSrc && !add) {
    initData();
  }
  const { addCourse, isPending: addPending } = useAddCourse({
    onSettled: close,
  });
  const { updateCourse, isPending: updatePending } = useUpdateCourse({
    courseId: activeCourse?.id,
    onSettled: close,
  });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(add ? addCourse : updateCourse)}>
          <div
            // data-testid="image-container"
            {...getRootProps()}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img
              data-testid="image"
              src={
                imageSrc?.url || getImageUrl(activeCourse?.image) || emptyImage
              }
              style={{ width: "80%", cursor: "pointer", marginX: "auto" }}
              alt=""
            />
            <input data-testid="image-input" {...getInputProps()} />
          </div>
          <FormHelperText sx={{ color: COLORS.DANGER }}>
            {formState.errors?.image && formState.errors?.image?.message}
          </FormHelperText>
          <Column gap={2}>
            <BorderedInput
              registerName={"name"}
              placeholder="ادخل اسم الكورس"
            />
            <BorderedInput
              registerName={"description"}
              placeholder="ادخل الوصف "
            />
            <Row spacing="space-between" width={"100%"} gap={1}>
              <BorderedInput
                registerName={"duration"}
                placeholder="ادخل المده "
                type="number"
              />
              <Typography>ساعة</Typography>
            </Row>

            <BorderedInput
              registerName={"price"}
              placeholder="ادخل السعر "
              type="number"
            />

            <Row spacing="space-between" width={"100%"} gap={1} mt={4}>
              <ColorButton
                type="submit"
                isLoading={addPending || updatePending}
              >
                {add ? "اضافة" : "تعديل"}
              </ColorButton>
              {!add && (
                <ColorButton
                  onClick={initData}
                  isLoading={addPending || updatePending}
                >
                  استعادة
                </ColorButton>
              )}
              <ColorButton onClick={close} colorType="secondary">
                اغلاق
              </ColorButton>
            </Row>
          </Column>
        </form>
      </FormProvider>
    </>
  );
}

function CourseDataUI({ setIsEditing, activeCourse }) {
  return (
    <>
      <Row>
        <Column>
          <Row>
            <CourseStateIconSmall
              image={student}
              number={activeCourse?.subscriptionsCount}
              name={"طالب"}
              color={"blue"}
            />
            <CourseStateIconSmall
              image={course}
              number={activeCourse?.modulesCount}
              name={"حصة"}
              color={"#2f8bc8"}
            />
          </Row>
          <Row>
            <CourseStateIconSmall
              image={money}
              number={activeCourse?.paymentCodesCount}
              name={"كود"}
              color={"blue"}
            />
            <CourseStateIconSmall
              image={book}
              number={activeCourse?.examsCount}
              name={"امتحان"}
              color={"#2f8bc8"}
            />
          </Row>
        </Column>
        <img
          src={getImageUrl(activeCourse?.image)}
          style={{ width: "70%" }}
          alt=""
        />
      </Row>

      <Column>
        <Typography fontFamily={FONTS.BOLD} fontSize={FONT_SIZES.LARGE} py={2}>
          {activeCourse?.name}
          {currentUser()?.role !== "Supervisor" && (
            <EditButton onClick={() => setIsEditing(true)} />
          )}
        </Typography>
        <Typography
          fontFamily={FONTS.BOLD}
          fontSize={FONT_SIZES.REGULAR}
          pb={2}
        >
          {activeCourse?.description}
        </Typography>
        {activeCourse?.price == 0 ? (
          <Typography
            fontFamily={FONTS.BOLD}
            fontSize={FONT_SIZES.LARGE}
            color={COLORS.PRIMARY.MAIN}
          >
            مجاني
          </Typography>
        ) : (
          <Row>
            <Typography
              fontFamily={FONTS.BOLD}
              color={COLORS.PRIMARY.MAIN}
              fontSize={FONT_SIZES.LARGE}
            >
              {activeCourse?.price}
            </Typography>
            <Typography
              fontFamily={FONTS.REGULAR}
              color={COLORS.PRIMARY.MAIN}
              fontSize={FONT_SIZES.MEDIUM}
            >
              &nbsp;جنيه
            </Typography>
          </Row>
        )}
      </Column>
      <DeleteCourse courseId={activeCourse?.id} />
    </>
  );
}

function DeleteCourse({ courseId }) {
  const [open, setOpen] = useState(false);
  const [_, setActiveCourse] = useRecoilState(activeCourseAtom);

  const { deleteCourse, isPending } = useDeleteCourse({
    id: courseId,
    onSettled: () => {
      setOpen(false);
      setActiveCourse(null);
    },
  });

  return (
    <>
      {currentUser()?.role !== "Supervisor" && (
        <Row>
          <TextButton
            color={COLORS.DANGER}
            sx={{ mt: 10 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            حذف الكورس
          </TextButton>
        </Row>
      )}
      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <Typography fontFamily={FONTS.BOLD} fontSize={FONT_SIZES.LARGE}>
          هل انت متأكد من حذف الكورس؟
        </Typography>
        <Row mt={2} gap={2}>
          <ColorButton
            onClick={() => deleteCourse()}
            isLoading={isPending}
            colorType="danger"
            width="100px"
          >
            حذف
          </ColorButton>
          <ColorButton
            colorType="secondary"
            width="100px"
            onClick={() => setOpen(false)}
          >
            الغاء
          </ColorButton>
        </Row>
      </Modal>
    </>
  );
}

// function LoadingUI() {
//   return (
//     <>
//       <Row align={"start"}>
//         <Column ml={1}>
//           <Row gap={1}>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//           </Row>
//           <Row gap={1}>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//             <Typography width={"30px"} variant="h3">
//               <Skeleton sx={{ backgroundColor: "#bbb" }} />
//             </Typography>
//           </Row>
//         </Column>
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "100%" }}
//           variant="rectangular"
//           height={220}
//         />
//       </Row>
//       <Column mt={4}>
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "300px", height: "60px" }}
//         />
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "300px", height: "40px" }}
//         />
//         <Skeleton
//           sx={{ backgroundColor: "#bbb", width: "300px", height: "60px" }}
//         />
//       </Column>
//     </>
//   );
// }
