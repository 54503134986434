import useCustomMutation from "@/Admin/Shared/Helpers/hooks/useCustomMutation";
import Modal from "@shared/Modal/Modal";
import { FONTS } from "@constants/Fonts/fonts";
import { FONT_SIZES } from "@constants/Fonts/font_sizes";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import Row from "@shared/Layout/Row/Row";
import { Typography } from "@mui/material";

export default function useDeleteCode({ id, onSettled }) {
  const { mutate, isPending } = useCustomMutation({
    url: `code/deletePaymentCode?paymentCode_id=${id}`,
    invalidate: [["code", id], "code"],
    method: "delete",
    successMsg: "تم حذف الكود بنجاح",
    onSettled,
  });

  function deleteCode() {
    mutate();
  }

  return { deleteCode, isPending };
}

export function DeleteCode({ courseId }) {
  const { deleteCode, isPending } = useDeleteCode({
    id: courseId,
    onSettled: () => {
      setOpen(false);
      setActiveCode(null);
      refetch();
    },
  });
  return (
    <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
      <Typography fontFamily={FONTS.BOLD} fontSize={FONT_SIZES.LARGE}>
        هل انت متأكد من حذف الكود؟
      </Typography>
      <Row mt={2} gap={2}>
        <ColorButton
          onClick={() => deleteCode()}
          //  {/* isLoading={isPending} */}
          colorType="danger"
          width="100px"
        >
          حذف
        </ColorButton>
        <ColorButton
          colorType="secondary"
          width="100px"
          onClick={() => setOpen(false)}
        >
          الغاء
        </ColorButton>
      </Row>
    </Modal>
  );
}
