import { COLORS } from "@constants/Colors/colors";
import { Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <Box
        py={"16px"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        backgroundColor={COLORS.SECONDARY.MAIN}
        textAlign={"center"}
      >
        <Typography color={"white"} fontFamily={"Cairo"} mb={0}>
          جميع الحقوق محفوظة لشركة{" "}
          <a
            href="http://firststepacademy.online/"
            style={{
              color: "#fff",
              textDecoration: "none",
              fontFamily: "sans-serif",
            }}
          >
            First Step
          </a>
        </Typography>
        {/* <Typography color={"white"} fontFamily={"Cairo"}>
          باستخدام هذا الموقع، فإنك تعبر عن موافقتك على
          {" "}
          <Link to={'/privacy'} style={{ color: '#2f8bc8', textDecoration: 'none' }}> سياسة الخصوصية </Link>
          {" "}
          الخاصة بنا.
        </Typography> */}
      </Box>
    </>
  );
}
