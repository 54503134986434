import useCustomMutation from '@shared/Helpers/hooks/useCustomMutation'
import Modal from '@shared/Modal/Modal'
import { notifySuccess } from '@shared/Toast/toast'
import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import {
    TextField,
    Button,
    Typography,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    Box,
    RadioGroup,
    Radio,
} from '@mui/material';
import { COLORS } from '@constants/Colors/colors';
import { HashLoader } from 'react-spinners';
import dayjs from 'dayjs';
import { ExamImagePicker } from '../ExamCreator/ExamImagePicker/ExamImagePicker'

export const ExamEditor = ({open, setOpen, id, type, examQuests, started}) => {
    console.log(examQuests)
    const [exam, setExam] = useState({
        title:examQuests?.title,
        isPrerequsite:examQuests?.isPrerequsite,
        questions:examQuests?.questions,
        starts_at:examQuests?.starts_at,
        ends_at:examQuests?.ends_at,
        exam_time:examQuests?.exam_time
    })
    const [submitted, setSubmitted] = useState(false)
    const queryClient = useQueryClient()
    const {mutate, isPending} = useCustomMutation({
        url:`exam/updateExam`,
        onSuccess:()=>{
            notifySuccess("تم تعديل الإمتحان بنجاح")
            queryClient.invalidateQueries(["module exam",`exam of course`])
            setOpen(false)
        }
    })

    const handleRadioChange = (questionIndex, answerIndex) => {
        const updatedExam = { ...exam };
        updatedExam.questions[questionIndex].answers.forEach((answer, index) => {
            answer.isCorrect = index === answerIndex ? 1 : 0;
        });
        setExam(updatedExam);
    };



    const addAnswer = (index) => {
        let temp = exam.questions
        temp[index].answers.push({title:"", isCorrect:0})
        setExam({...exam, questions: temp})
    }

    const removeAnswer = (index) => {
        let temp = exam.questions
        if(temp[index].answers[temp[index].answers.length - 1].isCorrect == 1){
            temp[index].answers[temp[index].answers.length - 2].isCorrect = 1
        }
        temp[index].answers = temp[index].answers.slice(0,-1)
        setExam({...exam, questions: temp})
    }

    const addQuestion = () => {
        let temp = exam.questions
        temp.push({
            // title:"",
            grade:"",
            // image:null,
            answers:[{
                title:"",
                isCorrect:1
            },{
                title:"",
                isCorrect:0
            }]
        })
        setExam({...exam, questions: temp})
    }

    const removeQuestion = () => {
        let temp = exam.questions
        temp = temp.slice(0,-1)
        setExam({...exam, questions: temp})
    }

    const submit = () => {
        let examData = exam
        if (!examData.title) return
        if (!started && (!examData.starts_at || new Date(new Date().setMinutes(new Date().getMinutes() + 60)) >= new Date(examData.starts_at))) return
        if(!started){
            examData.starts_at = dayjs(examData.starts_at).format("YYYY-MM-DD hh:mm:ss A")
        }
        if (!started && (!examData.ends_at || new Date(examData.ends_at) <= new Date(examData.starts_at))) return
        if(!started){
            examData.ends_at = dayjs(examData.ends_at).format("YYYY-MM-DD hh:mm:ss A")
        }
        if (!started && !examData.exam_time) return
        for(let i = 0; i < examData.questions.length; i++){
            if(!examData.questions[i].title && !examData.questions[i].image) return
            if(examData.questions[i].image){
                examData.questions[i].image = examData.questions[i].image[0]
            }
            if(!started && !examData.questions[i].grade) return
            for(let j = 0; j < examData.questions[i].answers.length; j++){
                if(!examData.questions[i].answers[j].title) return
            }
        }

        const reqFormData = new FormData();
        reqFormData.append("title", examData?.title);
        if(!started){
            reqFormData.append("starts_at", examData?.starts_at);
            reqFormData.append("ends_at", examData?.ends_at);
            reqFormData.append("exam_time", examData?.exam_time);
            reqFormData.append("isPrerequsite", String(examData.isPrerequsite));
        }
        reqFormData.append("exam_id", examQuests.id);
        type === "Lesson" && reqFormData.append("lesson_module_id", id);
        for(let i = 0; i < examData.questions.length; i++){
            if (examData.questions[i]?.title) {
                reqFormData.append(`questions[${i}][title]`, examData.questions[i].title);
            }
            if (examData.questions[i]?.image && examData.questions[i]?.image?.path) {
                reqFormData.append(
                    `questions[${i}][image]`,
                    examData.questions[i].image
                );
            }
            reqFormData.append(`questions[${i}][grade]`, examData.questions[i].grade);
            for(let j = 0; j < examData.questions[i].answers.length; j++){
                reqFormData.append(
                    `questions[${i}][answers][${j}][title]`,
                    examData.questions[i].answers[j].title
                );
                reqFormData.append(
                    `questions[${i}][answers][${j}][isCorrect]`, +examData.questions[i].answers[j].isCorrect
                );
            }
        }
        mutate(reqFormData)
    }

    return (
        <Modal open={open} closeFn={()=>setOpen(false)}>

            <form onSubmit={(e) => {
                e.preventDefault()
            }} style={{marginTop:5, width:"60%", display:"flex", flexDirection:"column", gap:10}}>
                {started && <Typography sx={{textAlign:"center", color:COLORS.DANGER}}>انتبه: لقد بدأ الإمتحان بالفعل بعض الحقول لا يمكن تعديلها</Typography>}
                <TextField
                    label = "العنوان"
                    fullWidth
                    type='text'
                    value={exam.title}
                    onChange={(e) => {
                        setExam({...exam, title:e.target.value})
                    }}
                    error={!exam.title && submitted}
                    helperText={!exam.title && submitted && "يجب إضافة عنوان للإمتحان"}
                    sx={{
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#2f8bc8",
                        },
                        "& label": {
                          left: "unset",
                          right: "1.75rem",
                          transformOrigin: "right",
                          fontSize: "0.8rem",
                          color: "#2f8bc8",
                        },
                        "& legend": {
                          textAlign: "right",
                          fontSize: "0.6rem",
                        },
                        direction: "rtl",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                />
                {!started && <>
                    <Box sx={{display:"flex", gap:2}} fullWidth>
                    <TextField
                        type='datetime-local'
                        label = "تاريخ البدء"
                        value={exam?.starts_at}
                        onChange={(e)=>{
                            setExam({...exam, starts_at:e.target.value})
                        }}
                        error={(!exam.starts_at && submitted) || (new Date(new Date().setMinutes(new Date().getMinutes() + 60)) >= new Date(exam.starts_at) && submitted)}
                        helperText={(()=>{
                            if(!exam.starts_at && submitted){
                                return "يجب إضافة تاريخ البدء"
                            }
                            if(new Date(new Date().setMinutes(new Date().getMinutes() + 60)) >= new Date(exam.starts_at) && submitted){
                                return "يجب إختيار تاريخ علي الاقل بعد ساعة"
                            }else{
                                return 
                            }
                        })()}
                        sx={{
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#2f8bc8",
                        },
                        "& label": {
                          left: "unset",
                          right: "1.75rem",
                          transformOrigin: "right",
                          fontSize: "0.8rem",
                          color: "#2f8bc8",
                        },
                        "& legend": {
                          textAlign: "right",
                          fontSize: "0.6rem",
                        },
                        direction: "rtl",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                        type='datetime-local'
                        label = "تاريخ الإنتهاء"
                        value={exam?.ends_at}
                        onChange={(e)=>{
                            setExam({...exam, ends_at:e.target.value})
                        }}
                        error={(!exam.ends_at && submitted) || (new Date(exam.ends_at) <= new Date(exam.starts_at) && submitted)}
                        helperText={(()=>{
                            if(!exam.ends_at && submitted){
                                return "يجب إضافة تاريخ الإنتهاء"
                            }
                            if(new Date(exam.ends_at) <= new Date(exam.starts_at) && submitted){
                                return "يجب إختيار تاريخ بعد تاريخ البدء"
                            }else{
                                return 
                            }
                        })()}
                        sx={{
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#2f8bc8",
                        },
                        "& label": {
                          left: "unset",
                          right: "1.75rem",
                          transformOrigin: "right",
                          fontSize: "0.8rem",
                          color: "#2f8bc8",
                        },
                        "& legend": {
                          textAlign: "right",
                          fontSize: "0.6rem",
                        },
                        direction: "rtl",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                    />
                </Box>
                    <TextField 
                        type='number' 
                        value={exam.exam_time} 
                        onChange={(e) => {
                            e.target.value > 0 && setExam({...exam, exam_time:e.target.value})
                        }}
                        label = "الزمن بالدقائق"
                        error = {!exam.exam_time && submitted}
                        helperText = {!exam.exam_time && submitted && "يجب تحديد زمن الامتحان"}
                        sx={{
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#2f8bc8",
                        },
                        "& label": {
                          left: "unset",
                          right: "1.75rem",
                          transformOrigin: "right",
                          fontSize: "0.8rem",
                          color: "#2f8bc8",
                        },
                        "& legend": {
                          textAlign: "right",
                          fontSize: "0.6rem",
                        },
                        direction: "rtl",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                    />
                    <FormControlLabel label={"يجب اجتياز امتحان الحصة السابقة"} control={<Checkbox defaultChecked={exam.isPrerequsite} onChange={e => {
                        setExam({...exam, isPrerequsite: e?.target.checked ? 1 : 0})
                    }}/>}>
                    </FormControlLabel>
                </>}
                <Typography fullWidth sx={{textAlign:"center", marginY:2}}>الأسئلة</Typography>
                {exam?.questions.map((_, index) => <Box fullWidth sx={{display:"flex", flexDirection:"column", gap:2}}>
                    
                    <Typography sx={{bgcolor:COLORS.PRIMARY.MAIN, width:30, height: 30, color: COLORS.WHITE, borderRadius:"50%", display:"flex", alignItems:"center", justifyContent:"center"}}>{index + 1}</Typography>
                    <TextField
                        label = "عنوان السؤال"
                        fullWidth
                        type='text'
                        value={exam.questions[index].title}
                        onChange={(e) => {
                            let temp = exam.questions
                            temp[index].title = e.target.value
                            setExam({...exam, questions: temp})
                        }}
                        error={!exam.questions[index].title && !exam.questions[index].image && submitted}
                        helperText={!exam.questions[index].title && !exam.questions[index].image && submitted && "يجب إضافة عنوان للسؤال في حالة عدم إضافة صورة"}
                        sx={{
                            "& .MuiFormLabel-root.Mui-focused": {
                            color: "#2f8bc8",
                            },
                            "& label": {
                            left: "unset",
                            right: "1.75rem",
                            transformOrigin: "right",
                            fontSize: "0.8rem",
                            color: "#2f8bc8",
                            },
                            "& legend": {
                            textAlign: "right",
                            fontSize: "0.6rem",
                            },
                            direction: "rtl",
                        }}
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="end"></InputAdornment>
                            ),
                        }}
                    />
                    <ExamImagePicker defaultImage={exam.questions[index].image} exam={exam} setExam={setExam} index={index}/>
                    {!started && <TextField 
                        type='number' 
                        value={exam.questions[index].grade} 
                        onChange={(e) => {
                            if(e.target.value > 0){
                                let temp = exam.questions
                                temp[index].grade = e.target.value
                                setExam({...exam, questions: temp})
                            }
                        }}
                        label = "درجة السؤال"
                        fullWidth
                        error = {!exam.questions[index].grade && submitted}
                        helperText = {!exam.questions[index].grade && submitted && "يجب تحديد درجة السؤال"}
                        sx={{
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#2f8bc8",
                        },
                        "& label": {
                          left: "unset",
                          right: "1.75rem",
                          transformOrigin: "right",
                          fontSize: "0.8rem",
                          color: "#2f8bc8",
                        },
                        "& legend": {
                          textAlign: "right",
                          fontSize: "0.6rem",
                        },
                        direction: "rtl",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                    />}
                        <RadioGroup sx={{display:"flex", flexDirection:"column", gap:2}} value={exam.questions[index].answers.findIndex(x => x.isCorrect == 1)}>
                            {exam.questions[index].answers.map((answer , i)=><Box key={i} sx={{display: "flex", alignItems:"center", gap:2}}>
                                <TextField
                                    label = {`إجابة ${i+1}`}
                                    type='text'
                                    value={answer.title}
                                    onChange={(e) => {
                                        let temp = exam.questions
                                        temp[index].answers[i].title = e.target.value
                                        setExam({...exam, questions: temp})
                                    }}
                                    error={!answer.title && submitted}
                                    helperText={!answer.title && submitted && "يجب إضافة نص اللإجابة"}
                                    sx={{
                                        "& .MuiFormLabel-root.Mui-focused": {
                                        color: "#2f8bc8",
                                        },
                                        "& label": {
                                        left: "unset",
                                        right: "1.75rem",
                                        transformOrigin: "right",
                                        fontSize: "0.8rem",
                                        color: "#2f8bc8",
                                        },
                                        "& legend": {
                                        textAlign: "right",
                                        fontSize: "0.6rem",
                                        },
                                        direction: "rtl",
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="end"></InputAdornment>
                                        ),
                                    }}
                                />
                                {!started && <Radio value={answer.isCorrect.toString()}
                                    checked={answer.isCorrect === 1}
                                    onChange={() => handleRadioChange(index, i)}/>}
                                </Box>)}
                                {!started && <Box sx={{display:"flex", alignItems:"center", gap:2}}>
                                    {exam.questions[index].answers.length < 4 && <Button sx={{bgcolor:COLORS.PRIMARY.MAIN, color:COLORS.WHITE, "&:hover":{bgcolor:COLORS.PRIMARY.MAIN}}} onClick={()=>addAnswer(index)}>أضف إجابة</Button>}
                                    {exam.questions[index].answers.length > 2 && <Button sx={{bgcolor:COLORS.PRIMARY.MAIN, color:COLORS.WHITE, "&:hover":{bgcolor:COLORS.PRIMARY.MAIN}}} onClick={()=>removeAnswer(index)}>إحذف إجابة</Button>}
                                </Box>}
                            </RadioGroup>
                        </Box>)}
                        {!started && <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", gap:2}}>
                            {<Button sx={{bgcolor:COLORS.PRIMARY.MAIN, color:COLORS.WHITE, "&:hover":{bgcolor:COLORS.PRIMARY.MAIN}}} onClick={addQuestion}>أضف سؤال</Button>}
                            {exam.questions.length > 1 && <Button sx={{bgcolor:COLORS.PRIMARY.MAIN, color:COLORS.WHITE, "&:hover":{bgcolor:COLORS.PRIMARY.MAIN}}} onClick={removeQuestion}>إحذف سؤال</Button>}
                        </Box>}
                    <Button disabled={isPending} sx={{bgcolor:COLORS.PRIMARY.MAIN, color:COLORS.WHITE, "&:hover":{bgcolor:COLORS.PRIMARY.MAIN}}} onClick={()=>{
                        console.log(exam)
                        setSubmitted(true)
                        submit()
                    }}>{isPending?<HashLoader size={20} color={COLORS.WHITE}/>:"تعديل"}</Button>
            </form>
        </Modal>
    )
}
