import React from 'react'
import { CourseHeader } from './CourseHeader/CourseHeader'
import { Box } from '@mui/material'
import { CourseModules } from './CourseModules/CourseModules'
import { CourseFiles } from './CourseFiles/CourseFiles'
import { CourseExams } from './CourseExams/CourseExams'
import { CourseStudents } from './CourseStudents/CourseStudents'
import useCustomQuery from '@shared/Helpers/hooks/useCustomQuery'
import { useParams } from 'react-router-dom'

export const Course = () => {
  const {id} = useParams()
    const {data, isPending} = useCustomQuery({
        url:`course/courseData?id=${id}`,
        key:["course", id],
        method:"post"
    })
  return (
    <Box sx={{width:"100%", minHeight:"100vh"}}>
      <CourseHeader data={data} isPending={isPending}/>
      <Box sx={{width:"100%", minHeight:400, display:"flex", alignItems:"center", gap:5}}>
        <CourseModules course={data} />
        <Box sx={{display:"flex", flexDirection:"column", gap:5, width:"50%", alignItems:"center"}}>
          <CourseFiles/>
          <CourseExams/>
        </Box>
      </Box>
      <CourseStudents/>
    </Box>
  )
}
