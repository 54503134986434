import { COLORS } from '@constants/Colors/colors'
import { api_url } from '@constants/base_url'
import { Box, IconButton, Typography } from '@mui/material'
import useCustomQuery from '@shared/Helpers/hooks/useCustomQuery'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { HashLoader } from 'react-spinners'
import EditIcon from '@mui/icons-material/Edit';
import { EditCourseHeader } from './EditCourseHeader/EditCourseHeader'
import { HeaderInfo } from './HeaderInfo/HeaderInfo'
import student from '@assets/adminImages/student.png'
import course from '@assets/adminImages/course.png'
import session from '@assets/adminImages/session.png'
import money from '@assets/adminImages/money.png'
import lesson from '@assets/adminImages/lessons.png'

export const CourseHeader = ({data, isPending}) => {
    const [editing, setEditing] = useState(false)
    const {id} = useParams()
    return (
        <Box sx={{width: "100%", minHeight:500, bgcolor:COLORS.DARK_GRAY, color:COLORS.WHITE, display:"flex", alignItems:"center", justifyContent:"center"}}>
            {editing?<EditCourseHeader course={data} setEditing={setEditing} id={id}/>:isPending?<Box sx={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", height:500}}>
                <HashLoader color={COLORS.PRIMARY.MAIN}/>
            </Box>: <Box sx={{width:"100%",padding:3, display:"flex", alignItems:"center", justifyContent:"space-between",gap:5}}>
                <img src={`${api_url.slice(0,-7)}${data?.image}`} height={200}/>
                <Box sx={{display:"flex",flexDirection:"column", gap:2,flexWrap: 'wrap'}}>
                    <Typography sx={{fontSize:40, wordWrap:"wrap"}}>{data?.name}</Typography>
                    <Typography sx={{fontSize:20, wordWrap:"wrap"}}>الوصف: {data?.description}</Typography>
                    <Box sx={{display:"flex", alignItems:"center", gap:2}}>
                        <HeaderInfo color={"#4dcf89"} name={"إشتراكات"} value={data?.subscriptionsCount} icon={money}/>
                        <HeaderInfo color={"#6e61b5"} name={"أكواد"} value={data?.paymentCodesCount} icon={session}/>
                        <HeaderInfo color={"#f08639"} name={"حصص"} value={data?.modulesCount} icon={course}/>
                        <HeaderInfo color={"#ea2a3d"} name={"واجبات"} value={data?.homeworkCount} icon={lesson}/>
                        <HeaderInfo color={"#4884df"} name={"إمتحانات"} value={data?.examsCount} icon={student}/>
                    </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", gap:5}}>
                    <Typography sx={{fontSize:20}}>{+data?.price?`${+data?.price} جنيها`:"مجاني"}</Typography>
                    <IconButton onClick={()=>{setEditing(true)}} sx={{color:COLORS.WHITE, bgcolor:COLORS.PRIMARY.MAIN}}>
                        <EditIcon/>
                    </IconButton>
                </Box>
            </Box>}
        </Box>
    )
}
