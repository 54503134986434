import { Box} from "@mui/material";
import NavBar from "../NavBar/NavBar";
import Landing from "../atoms/Landing";

export default function Hero() {

  return (
    <div style={{ background: 'white' }}>
      <Box>
        <NavBar />
        <Landing/>
      </Box>
    </div>
  );
}
