import { api_url } from "@constants/base_url";
import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { HashLoader } from "react-spinners";

export default function AddDevice({
  refetch,
  openAddDevice,
  closeAddDevice,
  id,
}) {
  const [type, setType] = useState("");
  const [inputError, setInputError] = useState(false);

  const { mutate, isPending, error, isError, status } = useMutation({
    mutationFn: () =>
      axios.get(`${api_url}device/addDevice?user_id=${id}&platForm=${type}`, {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
        },
      }),
    onSuccess: () => {
      closeAddDevice();
      refetch();
      notifySuccess("تم اضافة جهاز");
    },
    onError: (err) => {
      console.log(err);
      notifyError("حدث خطأ اثناء تنفيذ المهمة");
    },
  });

  const handleAdd = () => {
    if (type === "") {
      setInputError(true);
    } else {
      mutate(id, type);
      console.log(id, type);
    }
  };

  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100%",
        zIndex: "1500",
        display: "flex",
        // justifyContent: 'center',
        padding: "80px 0px",
        alignItems: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: "#2f8bc8",
        scrollbarWidth: "thin",
        overflowY: "auto",
        opacity: openAddDevice === "add-device" ? 1 : 0,
        visibility: openAddDevice === "add-device" ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: "#F5F5F5",
          borderRadius: "10px",
          width: "70%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: openAddDevice === "add-device" ? 1 : 0,
          transform: openAddDevice === "add-device" ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              closeAddDevice();
            }}
            sx={{
              color: "white",
              padding: "8px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "secondary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "#2f8bc8",
              },
            }}
          />
        </div>

        <Typography
          fontFamily={"Noor-bold"}
          mb={6}
          mt={4}
          fontSize={32}
          sx={{ textShadow: "0px 2px 0px #2f8bc8", textAlign: "center" }}
        >
          هل تريد اضافة جهاز جديد
        </Typography>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            margin: "50px 0px",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "-24px",
              right: "40px",
              fontSize: "15px",
              color: "#2f8bc8",
              background: "white",
              padding: "10px 20px",
            }}
          >
            نوع الجهاز
          </span>
          <select
            name=""
            id=""
            placeholder="نوع الجهاز"
            style={{
              background: "white",
              border: "1px solid #2f8bc8",
              padding: "20px 15px",
              borderRadius: "5px",
              outline: "none",
              fontSize: "16px",
              width: "100%",
              color: "black",
              transition: "0.4s",
            }}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="" disabled selected>
              اختر نوع الجهاز
            </option>
            <option value="Web">ويب</option>
            <option value="Mobile">موبايل</option>
          </select>
          {inputError ? (
            <Typography
              fontSize={15}
              fontFamily={"Noor-bold"}
              color={"red"}
              mt={1}
              mr={1}
            >
              يجب اختيار نوع الجهاز
            </Typography>
          ) : (
            ""
          )}
        </Box>

        <Box
          mt={5}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alidngItems: "center",
          }}
        >
          <Button
            onClick={() => handleAdd()}
            sx={{
              padding: "7px 20px",
              width: "49%",
              bgcolor: "darkred",
              color: "white",
              trnsition: "0.4s",
              "&:hover": {
                bgcolor: "red",
              },
            }}
          >
            {isPending ? <HashLoader color="#ffffff" size={24} /> : "اضافة"}
          </Button>
          <Button
            onClick={closeAddDevice}
            sx={{
              padding: "7px 20px",
              width: "49%",
              bgcolor: "gray",
              color: "white",
              trnsition: "0.4s",
              "&:hover": {
                bgcolor: "lightgray",
              },
            }}
          >
            الغاء
          </Button>
        </Box>
      </Box>
    </div>
  );
}
