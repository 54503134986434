import React, { useRef } from "react";
import style from "./Form.module.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useEffect } from "react";
import Hash from "@shared/Spinners/Hash/hash";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FONTS } from "@constants/Fonts/fonts";
import { COLORS } from "@constants/Colors/colors";

const Form = ({ title, children, onSubmit }) => {
  return (
    <div className={style.container} style={{ direction: "rtl" }}>
      <h1
        style={{
          fontFamily: FONTS.BOLD,
        }}
      >
        {title}
      </h1>
      <form onSubmit={onSubmit}>{children}</form>
    </div>
  );
};

const TextController = ({
  register,
  registername,
  validationRules,
  errorMessage,

  placeholder,
  type = "text",
  value,
  onChange,
  error,
  helperText,
  submitted,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className={style.inputContainer}>
      {submitted && !value && helperText && (
        <span className={style.helperText}>{helperText}</span>
      )}

      {errorMessage && <span className={style.helperText}>{errorMessage}</span>}

      <div>
        {registername ? (
          <input
            {...register(registername, validationRules)}
            style={{
              borderWidth: "0 5px 0 0",
              borderColor: errorMessage && COLORS.DANGER,
            }}
            type={showPassword ? "text" : type}
            placeholder={placeholder}
          />
        ) : (
          <input
            style={{
              borderWidth: "0 5px 0 0",
              borderColor: submitted && !value && helperText && COLORS.DANGER,
            }}
            className={`${style.input} ${error ? style.errorInput : ""}`}
            type={showPassword ? "text" : type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        )}
        {type === "password" && (
          <button
            type="button"
            className={style.togglePasswordVisibility}
            onClick={handleTogglePasswordVisibility}
            style={{ color: COLORS.WHITE }}
          >
            {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
          </button>
        )}
      </div>
    </div>
  );
};

const PriceController = ({ value, placeholder, onChange, submitted }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    setInputValue(newCheckedState ? value : "0");
    if (!newCheckedState) {
      onChange({ target: { value: "0" } });
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(e);
  };

  const validateInput = () => {
    if (!isChecked) {
      return "";
    }

    if (inputValue === "") {
      return "يجب ادخال سعر";
    } else if (isNaN(inputValue)) {
      return "السعر يجب ان يكون رقم";
    } else if (inputValue === "0" || Number(inputValue) < 10) {
      return "السعر يجب ان يكون 10 جنيهات علي الأقل";
    } else {
      return "";
    }
  };

  const error = submitted ? validateInput() : "";

  return (
    <div className={style.priceContainer}>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <div className={style.priceToggle}>
            <p style={{ color: COLORS.WHITE }}>{"مجانا"}</p>
            <p style={{ color: COLORS.WHITE }}>{"مدفوع"}</p>
          </div>
        </label>

        {error && <span className={style.helperText}>{error}</span>}
        {isChecked && (
          <input
            type="text"
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            disabled={!isChecked}
            style={{ borderColor: error && COLORS.DANGER }}
          />
        )}
      </div>
    </div>
  );
};

const ReleaseDateController = ({
  id,
  module,
  setModule,
  submitted,
  getCurrentDateTime,
}) => {
  const handleCheckboxChange = () => {
    setModule({ ...module, releaseLater: !module.releaseLater });
  };

  const handleDateChange = (e) => {
    setModule({ ...module, releaseLaterDate: e.target.value });
  };

  const hasError = !module.releaseLaterDate && submitted && module.releaseLater;
  const helperText = hasError ? "يجب إضافة تاريخ النشر" : "";

  return (
    <div className={style.dateContainer}>
      <input
        type="checkbox"
        checked={module?.releaseLater}
        onChange={handleCheckboxChange}
        id={`pub-later-${id}`}
      />

      <label
        style={{
          color: module?.releaseLater ? COLORS.WHITE : COLORS.SECONDARY.MAIN,
        }}
        htmlFor={`pub-later-${id}`}
      >
        نشر لاحقا
      </label>

      {hasError && <div className={style.helperText}>{helperText}</div>}

      {module?.releaseLater && (
        <input
          type="datetime-local"
          disabled={!module.releaseLater}
          min={getCurrentDateTime()}
          id={`release-date-${id}`}
          aria-labelledby={`pub-later-${id}`}
          value={module.releaseLaterDate}
          onChange={handleDateChange}
          style={{
            border: hasError && `1px solid ${COLORS.DANGER}`,
          }}
        />
      )}
    </div>
  );
};

const ButtonController = ({
  isPending,
  onClick,
  children,
  type = "button",
}) => {
  return (
    <button
      className={style.button}
      type={type}
      disabled={isPending}
      onClick={onClick}
      style={{ color: COLORS.DARK_GRAY, fontFamily: FONTS.BOLD }}
    >
      {isPending ? <Hash /> : children}
    </button>
  );
};

const CheckController = ({ label, checked, onChange }) => {
  return (
    <label className={style.checkContainer}>
      <input type="checkbox" defaultChecked={checked} onChange={onChange} />
      <p>{label}</p>
    </label>
  );
};

const DragAndDropController = ({
  register,
  registername,
  setValue,
  errors,
  working,
}) => {
  const [video, setVideo] = useState(null);
  const [error, setError] = useState(null);
  const inputRef = useRef();

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      validateAndSetVideo(files[0]);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      validateAndSetVideo(files[0]);
    }
  };

  const validateAndSetVideo = (file) => {
    const validVideoTypes = ["video/mp4", "video/webm", "video/ogg"];
    if (validVideoTypes.includes(file.type)) {
      setVideo(file);
      setError(null);
      setValue(registername, file);
    } else {
      setError("يجب رفع فيديو بأمتداد (MP4, WebM, or OGG)");
      setVideo(null);
      setValue(registername, null);
    }
  };

  useEffect(() => {
    register(registername, {
      value: working,
      message: "يرجي رفع الفيديو اولا",
    });
  }, [register, registername]);

  return (
    <div className={style.videoContainer}>
      {error || errors[registername] ? (
        <p className={style.helperText}>
          {error || errors[registername].message}
        </p>
      ) : null}
      {video ? (
        <div className={style.videoCard}>
          <div>
            <div className={style.icon}>
              <VideoFileIcon />
            </div>
            <p>{video.name}</p>
          </div>
          <button type="button" onClick={() => setVideo(null)}>
            <CloseIcon />
          </button>
        </div>
      ) : (
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={style.dragAndDrop}
          style={{
            borderColor: error || (errors[registername] && COLORS.DANGER),
          }}
        >
          <CloudUploadIcon />
          <p style={{ color: COLORS.DARK_GRAY }}>{"اسحب وضع الفيديو"}</p>
          <p style={{ color: COLORS.DARK_GRAY }}>{"او"}</p>
          <input
            type="file"
            onChange={handleFileChange}
            hidden
            ref={inputRef}
            accept="video/mp4, video/webm, video/ogg"
          />
          <button
            type="button"
            style={{ color: COLORS.DARK_GRAY, padding: 10, borderRadius: 5 }}
            onClick={() => inputRef.current.click()}
          >
            {"رفع من الجهاز"}
          </button>
        </div>
      )}
    </div>
  );
};

const SelectController = ({ children }) => {
  return (
    <h2
      className={style.subTitle}
      style={{
        fontFamily: FONTS.BOLD,
      }}
    >
      {children}
    </h2>
  );
};

Form.TextController = TextController;
Form.PriceController = PriceController;
Form.ReleaseDateController = ReleaseDateController;
Form.ButtonController = ButtonController;
Form.CheckController = CheckController;
Form.DragAndDropController = DragAndDropController;
export default Form;
