import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Input,
} from "@mui/material";
import { COLORS } from "@constants/Colors/colors";
import Paginator from "@shared/Pagination/Paginator";
import CustomTableHead from "@shared/Layout/TableHead/CustomTableHead";
import CustomNoData from "@shared/Layout/NoDataTableRow/CustomNoData";
import { RemoveStudentSubscription } from "./RemoveStudentSubscription/RemoveStudentSubscription";
import { HashLoader } from "react-spinners";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { Search } from "@mui/icons-material";
import useCustomMutation from "@shared/Helpers/hooks/useCustomMutation";
import { currentUser } from "@shared/Helpers/encryptionHelpers";

export const CourseStudents = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("0");
  const [keyword, setKeyword] = useState("0");
  const [totalPages, setTotalPages] = useState();
  const { id } = useParams();
  // const {data, isPending, isSuccess} = useCustomQuery({
  //     url:`course/courseData?id=${id}&page=${page}`,
  //     key:["course", id],
  //     method:"post"
  // })
  const { data, isPending, isSuccess } = useCustomQuery({
    url: `subscription/searchPhone?course_id=${id}&phoneQuery=${
      query || "0"
    }&page=${page}&isAddition=0`,
    method: "get",
    key: [query, "course", id],
  });
  console.log(data);
  useEffect(() => {
    if (isSuccess) {
      setTotalPages(data?.students?.last_page);
    }
  }, [isSuccess]);
  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Noor-bold",
          fontSize: 40,
          color: COLORS.DARK_GRAY,
        }}
      >
        الطلاب المسجلين بالكورس
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display={"flex"} alignItems={"center"} marginLeft={5}>
          <Input
            onChange={(e) => {
              setKeyword(e?.target?.value);
              if (e.target.value === "") {
                setQuery("0");
              }
            }}
            type="text"
            placeholder="ابحث برقم الهاتف"
            sx={{ fontSize: 16, width: 250, height: "100%", paddingY: 1 }}
          />
          <ColorButton
            sx={{ width: 5, height: 46, borderRadius: 0, boxShadow: "0 0 0 0" }}
            onClick={() => {
              setQuery(keyword);
            }}
          >
            <Search />
          </ColorButton>
        </Box>
        <Paginator page={page} setPage={setPage} totalPages={totalPages} />
      </Box>
      {isPending ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <HashLoader color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : (
        <Table sx={{ width: "100%", marginTop: 5 }}>
          <CustomTableHead
            headBackground={COLORS.PRIMARY.MAIN}
            tableHeaders={[
              "اسم الطالب",
              "رقم الطالب",
              "كود التفعيل",
              "تاريخ التفعيل",
              "المدة",
              "",
            ]}
          />
          <TableBody sx={{ width: "100%" }}>
            {data?.data?.data?.length == 0 ? (
              <CustomNoData
                numOfCells={6}
                rowText={
                  query !== "0"
                    ? "لا يوجد طلاب بهذا الرقم"
                    : "لا يوجد طلاب مسجلين بالكورس"
                }
              />
            ) : (
              data?.data?.data?.map((student, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center">{student?.user?.name}</TableCell>
                    <TableCell align="center">{student?.user?.phone}</TableCell>
                    <TableCell align="center">
                      {student?.paymentCode?.code ||
                        student?.payment_code?.code}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(student?.created_at)
                        .toISOString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("-")}
                    </TableCell>
                    <TableCell align="center">
                      {student?.paymentCode?.days ||
                        student?.payment_code?.days}{" "}
                      يوم
                    </TableCell>

                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {currentUser()?.role !== "Supervisor" && (
                          <RemoveStudentSubscription id={student.id} />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
