import { currentUser } from "@shared/Helpers/encryptionHelpers";
import Hero from "./Hero/Hero";
import Introduction from "./Introduction/Introduction";
import TopCourses from "./TopCourses/TopCourses";
import WhyUs from "./WhyUs/WhyUs";
import Footer from "./Footer/Footer";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Home = () => {
  const navigate = useNavigate();
  const userData = currentUser();
  useEffect(() => {
    if (userData?.role) {
      userData?.role === "Student" ? navigate("/home") : navigate("/dashboard");
    }
  });
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  if (currentUser()?.role == "Admin") {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <>
      <Hero />
      <Introduction />
      <WhyUs />
      <TopCourses />
      <Footer />
    </>
  );
};
