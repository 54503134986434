import { COLORS } from "@constants/Colors/colors";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";

export default function EditButton(props) {
  const { sx, ...rest } = props;
  return (
    <CreateRoundedIcon
      data-testid="edit-course-btn"
      sx={{
        color: "#fff",
        bgcolor: COLORS.SUCCESS,
        borderRadius: "50%",
        padding: "4px",
        marginRight: "5px",
        fontSize: "28px",
        cursor: "pointer",
        ...sx,
      }}
      {...rest}
    />
  );
}
