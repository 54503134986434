import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import CourseCard from "@shared/Cards/CourseCard/CourseCard";
import CustomGrid from "@shared/Layout/CustomGrid/CustomGrid";
import Row from "@shared/Layout/Row/Row";
import Paginator from "@shared/Pagination/Paginator";
import Column from "@shared/Layout/Column/Column";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { useState } from "react";
import CourseDetailsSideBar from "@/Admin/Components/Course/CourseDetailsSideBar";
import { useRecoilState } from "recoil";
import { addCourseAtom } from "./RecoilAtoms/addCourseAtom";

export default function CoursesHomePage() {
  const [page, setPage] = useState(1);
  const { data: courses, isPending } = useCustomQuery({
    url: `course/all?page=${page}`,
    key: ["courses"],
    page: page,
  });

  const [_, setAddCourse] = useRecoilState(addCourseAtom);

  return (
    <>
      <Row spacing={"space-between"} align={"flex-start"}>
        <Column
          gap={1}
          spacing={"space-between"}
          align={"flex-start"}
          width={"100%"}
        >
          <Row spacing={"space-between"} mb={2}>
            <ColorButton onClick={() => setAddCourse(true)} width={"100px"}>
              اضف كورس
            </ColorButton>
            <Paginator
              page={page}
              totalPages={courses?.last_page}
              setPage={setPage}
            />
          </Row>
          <CustomGrid
            data-testid="courses-container"
            isLoading={isPending}
            width={"100%"}
          >
            {courses?.data?.map((course) => (
              <CourseCard parent={"courses"} key={course?.id} courseId={course?.id} />
            ))}
          </CustomGrid>
        </Column>
        <CourseDetailsSideBar />
      </Row>
    </>
  );
}
