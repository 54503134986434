import { Box, Typography } from "@mui/material";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import loginImage from "@assets/images/Exams-bro.png";
import logo from "@assets/images/SNA-logo.png";
import UnstyledLink from "@shared/Links/UnstyledLink";
import TextButton from "@shared/Buttons/TextButton/TextButton";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { COLORS } from "@constants/Colors/colors";
import { FONT_SIZES } from "@constants/Fonts/font_sizes";
import { FONTS } from "@constants/Fonts/fonts";
import BorderedInput from "@shared/Form/BorderedInput/BorderedInput";
import { useForm, FormProvider } from "react-hook-form";
import ColorCheckBox from "@shared/Form/ColorCheckBox/ColorCheckBox";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginSchema } from "./login.schema";
import { useLogin } from "./hooks/login.hook";
import Column from "@shared/Layout/Column/Column";
import Row from "@shared/Layout/Row/Row";
import * as Sentry from "@sentry/react";
import { features } from "@config/features";
import { useState } from "react";

export default function Login() {
  const countryCodes = [
    { name: "Bahrain", code: "+973" },
    { name: "Iraq", code: "+964" },
    { name: "Jordan", code: "+962" },
    { name: "Kuwait", code: "+965" },
    { name: "Oman", code: "+968" },
    { name: "Qatar", code: "+974" },
    { name: "Saudi Arabia", code: "+966" },
    { name: "United Arab Emirates", code: "+971" },
  ];
  const [countryCode, setCountryCode] = useState("");

  const methods = useForm({
    resolver: zodResolver(loginSchema),
  });
  const { handleSubmit, register } = methods;

  const { isPending, onSubmit } = useLogin({ countryCode });
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        alignItems={"center"}
        position={"relative"}
        overflow={"hidden"}
        height={"100vh"}
      >
        <Box width={"100%"} position={"absolute"} top={"40px"} right={"60px"}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "15%",
              padding: "5px",
            }}
          />
        </Box>

        <img
          src={loginImage}
          style={{
            zIndex: -1,
            width: "35%",
            paddingBottom: 20,
          }}
        />

        <Column spacing={"space-between"} width={"50%"} gap={4}>
          <Typography
            fontSize={50}
            textAlign={"center"}
            fontFamily={FONTS.REGULAR}
            color={COLORS.DARK_GRAY}
          >
            تسجيل الدخول
          </Typography>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: "60%",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: "40px",
                    fontSize: "15px",
                    color: "#2f8bc8",
                    background: "#fff",
                    padding: "10px 20px",
                  }}
                >
                  كود الدولة
                </span>
                <select
                  style={{
                    background: "#fff",
                    border: "1px solid #2f8bc8",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: "black",
                    transition: "0.4s",
                    appearance: "none",
                  }}
                  {...register("countryCode")}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option value={""}>اختر كود الدولة</option>
                  {countryCodes.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}, {country.code}
                    </option>
                  ))}
                </select>
              </Box>
              <BorderedInput
                placeholder="رقم الهاتف"
                registerName="phone"
                Icon={PhoneInTalkOutlinedIcon}
              />
              <BorderedInput
                placeholder="كلمة المرور"
                registerName="password"
                type="password"
                Icon={LockOutlinedIcon}
              />
              <Row spacing={"space-between"} width={"100%"}>
                <ColorCheckBox label={"تذكرني دائما"} />

                {/* <Typography
                  fontFamily={FONTS.BOLD}
                  fontSize={FONT_SIZES.SMALL}
                  color={COLORS.DARK_GRAY}
                >
                  <UnstyledLink to={"/enter-phone-number"}>
                    نسيت كلمة المرور؟
                  </UnstyledLink>
                </Typography> */}
              </Row>

              <ColorButton
                width={"100%"}
                colorType="submit"
                type="submit"
                isLoading={isPending}
              >
                تسجيل الدخول
              </ColorButton>
              {/* <ColorButton
                onClick={() => {
                  // throw new Error("error");
                  // try {
                  //   throw new Error("error");
                  // } catch (err) {
                  //   Sentry.captureException(err);
                  // }
                }}
                width={"100%"}
                isLoading={isPending}
              >
                error
              </ColorButton> */}
              {features.student && (
                <TextButton>
                  <UnstyledLink to={"/signup"}>او اشترك الان</UnstyledLink>
                </TextButton>
              )}
            </form>
          </FormProvider>
        </Column>
      </Box>
    </>
  );
}
