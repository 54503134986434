import { api_url } from "@constants/base_url";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

export const QuestionImagePicker = ({ exam, setExam, index, defaultImage }) => {
  const [imageSrc, setImageSrc] = useState({
    url: defaultImage
      ? `https://sna.firststepacademy.online/${defaultImage}`
      : "https://www.svgrepo.com/show/508699/landscape-placeholder.svg",
  });

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFile) => {
      const file = acceptedFile[0];
      setExam((prevExam) => ({
        ...prevExam,
        image: file,
      }));
      setImageSrc({
        url: URL.createObjectURL(file),
        file: file,
      });
    },
  });

  return (
    <>
      <div
        style={{
          width: "250px",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
        {...getRootProps()}
      >
        <img
          src={imageSrc?.url}
          style={{ width: 250, cursor: "pointer", objectFit: "cover" }}
          alt=""
        />
      </div>
      <input {...getInputProps()} />
    </>
  );
};
