import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import React, { createContext, useContext, useState } from 'react';

const AllCoursesContext = createContext();

export const useAllCoursesContext = () => {
  return useContext(AllCoursesContext);
};

export const AllCoursesContextProvider = ({ children }) => {

  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const { data, isPending } = useCustomQuery({
    key: ["courses", page, query],
    url:query?`course/search?page=${page}&query=${query}`:`course/search?page=${page}`,
    select: (data) => data,
  });

  // You can include additional state or functions related to the context here

  const contextValue = {
    data,
    isPending,
    setPage,
    page,
    totalPages: data?.data?.last_page,
    query,
    setQuery
    // Add any other values or functions you want to expose
  };

  return <AllCoursesContext.Provider value={contextValue}>{children}</AllCoursesContext.Provider>;
};
