export default function WhyUsStatement({text, counter}) {
    return(<div className={"why-us-container"}>
        {counter?<div className="n-out-container">
            <div className="n-in-container">
                {counter}
            </div>
        </div>:<div className="dot"></div>}
        <div>
            <p>{text}</p>
        </div>
    </div>)
}