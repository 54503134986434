import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api_url } from "@constants/base_url";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";
import { FormProvider, useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { COLORS } from "@constants/Colors/colors";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { FONT_SIZES } from "@constants/Fonts/font_sizes";
import BorderedInput from "@shared/Form/BorderedInput/BorderedInput";
import { LockOutlinedIcon } from "@mui/icons-material/LockOutlined";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateSupervisorSchema } from "./schema/updateSupervisor.schema";
import { currentUser } from "@shared/Helpers/encryptionHelpers";

export default function UpdateSupervisor({
  noteBook,
  closeNote,
  refetch,
  name,
  supervisorId,
}) {
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(name);
  const methods = useForm({
    resolver: zodResolver(updateSupervisorSchema),
  });

  const { mutate, isPending, error, isError } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}supervisors/update`, formData, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser()?.token}`,
        },
      }),
    onSuccess: (data) => {
      console.log(data);
      refetch();
      notifySuccess("تم تعديل بيانات المسئول");
      closeNote();
    },
    onError: (error) => {
      console.log(error);
      notifyError("حدث خطأ اثناء تنفيذ المهمة");
    },
  });

  const { setValue, handleSubmit, formState, clearErrors, getValues } = methods;

  useEffect(() => {
    setFirstName(name);
    setValue("firstName", name);
  }, [name, setValue]);

  const onSubmit = () => {
    console.log(`supervisorId`, supervisorId);
    const { firstName, password } = getValues();
    const formData = {
      name: firstName,
      password: password.toString(),
      supervisor_id: supervisorId.toString(),
    };
    console.log(formData);
    mutate(formData);
  };

  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100vh",
        zIndex: "1500",
        display: "flex",
        // justifyContent: 'center',
        padding: "80px 0px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: COLORS.PRIMARY.MAIN,
        scrollbarWidth: "thin",
        overflow: "hidden",
        opacity: noteBook === "update-supervisor" ? 1 : 0,
        visibility: noteBook === "update-supervisor" ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: "#F5F5F5",
          borderRadius: "10px",
          width: "80%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: noteBook === "update-supervisor" ? 1 : 0,
          transform: noteBook === "update-supervisor" ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              closeNote();
              // reset();
            }}
            sx={{
              color: "white",
              padding: "10px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "primary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          />
        </div>
        <Typography
          fontFamily={"Noor-bold"}
          fontSize={40}
          sx={{
            textShadow: "0px 3px 0px #2f8bc8",
            color: COLORS.DARK_GRAY,
            textAlign: "center",
          }}
        >
          تعديل بيانات المسئول
        </Typography>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginY: "20px" }}>
              <TextField
                inputProps={{
                  style: {
                    // color: "black",
                    WebkitTextFillColor: "#2a2a2a",
                  },
                }}
                {...methods.register("firstName")}
                // registername="firstName"
                placeholder="ادخل الاسم"
                size="small"
                fullWidth
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: COLORS.DARK_GRAY,
                  },
                  "& label": {
                    left: "unset",
                    right: "1.66rem",
                    transformOrigin: "right",
                    fontSize: "1.1rem",
                    color: COLORS.DARK_GRAY,
                  },
                  "& legend": {
                    textAlign: "right",
                    fontSize: "0.6rem",
                  },
                  direction: "rtl",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
              <span
                style={{
                  color: "red",
                  fontSize: "14px",
                  my: "0px",
                  textAlign: "end",
                }}
              >
                {methods.formState.errors.firstName?.message}
              </span>
            </Box>

            <Box sx={{ marginY: "30px" }}>
              <BorderedInput
                {...methods.register("password")}
                registerName="password"
                placeholder="كلمة المرور"
                type="password"
                Icon={LockOutlinedIcon}
              />
              <span
                style={{
                  color: "red",
                  fontSize: "14px",
                  my: "0px",
                  textAlign: "end",
                }}
              >
                {methods.formState.errors.password?.message}
              </span>
            </Box>

            <Box
              sx={{
                marginTop: "50px",
                marginBottom: "30px",
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <ColorButton
                variant="contained"
                type="submit"
                sx={{
                  px: "80px",
                  bgcolor: COLORS.SECONDARY,
                  marginLeft: "20px",
                  textAlign: "center",
                  width: "auto",
                  fontSize: "17px",
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                {isPending ? <ClipLoader color="#000" size={20} /> : "حفظ"}
              </ColorButton>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </div>
  );
}
