import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Switch from "@mui/material/Switch";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "@constants/base_url";

const ToggleCell = ({ placementGrade, id, refetch }) => {
  const [checked, setChecked] = React.useState(false);
  const [grade, setGrade] = React.useState(false);

  console.log(id, "id");

  const { mutate, isPending, error, isError } = useMutation({
    mutationFn: () =>
      axios.get(`${api_url}users/switchAccess?id=${id}`, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser()?.token}`,
        },
      }),
    onSuccess: (data) => {
      console.log(data);
      refetch();
      notifySuccess("تم تعديل درجة تحديد المستوى ");
    },
    onError: (error) => {
      console.log(error);
      notifyError("حدث خطأ اثناء تنفيذ المهمة");
    },
  });

  useEffect(() => {
    if (placementGrade == null) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [placementGrade]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked == true) {
      setGrade(true);
    } else {
      setGrade(false);
    }

    mutate();
  };

  return (
    <Box
      sx={{
        // color: "text.primary",
        borderRadius: 1,
        fontSize: 25,
      }}
    >
      <Switch
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Box>
  );
};

export default ToggleCell;
