import { ROLES } from "@constants/roles";
import { Box, List, CssBaseline, Avatar, Drawer } from "@mui/material";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { useNavigate, Navigate } from "react-router-dom";
import MyListItem from "./MyListItem";
import home from "@assets/adminImages/home.png";
import code from "@assets/adminImages/code.png";
import bell from "@assets/adminImages/bell.png";
import courses from "@assets/adminImages/courses.png";
import studentIcon from "@assets/adminImages/studentIcon.png";
import bookIcon from "@assets/adminImages/bookIcon.png";
import examIcon from "@assets/adminImages/exam.png";
import supervisors from "@assets/adminImages/supervisors.png";
import jawAcademyLogo from "@assets/images/SNA-logo.png";
import avatar from "@assets/adminImages/avatar.png";
import { useState } from "react";
import { COLORS } from "@constants/Colors/colors";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { Outlet } from "react-router-dom";
import AdminLogout from "@components/AdminLogout/AdminLogout";
import { features } from "@config/features";

export default function SideNav() {
  const { name, token } = currentUser();
  const drawerWidth = 260;

  const [adminLogout, setAdminLogout] = useState(false);

  if (!token) return <Navigate to={"/login"} replace />;

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1500",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: adminLogout ? "1" : "0",
          visibility: adminLogout ? "visible" : "hidden",
        }}
      >
        <AdminLogout
          closeAdminLogout={() => setAdminLogout(false)}
          adminLogout={adminLogout}
        />
      </Box>
      <Box sx={{ display: "flex", bgcolor: COLORS.OFF_WHITE }}>
        <CssBaseline />

        <Box component="nav" displayPrint={"none"}>
          <Drawer
            variant="permanent"
            anchor="right"
            sx={{
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
          >
            <ElementDrawer />
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            height: "100vh",
            width: `calc(100% - ${drawerWidth}px)`,
            mr: `${drawerWidth}px`,
          }}
        >
          <Box
            position="fixed"
            sx={{
              width: "100%",
              left: 0,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              "@media print": { display: "none" },
              // bgcolor:"red",
              zIndex: 100,
              bgcolor: COLORS.WHITE,
            }}
          >
            <Box
              className={"admin-profile"}
              sx={{
                borderRadius: "50px",
                bgcolor: "white",
                color: "black",
                width: "auto",
                display: "flex",
                alignItems: "center",
                padding: "3px 3px 3px 20px",
                cursor: "pointer",
                position: "relative",
                transition: "0.4s",
                border: "2px solid white",
                fontSize: "14px",
                "&:hover": {
                  borderColor: "#2f8bc8",
                },
              }}
              variant="contained"
            >
              <Avatar
                alt="Remy Sharp"
                src={avatar}
                sx={{ width: 34, height: 34, ml: 1 }}
              />
              {name}

              <Box
                className="admin-list"
                sx={{
                  position: "absolute",
                  top: 41,
                  left: 0,
                  bgcolor: "#EEEEEE",
                  padding: "20px 10px",
                  borderRadius: "0px 0px 10px 10px",
                  borderTop: "2px solid #2f8bc8",
                  width: "200px",
                  opacity: "0",
                  visibility: "hidden",
                  transform: "translateY(15px)",
                  transition: "0.4s",
                  zIndex: "1000",
                }}
              >
                <ColorButton
                  onClick={() => setAdminLogout(true)}
                  sx={{
                    width: "100%",
                    transition: "0.3s",
                  }}
                >
                  تسجيل الخروج
                </ColorButton>
              </Box>
            </Box>
          </Box>
          <Box mt={10} px={2} pb={2}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const ElementDrawer = () => {
  const navigate = useNavigate();
  const { id, role } = currentUser();
  function navigateHome() {
    switch (role) {
      case ROLES.ADMIN:
        navigate("/dashboard");
        break;
      case ROLES.TEACHER:
        navigate("/dashboard/courses/" + id);
        break;
      default:
        navigate("/dashboard/courses");
    }
  }
  return (
    <>
      <Box
        onClick={navigateHome}
        sx={{
          width: "130px",
          marginX: "auto",
          marginTop: "10px",
          marginBottom: "10px",
          cursor: "pointer",
        }}
      >
        <img
          src={`${jawAcademyLogo}`}
          alt="Logo"
          style={{ width: "100%", padding: "5px" }}
        />
      </Box>

      <List sx={{ width: "80%", marginInline: "auto" }}>
        <MyListItem
          navigatePath="/dashboard"
          locationPath="/dashboard"
          text="الرئيسية"
          img={home}
          roles={[ROLES.ADMIN]}
        />
        <MyListItem
          navigatePath={`/dashboard/courses`}
          locationPath={[
            `/dashboard/courses`,
            `/dashboard/courses/${id}`,
            "/dashboard/courses/AddSessionInCourse",
          ]}
          text="الكورسات"
          img={courses}
          roles={[ROLES.ADMIN]}
        />
        <MyListItem
          navigatePath={
            role !== "Supervisor" ? `/dashboard/courses` : `/dashboard/courses`
          }
          locationPath={[
            `/dashboard/courses`,
            "/dashboard/courses/AddSessionInCourse",
          ]}
          text="الكورسات"
          img={courses}
          roles={[ROLES.TEACHER, ROLES.SUPERVISOR]}
        />
        <MyListItem
          navigatePath="coursesCodes"
          locationPath={[
            "/dashboard/coursesCodes",
            "/dashboard/coursesCodes/codes",
          ]}
          text="الاكواد"
          img={code}
          roles={[ROLES.ADMIN, ROLES.TEACHER, ROLES.SUPERVISOR]}
        />
        {features.notifications && (
          <MyListItem
            navigatePath="notifications"
            locationPath={"/dashboard/notifications"}
            text="الإشعارات"
            img={bell}
            roles={[ROLES.ADMIN]}
          />
        )}
        {features.supervisors && (
          <MyListItem
            navigatePath="supervisors"
            locationPath={"/dashboard/supervisors"}
            text="المشرفين"
            img={supervisors}
            roles={[ROLES.ADMIN]}
          />
        )}

        <MyListItem
          navigatePath="students"
          locationPath={"/dashboard/students"}
          text="الطلاب"
          img={studentIcon}
          roles={[ROLES.ADMIN, ROLES.TEACHER,ROLES.SUPERVISOR]}
        />
        <MyListItem
          navigatePath="book-store"
          locationPath={"/dashboard/book-store"}
          text="متجر الكتب"
          img={bookIcon}
          roles={[ROLES.ADMIN, ROLES.TEACHER]}
        />
        <MyListItem
          navigatePath="placement-test "
          locationPath={"/dashboard/placement-test"}
          text="اختبار تحديد المستوى"
          img={examIcon}
          roles={[ROLES.ADMIN, ROLES.TEACHER]}
        />
      </List>
    </>
  );
};
