import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api_url } from "@constants/base_url";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { HashLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import emptyImg from "../../../assets/adminImages/emptyImg.png";
import BookTeachers from "./BookTeachers/BookTeachers";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDropzone } from "react-dropzone";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import useCustomMutation from "@shared/Helpers/hooks/useCustomMutation";
import { COLORS } from "@constants/Colors/colors";

export default function CreateBook({ noteBook, closeNote, refetch }) {
  const [imageSrc, setImageSrc] = useState({ url: emptyImg });
  const [listOrInput, setListOrInput] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [teacherError, setTeacherError] = useState(false);

  const getSelectedTeacher = (name) => {
    setSelectedTeacher(name);
  };

  // form validation
  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      price: "",
      instructor_name: "",
      reservation_number: "",
      image: "",
      isOpenForReservation: 0,
    },
  });

  const { mutate, isPending, error, isError, status } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}book/createBook`, formData, {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser()?.token}`,
        },
      }),
    onSuccess: () => {
      closeNote();
      refetch();
      reset();
      setImageSrc({ url: emptyImg });
      notifySuccess("تم انشاء كتاب جديد");
      setTeacherError(false);
      setSelectedTeacher(null);
    },
    onError: () => {
      notifyError("حدث خطأ اثناء تنفيذ المهمة");
    },
  });

  console.log(isPending);
  const onSubmit = (data) => {
    console.log("submit data", data);

    const formData = new FormData();
    Object.entries(data).forEach((key) => {
      formData.append(key[0], data[key[0]]);
    });

    if (data.isOpenForReservation) {
      formData.set("isOpenForReservation", 1);
    } else {
      formData.set("isOpenForReservation", 0);
    }

    formData.append("image", data.image[0]);
    if (selectedTeacher === "" || selectedTeacher === null) {
      if (data.instructor_name != "") {
        formData.append("instructor_name", data.instructor_name);
        mutate(formData);
      } else {
        setTeacherError(true);
      }
    } else {
      formData.append("instructor_name", selectedTeacher);
      mutate(formData);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFile) => {
      console.log("acceptedFile", acceptedFile);
      setValue(`image`, acceptedFile);
      setImageSrc({
        url: URL.createObjectURL(acceptedFile[0]),
        file: acceptedFile[0],
      });
    },
  });

  console.log("watching", watch());

  const listOrInputFunction = () => {
    setListOrInput(!listOrInput);
  };

  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100vh",
        zIndex: "1500",
        display: "flex",
        // justifyContent: 'center',
        padding: "80px 0px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: COLORS.PRIMARY.MAIN,
        scrollbarWidth: "thin",
        overflow: "hidden",
        opacity: noteBook === "create-book" ? 1 : 0,
        visibility: noteBook === "create-book" ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: "#F5F5F5",
          borderRadius: "10px",
          width: "70%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: noteBook === "create-book" ? 1 : 0,
          transform: noteBook === "create-book" ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              closeNote();
              reset();
              setImageSrc({ url: emptyImg });
              setTeacherError(false);
              setSelectedTeacher(null);
            }}
            sx={{
              color: "white",
              padding: "8px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "primary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          />
        </div>

        <Typography
          fontFamily={"Noor-bold"}
          mb={6}
          mt={4}
          fontSize={32}
          sx={{
            textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}`,
            textAlign: "center",
          }}
        >
          انشاء كتاب جديد
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                marginBottom: "40px",
                textAlign: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                    padding: "20px 10px 5px 10px",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                  {...getRootProps()}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "-20px",
                      right: "40px",
                      fontSize: "15px",
                      color: COLORS.PRIMARY.MAIN,
                      background: "#F5F5F5",
                      padding: "5px 20px",
                    }}
                  >
                    صورة الكتاب
                  </span>

                  <img
                    src={imageSrc?.url || emptyImg}
                    style={{
                      width: "400px",
                      height: "300px",
                      cursor: "pointer",
                      borderRadius: "10px",
                    }}
                    alt=""
                  />
                </div>
                <input
                  {...getInputProps()}
                  // {...register("image", {
                  //   // required: "يجب ادخال صورة الكتاب",
                  //   validate: (file) => {
                  //     console.log("file", file);
                  //     if (!file) {
                  //       return "يجب ادخال صورة الكتاب";
                  //     }
                  //   },
                  // })}
                />
              </Box>
              {errors.image ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.image?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>

            <Box
              sx={{
                width: "100%",
                marginRight: "30px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "-15px",
                      right: "40px",
                      fontSize: "15px",
                      color: COLORS.PRIMARY.MAIN,
                      background: "#F5F5F5",
                      padding: "0px 20px",
                    }}
                  >
                    عنوان الكتاب
                  </span>
                  <input
                    type="text"
                    placeholder="عنوان الكتاب"
                    style={{
                      background: "#F5F5F5",
                      border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                      padding: "20px 15px",
                      borderRadius: "5px",
                      outline: "none",
                      fontSize: "16px",
                      width: "100%",
                      color: "black",
                      transition: "0.4s",
                    }}
                    {...register("title", {
                      required: `يجب ادخال عنوان الكتاب`,
                    })}
                  />
                  {errors.title ? (
                    <Typography
                      fontSize={15}
                      fontFamily={"Noor-bold"}
                      color={"red"}
                      mt={1}
                      mr={1}
                    >
                      {errors.title?.message}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>

              {listOrInput ? (
                <Box
                  sx={{
                    marginBottom: "30px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <BookTeachers
                      getSelectedTeacher={getSelectedTeacher}
                      selectedTeacher={selectedTeacher}
                      create={"cutomCreate"}
                    />

                    <Button
                      onClick={listOrInputFunction}
                      sx={{
                        background: COLORS.PRIMARY.MAIN,
                        width: "100%",
                        padding: "10px 25px",
                        height: "100%",
                        color: "white",
                        cursor: "pointer",
                        marginRight: "15px",
                        transition: "0.4s",
                        "&:hover": {
                          background: "white",
                          color: COLORS.PRIMARY.MAIN,
                        },
                      }}
                    >
                      اكتب اسم المدرس
                    </Button>
                  </Box>
                  {teacherError ? (
                    <Typography
                      fontSize={15}
                      fontFamily={"Noor-bold"}
                      color={"red"}
                      mt={1}
                      mr={1}
                    >
                      يجب اختيار اسم المدرس
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    marginBottom: "30px",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: "-15px",
                        right: "40px",
                        fontSize: "15px",
                        color: COLORS.PRIMARY.MAIN,
                        background: "#F5F5F5",
                        padding: "0px 20px",
                      }}
                    >
                      اسم المدرس
                    </span>
                    <input
                      type="text"
                      placeholder="اسم المدرس"
                      style={{
                        background: "#F5F5F5",
                        border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                        padding: "20px 15px",
                        borderRadius: "5px",
                        outline: "none",
                        fontSize: "16px",
                        width: "100%",
                        color: "black",
                        transition: "0.4s",
                      }}
                      {...register("instructor_name", {
                        required: `يجب ادخال اسم المدرس`,
                      })}
                    />

                    <Button
                      onClick={listOrInputFunction}
                      sx={{
                        background: COLORS.PRIMARY.MAIN,
                        width: "50%",
                        padding: "10px 25px",
                        height: "100%",
                        color: "white",
                        cursor: "pointer",
                        marginRight: "15px",
                        transition: "0.4s",
                        "&:hover": {
                          background: "white",
                          color: COLORS.PRIMARY.MAIN,
                        },
                      }}
                    >
                      اختر من الدرسين
                    </Button>
                  </Box>
                  {errors.instructor_name ? (
                    <Typography
                      fontSize={15}
                      fontFamily={"Noor-bold"}
                      color={"red"}
                      mt={1}
                      mr={1}
                    >
                      {errors.instructor_name?.message}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              )}

              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  marginBottom: "30px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "40px",
                    fontSize: "15px",
                    color: COLORS.PRIMARY.MAIN,
                    background: "#F5F5F5",
                    padding: "0px 20px",
                  }}
                >
                  سعر الكتاب
                </span>
                <input
                  type="text"
                  min={1}
                  placeholder="سعر الكتاب"
                  style={{
                    background: "#F5F5F5",
                    border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: "black",
                    transition: "0.4s",
                  }}
                  {...register("price", {
                    required: `يجب ادخال سعر الكتاب`,
                    validate: (value) =>
                      !isNaN(parseFloat(value)) || "يجب ادخال رقم",
                  })}
                />
                {errors.price ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.price?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "40px",
                    fontSize: "15px",
                    color: COLORS.PRIMARY.MAIN,
                    background: "#F5F5F5",
                    padding: "0px 20px",
                  }}
                >
                  رقم الحجز
                </span>
                <input
                  type="text"
                  placeholder="رقم الحجز"
                  style={{
                    background: "#F5F5F5",
                    border: `1px solid ${COLORS.PRIMARY.MAIN}`,
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: "black",
                    transition: "0.4s",
                  }}
                  {...register("reservation_number", {
                    required: `يجب ادخال رقم الحجز`,
                    maxLength: {
                      value: 11,
                      message: "يجب ان يكون 11 رقم فقط",
                    },
                    minLength: {
                      value: 11,
                      message: "يجب ان يكون 11 رقم",
                    },
                    pattern: {
                      value: /^(011|010|012|015)\d{8}$/,
                      message: "الرقم غير صحيح",
                    },
                    validate: (value) =>
                      !isNaN(parseFloat(value)) || "يجب ادخال رقم",
                  })}
                />

                {errors.reservation_number ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.reservation_number?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  sx={{
                    my: 2,
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    mr: -1,
                    color: COLORS.PRIMARY.MAIN,
                  }}
                  control={<Checkbox {...register("isOpenForReservation")} />}
                  label="متاح للحجز"
                />

                {errors.isOpenForReservation ? (
                  <Typography
                    fontSize={15}
                    fontFamily={"Noor-bold"}
                    color={"red"}
                    mt={1}
                    mr={1}
                  >
                    {errors.isOpenForReservation?.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="submit"
              onClick={""}
              sx={{
                background: COLORS.PRIMARY.MAIN,
                width: "250px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "white",
                  color: COLORS.PRIMARY.MAIN,
                },
              }}
            >
              {isPending ? <HashLoader color="#ffffff" size={24} /> : "انشاء"}
              {/* <HashLoader color="#ffffff" size={24} /> */}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}
