import { z } from "zod";


export const signUpSchema = z
  .object({
    name: z.string().nonempty("الإسم  مطلوب"),
    phone: z.string().nonempty("رقم الهاتف مطلوب"),
    //   .regex(/^01[0-2,5]{1}[0-9]{8}$/, "رقم الهاتف غير صحيح"),
    parent_phone: z.string().nonempty("رقم ولي الامر مطلوب"),
    //   .regex(/^01[0-2,5]{1}[0-9]{8}$/, "رقم الهاتف غير صحيح"),
    city: z.string("المدينة مطلوبة").nonempty("المدينة مطلوبة"),
    email: z
      .string()
      .email("الايميل غير صحيح")
      .nonempty("البريد الالكتروني مطلوب"),
    password: z
      .string()
      .min(8, "كلمة المرور يجب ان يحتوي على 8 احرف")
      .nonempty("كلمة المرور مطلوبة"),
    password_confirmation: z
      .string()
      .min(8, "كلمة المرور يجب ان يحتوي على 8 احرف")
      .nonempty("تأكيد كلمة المرور مطلوبة"),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "كلمة المرور غير متطابقة",
    path: ["password_confirmation"],
  })
  .refine((data) => data.phone !== data.parent_phone, {
    message: "رقم الطالب يجب ان يكون مختلف عن رقم ولي الامر",
    path: ["phone"],
  });
