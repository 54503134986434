import WhyUsStatement from "../atoms/WhyUsStatement"
import teacher from "@assets/images/Teacher-pana.png"
import student from "@assets/images/Raising hand-pana.png"
import parent from "@assets/images/Fatherhood-pana.png"
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from "@mui/material";
import { useEffect } from "react"
import { COLORS } from "@constants/Colors/colors";

export default function WhyUs() {
    const theme = useTheme();
    
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          } 
        });
      });
  
      const hiddenElements = document.querySelectorAll(".hidden");
      hiddenElements.forEach((el) => observer.observe(el));
  
      // Clean up the observer when the component unmounts
      return () => {
        hiddenElements.forEach((el) => observer.unobserve(el));
      };
    }, []);

    return(<div className={"why-us"}>
        <div className="why-us-section">
          <div className="hidden">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "white",
              px: 10,
              [theme.breakpoints.down('lg')]: {
                display: "block",
              },

              [theme.breakpoints.down('md')]: {
                paddingX: 4,
              },
              zIndex: -1
            }}
          >
            <Box
              color={"background.main"}
              width={"50%"}
              sx={{
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                // flexDirection: 'column',
                [theme.breakpoints.down('lg')]: {
                  width: "100%",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Cairo',
                  fontWeight: '800',
                  fontSize: 50,
                  [theme.breakpoints.down('md')]: {
                    fontSize: 35,
                  },
                  color:COLORS.PRIMARY.MAIN
                }}
              >
                <span
                  style={{
                    backgroundColor: COLORS.PRIMARY.MAIN,
                    width: "16px",
                    height: "16px",
                    display: "inline-block",
                    marginLeft: "20px",
                  }}
                ></span>
                إزاي الأبلكيشن هيساعد المدرس ؟
              </Typography>
              
            </Box>
            
          </Box>
          </div>
          <div className="hidden hidden-img">
            <img src={teacher} width={"70%"}/>
          </div>
          
          <div className={"hidden"}>
              <WhyUsStatement text={`رفع الفيديوهات عن طريق إضــــــافة لينك الفيديو في "الداش بورد" فقط ثــم تحميل الفيديو عـــلى السيرفـــر الخاص بنا مع التأمين بالطرق المذكورة سابقًا.`} counter={"01"}/>
          </div>
          <div className={"hidden"}>
              <WhyUsStatement text={`سهولة إستخــــدام "الداش بــــورد" في إضافة الحصص أوالإمتحـــــــــــانات أو الملازم عن طريق ضغطة على زرار المدرس وبعــــــــــــدها إضافة (حصة، إمتحان، ملف .... ) بتتم الإضافة.`} counter={"02"}/>
          </div>
          <div className={"hidden"}>
              <WhyUsStatement text={`خاصية "Pre-requisite" هتساعد المدرس إنه يربط دخول الطالب للحصص بحل إمتحان معين.`} counter={"03"}/>
          </div>
        </div>
        <div className="why-us-section">
          <div className="hidden">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "white",
              px: 10,
              [theme.breakpoints.down('lg')]: {
                display: "block",
              },

              [theme.breakpoints.down('md')]: {
                paddingX: 4,
              },
              zIndex: -1
            }}
          >
            <Box
              color={"background.main"}
              width={"50%"}
              sx={{
                [theme.breakpoints.down('lg')]: {
                  width: "100%",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Cairo',
                  fontWeight: '800',
                  fontSize: 50,
                  [theme.breakpoints.down('md')]: {
                    fontSize: 35,
                  },
                  color:COLORS.PRIMARY.MAIN
                }}
              >
                <span
                  style={{
                    backgroundColor: COLORS.PRIMARY.MAIN,
                    width: "16px",
                    height: "16px",
                    display: "inline-block",
                    marginLeft: "20px",
                  }}
                ></span>
                إزاي المدرس يتابع الطالب ؟
              </Typography>
              
            </Box>
            
          </Box>
          </div>
          <div className="hidden">
            <p>توفير كل البيانات الخــــــــاصة بالطـــــــالب من رقم تليفـــــــــونه، الكـــــــود الخــــــــــاص بـــه وكل خطواته ورقم ولي أمره في الداش بورد الخاصة بالمدرس.</p>
          </div>
          <div className="hidden">
            <p>بيكون الأدمـــــــن مسؤول عن عمل الكود للكورس وبينزله في ملف pdf وبيتم إرسالها للطالب. والعملية بتتم كالآتي :</p>
          </div>
          
          <div className={"hidden"}>
              <WhyUsStatement text={`بيدخل الداش بـــــــورد يدوس إنشاء كود لهذا الكورس، الداش بورد بتطلب عدد الأكــــــواد اللي المدرس محتـــــــاجها و المدة( يعني عايز الكود متفعل لمده قد ايه ).`}/>
          </div>
          <div className={"hidden"}>
              <WhyUsStatement text={`بيحدد مثلا عايز 1000 كـــــــــــود لمده 30 يوم أول ما بيضغط إنشاء، بيتعمله ملف pdf فيه الــــــــــــ 1000 كود كاملين سواء عايزهم كود أو code QR.`}/>
          </div>
        </div>
        <div className="why-us-section">
          <div className="hidden">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "white",
              px: 10,
              [theme.breakpoints.down('lg')]: {
                display: "block",
              },

              [theme.breakpoints.down('md')]: {
                paddingX: 4,
              },
              zIndex: -1
            }}
          >
            <Box
              color={"background.main"}
              width={"50%"}
              sx={{
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                // flexDirection: 'column',
                [theme.breakpoints.down('lg')]: {
                  width: "100%",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Cairo',
                  fontWeight: '800',
                  fontSize: 50,
                  [theme.breakpoints.down('md')]: {
                    fontSize: 35,
                  },
                  color:COLORS.PRIMARY.MAIN
                }}
              >
                <span
                  style={{
                    backgroundColor: COLORS.PRIMARY.MAIN,
                    width: "16px",
                    height: "16px",
                    display: "inline-block",
                    marginLeft: "20px",
                  }}
                ></span>
                إزاي الطالب هيستخدم الأبلكيشن ؟
              </Typography>
              
            </Box>
            
          </Box>
          </div>
          <div className="hidden hidden-img">
            <img src={student} width={"70%"}/>
          </div>
          
          <div className={"hidden"}>
              <WhyUsStatement text={`الأبلكيشن متوفر علي جوجل بلاي و آب ستور و آب جاليري يقدر الطالب يستخدمه عن طريق الأندرويد وا الأيفون او هواوي او تابلت او لابتوب`}/>
          </div>
          <div className={"hidden"}>
              <WhyUsStatement text={`بيقوم الطالب وقت دخوله للـ screen home بكتابة الكـــــود أو عمل code QR Scan  اللي بيبتهوله المدرس بتنزله فورا إشتـــــــــــــراكاته وبيظهرله المده اللي هينتهي بعدها الإشتراك الخاص به, الكود بتكون المدة من 30 يوم من تاريخ استخدام الطالب ليه و بعد انتهاء صلاحية الكود بيتم مسح المحتوي لديه.`}/>
          </div>
          <div className={"hidden"}>
              <WhyUsStatement text={`موجـــــــــــود Screen بتظِ هر الإحصــــــــــــائيات اللي اتمها الطالب في الكورس الخاص به من دورس وامتحانات.`}/>
          </div>
          <div className={"hidden"}>
              <WhyUsStatement text={`الكود الخاص بالطالب بيعرفه المده المتبقية لإنتهاء الكورس وقبل إنتهاء الكورس بيوم بيبعتله نوتفكيشن.`}/>
          </div>
        </div>
        <div className="why-us-section">
          <div className="hidden">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "white",
              px: 10,
              [theme.breakpoints.down('lg')]: {
                display: "block",
              },

              [theme.breakpoints.down('md')]: {
                paddingX: 4,
              },
              zIndex: -1
            }}
          >
            <Box
              color={"background.main"}
              width={"50%"}
              sx={{
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                // flexDirection: 'column',
                [theme.breakpoints.down('lg')]: {
                  width: "100%",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Cairo',
                  fontWeight: '800',
                  fontSize: 50,
                  [theme.breakpoints.down('md')]: {
                    fontSize: 35,
                  },
                  color:COLORS.PRIMARY.MAIN
                }}
              >
                <span
                  style={{
                    backgroundColor: COLORS.PRIMARY.MAIN,
                    width: "16px",
                    height: "16px",
                    display: "inline-block",
                    marginLeft: "20px",
                  }}
                ></span>
                إزاي كولي أمر أقدر اتابع الطالب بتاعي ؟
              </Typography>
              
            </Box>
            
          </Box>
          </div>
          <div className="hidden hidden-img">
            <img src={parent} width={"70%"}/>
          </div>
          <div className={"hidden"}>
              <WhyUsStatement text={`وجود تقرير شهري شامل عن أداء الطــالب فــــي كـــــــل نواحي الكورس بيتم إرساله لولي الأمر.`} counter={"01"}/>
          </div>
          <div className={"hidden"}>
              <WhyUsStatement text={`وجــــــود وجهة مخصصة لولي الأمر لمتابعة مستويات الطالب`} counter={"02"}/>
          </div>
        </div>
    </div>)
}