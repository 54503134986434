import { Box, Button, FormHelperText, TextField } from "@mui/material";
import { COLORS } from "@constants/Colors/colors";
import { useState } from "react";
import Modal from "@shared/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { ClipLoader } from "react-spinners";
import { zodResolver } from "@hookform/resolvers/zod";
import useAddCourseFile from "../CourseModules/ModuleDetailsCollapse/hook/useAddFile";
import addFileSchema from "../CourseModules/schema/addFile.schema";

export const CreateCourseFile = ({ id }) => {
  const [open, setOpen] = useState(false);
  const methods = useForm({
    resolver: zodResolver(addFileSchema),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      file: "",
      type: "Course",
      id: "",
    },
  });

  const { mutate, isLoading } = useAddCourseFile({
    onSettled: close,
  });

  function submit(data) {
    const formData = {
      name: data?.name,
      file: data?.file[0],
      id: id,
      type: "Course",
    };

    mutate(formData);

    setOpen(false);
  }
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{
          bgcolor: COLORS.PRIMARY.MAIN,
          color: COLORS.WHITE,
          "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
        }}
      >
        أضف ملف
      </Button>
      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(submit)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
              width: "60%",
              margin: "auto",
            }}
          >
            <TextField
              registername={"name"}
              placeholder="ادخل اسم الملف"
              type="text"
              {...register("name", {
                required: `هذا الحقل مطلوب`,
              })}
              fullWidth
              sx={{
                direction: "rtl",
              }}
            />
            <FormHelperText sx={{ color: COLORS.DANGER }}>
              {errors?.name && errors?.name?.message}
            </FormHelperText>

            <TextField
              registername={"file"}
              accept=".pdf"
              placeholder="الملف"
              {...register("file", {
                required: `يجب رفع الملف اولا`,
              })}
              type="file"
              fullWidth
              sx={{
                direction: "rtl",
              }}
            />

            <FormHelperText sx={{ color: COLORS.DANGER }}>
              {errors?.file && errors?.file?.message}
            </FormHelperText>

            <Box
              sx={{
                marginTop: "50px",
                marginBottom: "30px",
                textAlign: "center",
              }}
            >
              <ColorButton
                disabled={isLoading}
                type="submit"
                variant="contained"
                sx={{ bgcolor: "#2f8bc8", marginLeft: "20px" }}
                //   onClick={addRowInSession}
              >
                {isLoading ? <ClipLoader color="#000" size={20} /> : "حفظ"}
              </ColorButton>
            </Box>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};
