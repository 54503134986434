import { z } from "zod";

export const createCourseSchema = z.object({
  name: z.string().min(1, "اسم الكورس مطلوب"),
  description: z.string().min(1, "وصف الكورس مطلوب"),
  duration: z.coerce.number().min(1, "مدة الكورس مطلوبة"),
  price: z.coerce.number().min(0, "سعر الكورس مطلوب"),
  image: z
    .array(z.instanceof(File), {
      required_error: "صورة الكورس مطلوبة",
    })
    .nonempty("صورة الكورس مطلوبة"),
});
