import { COLORS } from "@constants/Colors/colors";
import { Box, Button, TextField, Typography } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import Modal from "@shared/Modal/Modal";
import React, { useEffect, useRef, useState } from "react";
// import { useAddNotification } from "./useAddNotification.hook";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import axios from "axios";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { api_url } from "@constants/base_url";
import { ClipLoader } from "@shared/Spinners/Clip/clip.stories";
import { HashLoader } from "react-spinners";


export default function AddNotifications({
  modalNotification,
  closeModalNotification,
  refetch,
}) {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessagebody, setErrorMessagebody] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const timerRef = useRef();
  useEffect(() => {
    const timer = timerRef.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (title.trim().length === 0) {
        setErrorMessage("عنوان الاشعار مطلوب");
        return;
      }
      if (title.length < 8) {
        setErrorMessage("يجب ان يكون العنوان علي الاقل 8 حروف");
        return;
      }

      if (body.trim().length === 0) {
        setErrorMessagebody("نص الاشعار مطلوب");
        return;
      }
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${api_url}sendTopicNotification`,
        formData,
        config
      );
      notifySuccess("تمت الاضافه بنجاح");
      closeModalNotification();
      // reset();
      refetch();
      timerRef.current = setTimeout(() => {
        closeModalNotification();
      }, 2000);
    } catch (error) {
      notifyError("حدث خطا اثناء التنفيذ");
      closeModalNotification();
      console.error("Error sending notification:", error);
    } finally {
      setIsLoading(false);
      modalNotification = true;
      
    }
  };
  return (
    <Modal
      maxWidth={"sm"}
      open={modalNotification}
      closeFn={closeModalNotification}
    >
      <Typography
        fontFamily={"Noor-bold"}
        fontSize={30}
        sx={{ textShadow: "0px 3px 0px #2f8bc8", color: "black" }}
      >
        أدخل بيانات الإشعار
      </Typography>

      <Box sx={{ width: "65%", margin: "auto" }}>
        <Box sx={{ marginY: "20px" }}>
          <TextField
            inputProps={{
              style: {
                // color: "black",
                WebkitBoxShadow: "0 0 0 1000px white inset",
                WebkitTextFillColor: "#2a2a2a",
              },
            }}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
              setErrorMessage("");
            }}
            placeholder="ادخل عنوان الإشعار "
            label=" عنوان الإشعار"
            size="small"
            fullWidth
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: COLORS.DARK_GRAY,
              },
              "& label": {
                left: "unset",
                right: "1.75rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: COLORS.DARK_GRAY,
              },
              "& legend": {
                textAlign: "right",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <span
            style={{
              color: "red",
              fontSize: "14px",
              my: "0px",
              textAlign: "end",
            }}
          >
            {errorMessage}
          </span>
        </Box>

        <Box sx={{ marginY: "30px" }}>
          <TextField
            inputProps={{
              style: {
                WebkitBoxShadow: "0 0 0 1000px white inset",
                WebkitTextFillColor: "#2a2a2a",
              },
            }}
            value={body}
            onChange={(event) => {
              setBody(event.target.value);
              setErrorMessagebody("");
            }}
            placeholder="ادخل نص الإشعار "
            label=" نص الإشعار"
            fullWidth
            multiline
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: COLORS.DARK_GRAY,
              },
              "& label": {
                left: "unset",
                right: "1.75rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: COLORS.DARK_GRAY,
              },
              "& legend": {
                textAlign: "right",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <span
            style={{
              color: "red",
              fontSize: "14px",
              my: "0px",
              textAlign: "end",
            }}
          >
            {errorMessagebody}
          </span>
        </Box>

        <Box
          sx={{
            marginTop: "50px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            onClick={handleSubmit}
            sx={{
              background: COLORS.PRIMARY.MAIN,
              width: "250px",
              padding: "10px 25px",
              color: "white",
              cursor: "pointer",
              transition: "0.4s",
              "&:hover": {
                background: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          >
            {isLoading ? <HashLoader color="#ffffff" size={20} /> : "ارسال"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
