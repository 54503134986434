import useCustomMutation from "@/Admin/Shared/Helpers/hooks/useCustomMutation";

export default function useUpdateCourse({ courseId, onSettled }) {
  const { mutate, isPending } = useCustomMutation({
    url: "course/update",
    invalidate: [["course", courseId], "courses"],
    method: "post",
    successMsg: "تم تعديل الكورس بنجاح",
    onSettled,
  });

  function updateCourse(data) {
    mutate({ ...data, id: courseId });
  }

  return { updateCourse, isPending };
}
