import * as zod from "zod";

const addFileSchema = zod.object({
  name: zod
    .string()
    .refine({
      message: "يجب ادخال اسم الملف",
      async validate(value) {
        return /^[a-zA-Z\s\u0621-\u064A]+$/.test(value);
      },
    }),
  file: zod
    .string()
    .refine({
      message: "يجب رفع الملف اولا",
      async validate(value) {
        return !!value; // Simple check for non-empty value
      },
    }),
});

export default addFileSchema;