import Button from "@mui/material/Button";
import { FONT_SIZES } from "../../../Constants/Fonts/font_sizes";
import { COLORS } from "../../../Constants/Colors/colors";
import { FONTS } from "../../../Constants/Fonts/fonts";
import Hash from "../../Spinners/Hash/hash";
import PropTypes from "prop-types";

export default function TextButton(props) {
  const { children, isLoading, color, sx, ...rest } = props;
  return (
    <Button
      disabled={isLoading}
      sx={{
        fontFamily: FONTS.REGULAR,
        fontSize: FONT_SIZES.REGULAR,
        color: color || COLORS.PRIMARY.MAIN,
        cursor: "pointer",
        ...sx,
      }}
      variant="text"
      {...rest}
    >
      {isLoading ? <Hash color={color || COLORS.PRIMARY.MAIN} /> : children}
    </Button>
  );
}

TextButton.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  sx: PropTypes.object,
};
