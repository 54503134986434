import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { api_url } from "@constants/base_url";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import CustomNoData from "@shared/Layout/NoDataTableRow/CustomNoData";
import UnassignCourse from "./UnassignCourse";
import AddSupervisorTeacher from "./AddSupervisorTeacher";
import CustomTableHead from "@shared/Layout/TableHead/CustomTableHead";
import { COLORS } from "@constants/Colors/colors";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";

export default function SupervisorTeacher({
  noteBook,
  closeNote,
  supervisorId,
  // refetch,
}) {
  const tableHeader = ["الرقم", "اسم الكورس", "الوصف", "الاعدادت"];
  const { data, error, isPending, isError, refetch } = useCustomQuery({
    url: `supervisors/getCoursesOfSupervisor?supervisor_id=${+supervisorId}`,
    key: ["getCoursesOfSupervisor", supervisorId],
    enabled: !isNaN(+supervisorId),

  });

  const [subscribtionId, setSubscribtionId] = useState();

  const getSubscribtionId = (id) => {
    console.log(`course id`, id);
    setSubscribtionId(id);
  };

  const [noteSubscribe, setNoteSubscribe] = useState(false);
  const openSubNote = () => {
    setNoteSubscribe(true);
  };

  const closeSubNote = () => {
    setNoteSubscribe(false);
  };

  // add super teacher
  const [noteSuperTeacher, setNoteSuperTeacher] = useState(false);
  const openSuperTeahcer = () => {
    setNoteSuperTeacher(true);
  };

  const closeSuperTeacher = () => {
    setNoteSuperTeacher(false);
  };

  return (
    <div
      className="device-cover"
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100%",
        zIndex: "1500",
        display: "flex",
        justifyContent: "center",
        padding: "20px 0px",
        alignItems: "center",
        transition: "0.3s",
        opacity: noteBook === "teachers" ? 1 : 0,
        visibility: noteBook === "teachers" ? "visible" : "hidden",
      }}
    >
      <UnassignCourse
        supervisorID={supervisorId}
        subscribtionId={subscribtionId}
        closeSubNote={closeSubNote}
        noteSubscribe={noteSubscribe}
        refetch={refetch}
      />

      <AddSupervisorTeacher
        noteSuperTeacher={noteSuperTeacher}
        setNoteSuperTeacher={setNoteSuperTeacher}
        closeSuperTeacher={closeSuperTeacher}
        id={supervisorId}
        refetch={refetch}
      />

      <div
        className="close-btn"
        style={{
          position: "absolute",
          top: 50,
          right: 100,
          zIndex: 100,
        }}
      >
        <Close
          onClick={closeNote}
          sx={{
            color: "black",
            background: COLORS.WHITE,
            padding: "8px 10px",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            transition: "0.4s",
            cursor: "pointer",
            "&:hover": {
              color: "#2f8bc8",
            },
          }}
        />
      </div>

      <Box
        className="device-box"
        px={3}
        sx={{
          // padding: '30px',
          // background: '#F5F5F5',
          // borderRadius: '10px',
          width: "70%",
          height: "80%",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "#2f8bc8",
          transition: "0.3s",
          opacity: noteBook ? 1 : 0,
          transform: noteBook ? "scale(1)" : "scale(0)",
        }}
      >
        <Box
          mb={2}
          sx={
            {
              // bgcolor: "white",
              // padding: ' 10px',
              // borderRadius: '5px'
            }
          }
        >
          <Button
            onClick={openSuperTeahcer}
            sx={{
              bgcolor: "green",
              color: COLORS.WHITE,
              padding: "7px 20px",
              marginLeft: "10px",
              transition: "0.3s",
              "&:hover": {
                bgcolor: COLORS.DARK_GRAY,
              },
            }}
          >
            أضف كورس
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table
            sx={{ direction: "rtl", color: "white" }}
            aria-label="simple table"
          >
            <CustomTableHead
              headBackground={COLORS.PRIMARY.MAIN}
              tableHeaders={tableHeader}
            />
            <TableBody>
              {isPending ? (
                <>
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      style={{ textAlign: "center", padding: "0px  20px" }}
                    >
                      <Skeleton height={60} />
                    </TableCell>
                  </TableRow>
                </>
              ) : data?.data?.data ? (
                <>
                  {data?.data?.data.length != 0 ? (
                    <>
                      {data?.data?.data?.map((item, i) => (
                        <TableRow
                          key={`a${i}sm`}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell align="center">{i + 1}</TableCell>
                          <TableCell align="center" component="th" scope="row">
                            <Typography
                              fontSize={18}
                              fontWeight={"bold"}
                              fontFamily={"Noor-bold"}
                            >
                              {item?.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            <Typography
                              fontSize={18}
                              fontWeight={"bold"}
                              fontFamily={"Noor-bold"}
                            >
                              {item?.description}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  openSubNote();
                                  getSubscribtionId(item.id);
                                }}
                                aria-label="delete"
                                size="small"
                                sx={{
                                  bgcolor: "#db3a3c",
                                  "&:hover": {
                                    bgcolor: "#db3a3c",
                                  },
                                }}
                              >
                                <DeleteIcon
                                  fontSize="inherit"
                                  sx={{ color: "#fff" }}
                                />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      <CustomNoData rowText={"لا يوجد كورسات"} numOfCells={7} />
                    </>
                  )}
                </>
              ) : (
                <CustomNoData rowText={"لا يوجد كورسات"} numOfCells={7} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
