import useCustomMutation from "@/Admin/Shared/Helpers/hooks/useCustomMutation";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { useQueryClient } from "@tanstack/react-query";
import { notifyError } from "@shared/Toast/toast";
import { useState } from "react";
import * as tus from "tus-js-client";
import { uploadVideoProgress } from "./../Atom/Progress.atom";
import { TitleUploadVideo } from "./../Atom/TitleUploadVideo.atom";
import { useRecoilState } from "recoil";

export default function useAddVideo({ onSuccess }) {
  const queryClient = useQueryClient();
  const instance = useAxiosConfig();
  const [video, setVideo] = useState({});
  const [name, setName] = useState("");
  const [uploadVideoProg, setUploadVideoProg] =
    useRecoilState(uploadVideoProgress);
  const [TitleUploadVid, setTitleUploadVid] = useRecoilState(TitleUploadVideo);
  const { mutate, isPending } = useCustomMutation({
    url: "lesson/store",
    invalidate: ["lesson"],
    method: "post",
    successMsg: "تم اضافة الفيديو بنجاح",
    notifySuccess: "تم اضافة الفيديو بنجاح",
    onSuccess: async (response_store) => {
      console.log(response_store, "response_store");
      const data = response_store.data;

      if (video && video instanceof File) {
        // Video exists and is a valid file, proceed with upload
        try {
          console.log(video, "video");

          // Fetch resumable upload headers
          const res = await instance.get(
            `lesson/video/resumableUpload/${data.id}`
          );
          console.log(res, "res");

          // Set up TUS upload
          var upload = new tus.Upload(video, {
            endpoint: "https://video.bunnycdn.com/tusupload",
            retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
            headers: {
              AuthorizationSignature:
                res?.data?.data?.headers?.AuthorizationSignature,
              AuthorizationExpire:
                res?.data?.data?.headers?.AuthorizationExpire,
              VideoId: res?.data?.data?.headers?.VideoId,
              LibraryId: res?.data?.data?.headers?.LibraryId,
            },
            metadata: {
              filetype: video.type,
              title: name,
              collection: res?.data?.data?.collectionId,
            },
            onError: function (error) {
              console.log("Upload error:", error);
              notifyError("An error occurred during the upload process.");
            },
            onProgress: function (bytesUploaded, bytesTotal) {
              setUploadVideoProg(
                Math.round((bytesUploaded / bytesTotal) * 100)
              );
              setTitleUploadVid(name);
              console.log("progress");
            },
            onSuccess: function () {
              console.log("Upload completed successfully.");
              queryClient.invalidateQueries(["lesson"]);
              setUploadVideoProg(null);
              setTitleUploadVid(null);
              const res = instance
                .post(`lesson/updateVideoFlag/${data.id}`)
                .then((data) => {
                  console.log(data, "updateVideoFlag");
                })
                .catch((err) => {
                  console.log(err, "error");
                });
            },
          });

          // Resume previous uploads if any
          const previousUploads = await upload.findPreviousUploads();
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
          }

          // Start the upload
          upload.start();
        } catch (error) {
          console.error("Error during the upload process:", error);
          notifyError("An error occurred during the upload process.");
        }
      } else {
        console.log("No valid video file to upload.");
      }
      queryClient.invalidateQueries(["lesson"]);
    },
    onError: (error) => {
      console.error("Error storing lesson:", error);
      notifyError(
        error?.response?.data?.message ||
          "An error occurred while storing the lesson"
      );
    },
  });

  function addModuleVideo(data) {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("link", data.link);
    formData.append("module_id", data.module_id);

    setName(data.name);
    if (!data.link) {
      formData.delete("link");
      setVideo(data.video);
    } else {
      setVideo(null);
    }

    // Ensure the video file is included in the mutation payload
    mutate(formData);
  }

  return { addModuleVideo, isPending };
}
