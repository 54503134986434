import useCustomMutation from "@/Admin/Shared/Helpers/hooks/useCustomMutation";
import { queryClient } from "@config/queryClient.config";
import { notifyError, notifySuccess } from "@shared/Toast/toast";

export default function useDeleteExam({ id, onSettled }) {
  const { mutate:deleteExamMutation, isPending:isExamLoading } = useCustomMutation({
    url: `exam/deleteExam?exam_id=${id}`,
    invalidate: ["module exam", id],
    method: "delete",
    onSettled,
    onSuccess: () => {
        notifySuccess("تم حذف الفيديو بنجاح");
        queryClient.invalidateQueries(["module exam",id]);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
  });

  function deleteExam() {
    deleteExamMutation();
  }

  return { deleteExam, isExamLoading };
}
