import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "@assets/images/SNA-logo.png";
import { useTheme } from "@mui/material/styles";
import { COLORS } from "@constants/Colors/colors";
export default function NavBar({ bar }) {
  const theme = useTheme();
  return (
    <>
      <Box
        backgroundColor={"#ffffff"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={5}
        py={2}
      >
        <Link
          to={"/"}
          style={{
            textDecoration: "none",
          }}
        >
          <img
            src={logo}
            alt="logo"
            className="logo"
            style={{
              width: "20%",
              [theme.breakpoints.down("lg")]: {
                width: "70%", // Apply smaller font size on small screens
              },
              padding: "5px",
            }}
          />
        </Link>
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={4}
          alignItems={"center"}
        >
          <Link
            to={"/login"}
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button
              sx={{
                color: "white",
                backgroundColor: COLORS.SECONDARY.MAIN,
                borderRadius: "8px",
                px: 4,
                py: 1,
                fontSize: 16,
                [theme.breakpoints.down("lg")]: {
                  fontSize: 14,
                  display: "none", // Apply smaller font size on small screens
                },
                fontFamily: "Noor-bold",
                "&:hover": {
                  backgroundColor: COLORS.PRIMARY.MAIN,
                },
              }}
            >
              تسجيل الدخول{" "}
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
}
