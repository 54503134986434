import { COLORS } from "@constants/Colors/colors";
import { Paper } from "@mui/material";

export default function Empty({ children, sx }) {
  return (
    <Paper
      sx={{
        height: "86vh",
        width: "35%",
        mx: 2,
        backgroundColor: COLORS.WHITE,
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
}
