import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { COLORS } from "@constants/Colors/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { api_url } from "@constants/base_url";
import DeleteDialog from "../../DeleteDialog";
import { currentUser } from "@shared/Helpers/encryptionHelpers";

export const ModuleFile = ({ file, id }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteName, setDeleteName] = useState();
  const [confirmMessage, setConfirmMessage] = useState("");
  // console.log(file)
  const handleDeleteClick = () => {
    setIsConfirmOpen(true);
    setConfirmMessage("هل انت متأكد من حذف الملف");
    setDeleteData(file);
    setDeleteName(file?.url?.split("/")?.pop());
  };
  // console.log(file, `fileMod`);
  // console.log(deleteName, `name`);
  return (
    <Box
      sx={{
        width: "100%",
        paddingX: 2,
        color: COLORS.WHITE,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <InsertDriveFileIcon sx={{ color: COLORS.PRIMARY.MAIN }} />
        <a
          href={`${api_url.slice(0, -8)}${file.url}`}
          style={{ color: COLORS.WHITE }}
        >
          {file?.name?.length > 20
            ? file?.name?.slice(0, 20) + "..."
            : file?.name}
        </a>
      </Box>
      {currentUser()?.role !== "Supervisor" && (
        <IconButton
          sx={{
            bgcolor: COLORS.DANGER,
            "&:hover": { bgcolor: COLORS.DANGER },
            width: 28,
            height: 28,
          }}
          onClick={handleDeleteClick}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
      <DeleteDialog
        isConfirmOpen={isConfirmOpen}
        setIsConfirmOpen={setIsConfirmOpen}
        deleteName={deleteName}
        deleteData={deleteData}
        confirmMessage={confirmMessage}
        id={id}
      />
    </Box>
  );
};
