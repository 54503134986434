import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, IconButton } from "@mui/material";
import { COLORS } from "@constants/Colors/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteCourseFile } from "./DeleteCourseFile";
import { currentUser } from "@shared/Helpers/encryptionHelpers";

export default function CourseFileBox({ item }) {
  const [del, setDel] = useState(false);
  const { id } = useParams();

  return (
    <Box
      key={item.id}
      sx={{
        width: "100%",
        height: 100,
        borderRadius: 2,
        color: COLORS.WHITE,
        bgcolor: COLORS.DARK_GRAY,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box marginTop={2} sx={{ display: "flex", gap: 2 }}>
        {currentUser()?.role !== "Supervisor" && (
          <IconButton
            onClick={() => setDel(true)}
            sx={{
              bgcolor: COLORS.DANGER,
              "&:hover": { bgcolor: COLORS.DANGER },
              width: 28,
              height: 28,
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Typography>{item.name}</Typography>
      <DeleteCourseFile
        filename={item?.url?.split("/")?.pop()}
        id={id}
        isConfirmOpen={del}
        setIsConfirmOpen={setDel}
        confirmMessage={`هل تريد حذف ملف `}
        deleteName={item.name}
      />
    </Box>
  );
}
