import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { Navigate } from "react-router-dom";
import { api_url } from "@constants/base_url";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useRecoilState } from "recoil";
import { BookActiveAtom } from "../RecoilAtoms/BookActive.atom";
import { ROLES } from "@constants/roles";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { COLORS } from "@constants/Colors/colors";
import getImageUrl from "@shared/Helpers/image/getImageUrl";
import CustomTableHead from "@shared/Layout/TableHead/CustomTableHead";
import CustomTableLoading from "@shared/Layout/TableRowLoading/CustomTableLoading";
import Paginator from "@shared/Pagination/Paginator";

export default function BookOrders() {
  const tableHeader = [
    "اسم الطالب",
    "صورة الحجز",
    "الرقم الأول",
    "الرقم الثاني",
    "العنوان",
    "رقم الحجز",
    "تاريخ الحجز",
    "عدد النسخ",
    "",
  ];

  const bookData = useRecoilState(BookActiveAtom);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const { id } = useParams();

  const activeBook = JSON.parse(localStorage.getItem("activeBook"));

  const { data, isPending, error, isError, refetch } = useCustomQuery({
    url: `bookOrder/showByBook?book_id=${id}&page=${page}`,
    method: "get",
    key: ["bookOrder"],
    page: page,
    onSuccess: () => {
      setTotalPages(data?.data?.last_page);
    },
  });

  console.log(`query data`, data);

  const [getBoodData, setGetBookData] = useState();

  const [imageBox, setImage] = useState();
  const showImage = (img) => {
    setImage(img);
  };

  const [openImage, setOpenImage] = useState(false);

  const openIamgeBox = () => {
    setOpenImage(true);
  };

  const closeImageBox = () => {
    setOpenImage(false);
  };

  const { mutate: confirmOrder } = useMutation({
    mutationFn: ({ id, confirm }) =>
      axios.post(
        `${api_url}bookOrder/confirmOrder`,
        { order_id: id, is_confirmed: confirm },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser()?.token}`,
          },
        }
      ),
    onSuccess: () => {
      refetch();
      notifySuccess("تم تاكيد الطلب بنجاح");
    },
    onError: () => {
      notifyError("حدث خطأ اثناء تاكيد الطلب");
    },
  });

  if (ROLES == "Admin") {
    return <Navigate to="/dashboard" replace />;
  }
  const [downloadExcel, setDownloadExcel] = useState(true);
  function downloadCSV(data, filename) {
    const csv = convertToCSV(data);
    const link = document.createElement("a");
    // link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv);
    link.download = filename;

    // Simulate a click on the download link
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function convertToCSV(data) {
    data = data.map((obj) => {
      const newData = { ...obj };
      delete newData.user_id;
      delete newData.book_id;
      newData.reservation_image = getImageUrl(`/${obj.reservation_image}`);
      return newData;
    });

    const headers = Object.keys(data[0]);
    console.log("data", data);

    const headerRow = headers.join(",") + "\n";

    const escapeCellValue = (value) => {
      if (typeof value !== "string") {
        value = String(value);
      }

      if (value.includes('"')) {
        value = value.replace(/"/g, '""');
      }

      if (value.includes(",") || value.includes("\n")) {
        value = `"${value}"`;
      }
      value = value + String.fromCharCode(8203);
      return value;
    };

    const rows = data.map((obj) => {
      const rowValues = headers.map((key) => {
        let value = obj[key];

        if (typeof value === "string" && /^\d{10}$/.test(value)) {
          value = `'${value}`;
        }

        return escapeCellValue(value);
      });

      return rowValues.join(",") + "\n";
    });
    console.log(rows);
    return headerRow + rows.join("");
  }

  console.log("bookdata: ", bookData);
  const { mutate, isLoading: excelLoading } = useMutation({
    mutationFn: () =>
      axios.get(
        `${api_url}excel/excelBookOrder?book_id=${bookData[0].id}&isExcel=0`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser()?.token}`,
          },
        }
      ),
    onSuccess: (data) => {
      console.log(`excel`, data);
      if (data?.data instanceof Array) {
        downloadCSV(data?.data, `${bookData[0].title}.csv`);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <>
      <Box px={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "",
            padding: "20px",
            borderRadius: "10px",
            bgcolor: COLORS.DARK_GRAY,
          }}
        >
          <Box>
            <img
              style={{
                width: "400px",
                height: "300px",
                borderRadius: "10px",
                marginLeft: "15px",
              }}
              src={activeBook ? activeBook?.img : bookData[0]?.img}
              alt=""
            />
          </Box>

          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: 'center',
                marginBottom: "5px",
              }}
            >
              <Typography
                color={COLORS.WHITE}
                fontSize={35}
                fontFamily={"Noor-bold"}
                sx={{
                  transition: "0.4s",
                  "&:hover": { color: "primary.main" },
                }}
              >
                {activeBook ? activeBook?.title : bookData[0]?.title}
              </Typography>

              <Typography
                fontSize={14}
                fontFamily={"Noor-bold"}
                sx={{
                  padding: "5px 10px",
                  bgcolor: COLORS.PRIMARY.MAIN,
                  color: COLORS.WHITE,
                  fontSize: "25px",
                  borderRadius: "5px",
                }}
              >
                {activeBook ? activeBook?.price : bookData[0]?.price} جنية
              </Typography>
            </Box>
            <Typography
              fontSize={20}
              color={"white"}
              sx={{ opacity: ".8", marginBottom: "30px" }}
            >
              رقم الحجز :{" "}
              {activeBook ? activeBook?.reserveNum : bookData[0]?.reserveNum}
            </Typography>
            <Typography fontSize={30} color={COLORS.WHITE} sx={{}}>
              {activeBook ? activeBook?.teacherName : bookData[0]?.teacherName}
            </Typography>
          </Box>
        </Box>

        <Box
          my={5}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            fontFamily={"Noor-bold"}
            fontSize={32}
            sx={{ textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}` }}
          >
            طلبات الكتاب
          </Typography>

          <Paginator
            page={page}
            totalPages={data?.last_page}
            setPage={setPage}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            bgcolor: "#00000075",
            zIndex: "1600",
            display: "flex",
            justifyContent: "center",
            // alignItems: 'center',
            transition: "0.4s",
            overflowY: "auto",
            visibility: openImage ? "visible" : "hidden",
            opacity: openImage ? 1 : 0,
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              padding: "20px",
              width: "auto",
              display: "flex",
              height: "60%",
              margin: "30px 0px",
              borderRadius: "5px",
              transition: "0.4s",
              visibility: openImage ? "visible" : "hidden",
              opacity: openImage ? 1 : 0,
              transform: openImage ? "scale(1)" : "scale(0)",
            }}
          >
            <div
              className="close-btn"
              style={{
                position: "absolute",
                top: -10,
                right: -80,
                zIndex: 100,
              }}
            >
              <Close
                onClick={closeImageBox}
                sx={{
                  width: 35,
                  height: 35,
                  transition: "0.4s",
                  cursor: "pointer",
                  padding: "2px 5px",
                  bgcolor: "white",
                  color: "black",
                  borderRadius: "50%",
                  "&:hover": {
                    color: "#EB7124",
                  },
                }}
              />
            </div>

            {imageBox && (
              <img
                className=""
                width={"100%"}
                src={`${api_url.slice(0, -7)}${imageBox}`}
                alt="صورة الحجز"
                style={{
                  borderRadius: "5px",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            mx: 4,
            my: 1,
          }}
        >
          <ColorButton
            sx={{
              color: "white",
              width: "20%",
            }}
            onClick={() => {
              mutate();
            }}
          >
            {excelLoading ? <ClipLoader color="#000" size={20} /> : "تحميل"}
          </ColorButton>
        </Box>
        <TableContainer component={Paper}>
          <Table
            sx={{ direction: "rtl", color: "white", paddingBottom: "20px" }}
            aria-label="simple table"
          >
            <CustomTableHead
              headBackground={COLORS.PRIMARY.MAIN}
              tableHeaders={tableHeader}
            />
            <TableBody>
              {isPending ? (
                <>
                  <CustomTableLoading
                    width={"100%"}
                    height={60}
                    numOfCells={7}
                  />
                </>
              ) : (
                <>
                  {data?.data?.data?.length != 0 ? (
                    <>
                      {data?.data?.data?.map((item, i) => (
                        <TableRow
                          key={`a${i}sm`}
                          sx={{
                            backgroundColor: item.isConfirmed
                              ? "#ccffd7"
                              : COLORS.OFF_WHITE,
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            {item.name}
                          </TableCell>
                          <TableCell align="center">
                            <div
                              className="tableImage"
                              onClick={() => {
                                openIamgeBox();
                                showImage(item.reservation_image);
                              }}
                            >
                              <img
                                className=""
                                width={"80px"}
                                src={`${api_url.slice(0, -7)}${
                                  item.reservation_image
                                }`}
                                alt="صورة الحجز"
                                style={{
                                  borderRadius: "5px",
                                  height: "80px",
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="center">{item.phone}</TableCell>
                          <TableCell align="center">
                            {item.secondary_phone}
                          </TableCell>
                          <TableCell align="center">
                            {`${item.state},${item.station},${item.street},${item.building_number}`}
                          </TableCell>
                          <TableCell align="center">
                            {item.reservation_phone}
                          </TableCell>
                          <TableCell align="center">
                            {item.reservation_date}
                          </TableCell>
                          <TableCell align="center">{item.quantity}</TableCell>

                          <TableCell
                            colSpan={9}
                            style={{ textAlign: "center", padding: "10px" }}
                          >
                            <ColorButton
                              type="submit"
                              // onClick={()=>{
                              //   currentDashboardUser()?.role === "Teacher" && setValue("teacher_id", teacher_id)
                              // }}
                              variant="contained"
                              sx={{ bgcolor: COLORS.PRIMARY.MAIN }}
                              onClick={() => {
                                console.log(item.id, item.isConfirmed);
                                confirmOrder({
                                  id: item.id,
                                  confirm: !item.isConfirmed,
                                });
                              }}
                            >
                              {item.isConfirmed ? "الغاء" : "تأكيد"}
                            </ColorButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={9}
                        style={{ textAlign: "center", padding: "10px" }}
                      >
                        <Typography
                          fontFamily={"Noor-bold"}
                          fontSize={30}
                          color={"black"}
                          sx={{ textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}` }}
                        >
                          لا يوجد طلبات
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
