import { COLORS } from "@constants/Colors/colors";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { HashLoader } from "react-spinners";
import { ModuleVideo } from "./ModuleVideo/ModuleVideo";
import useCustomPostQuery from "@shared/Helpers/hooks/useCustomPostQuery";
import { ModuleFile } from "./ModuleFile/ModuleFile";
import { ModuleExam } from "./ModuleExam/ModuleExam";
import { ModuleHomework } from "./ModuleHomework/ModuleHomework";
import AddIcon from "@mui/icons-material/Add";
import { Add } from "./Add/Add";
import DeleteDialog from "../DeleteDialog";
import EditModule from "../EditModule";
import ProgressUpload from "@/Admin/Components/ProgressUpload/ProgressUpload";
import { useRecoilState } from "recoil";
import { uploadVideoProgress } from "./Atom/Progress.atom";
import { TitleUploadVideo } from "./Atom/TitleUploadVideo.atom";
import { currentUser } from "@shared/Helpers/encryptionHelpers";

export default function ModuleDetailsCollapse({ module, index, course }) {
  const [uploadVideoProg, setUploadVideoProg] =
    useRecoilState(uploadVideoProgress);
  const [TitleUploadVid, setTitleUploadVid] = useRecoilState(TitleUploadVideo);
  const [collapse, setCollapse] = useState(true);
  const { data, isPending } = useCustomQuery({
    enabled: !collapse,
    url: `module/adminShow?id=${module?.id}`,
    key: ["module", module],
    retry: false,
  });

  const {
    data: files,
    isPending: isFilesPending,
    isError: isFilesError,
  } = useCustomPostQuery({
    url: `files`,
    key: ["module files", module?.id],
    data: { type: "Module", id: module?.id },
    retry: false,
    enabled: !isNaN(+module?.id) && !collapse,
  });
  // console.log(`files`, files?.data?.data);

  const {
    data: exams,
    isPending: isExamsPending,
    isError: isExamsError,
  } = useCustomQuery({
    url: `exam/getLessonExamsAdmin?lesson_module_id=${module?.id}`,
    key: ["module exam", module?.id],
    enabled: !collapse,
    retry: false,
  });
  const {
    data: homework,
    isPending: isHomeworkPending,
    isError: isHomeworkError,
  } = useCustomQuery({
    url: `homeWork/getHomeWorkAdmin?lesson_module_id=${module?.id}`,
    key: ["module homework", module?.id],
    enabled: !collapse,
    retry: false,
  });

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteName, setDeleteName] = useState();
  const [confirmMessage, setConfirmMessage] = useState("");
  const deleteModuleHandler = (module) => {
    setIsConfirmOpen(true);
    setConfirmMessage("هل انت متأكد من حذف الحصه");
    setDeleteData(module);

    // setDeleteName(module?.id);
  };
  const [moduleIsOpen, setModuleIsOpen] = useState(false);

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {uploadVideoProg && TitleUploadVid !== null && (
        <ProgressUpload percentage={uploadVideoProg} title={TitleUploadVid} />
      )}
      <Box
        onClick={() => setCollapse(!collapse)}
        sx={{
          width: "100%",
          height: "auto",
          bgcolor: COLORS.BOX,
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            width={50}
            height={"100%"}
            bgcolor={COLORS.DARK_GRAY}
            color={COLORS.WHITE}
            fontSize={20}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {index < 9 ? "0" + (index + 1) : index + 1}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              paddingY: 2,
              marginRight: 2,
            }}
          >
            <Typography fontSize={20} fontFamily={"Noor-bold"}>
              {module?.name}
            </Typography>
            <Typography fontSize={18}>{module?.overview}</Typography>
          </Box>
        </Box>
        <Box
          sx={{ height: "100%", display: "flex", alignItems: "center", gap: 2 }}
        >
          <Typography fontFamily={"Noor-bold"} fontSize={20} marginLeft={2}>
            {+module.price > 0 ? module.price + " جنيه" : "مجاني"}
          </Typography>
          <Button
            sx={{
              bgcolor: COLORS.PRIMARY.MAIN,
              color: COLORS.WHITE,
              "&:hover": { color: COLORS.PRIMARY.MAIN },
            }}
          >
            {collapse ? "إظهار" : "إخفاء"}
          </Button>
          {/* <IconButton
            sx={{ bgcolor: "#01c46e", "&:hover": { bgcolor: "#01c46e" } }}
            // onClick={() => setModuleIsOpen(true)}
          >
            <EditIcon fontSize="small" />
          </IconButton> */}

          {currentUser()?.role !== "Supervisor" && (
            <>
              <EditModule course={course} module={module} />
              <IconButton
                sx={{
                  bgcolor: COLORS.DANGER,
                  "&:hover": { bgcolor: COLORS.DANGER },
                }}
                onClick={() => {
                  deleteModuleHandler(module);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </>
          )}

          <DeleteDialog
            isConfirmOpen={isConfirmOpen}
            setIsConfirmOpen={setIsConfirmOpen}
            deleteName={deleteName}
            deleteData={deleteData}
            confirmMessage={confirmMessage}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          bgcolor: COLORS.DARK_GRAY,
          height: collapse ? 0 : "auto",
          minHeight: collapse ? 0 : 50,
          overflow: "hidden",
          msTransitionTimingFunction: "linear",
        }}
      >
        {isPending && isFilesPending && isExamsPending && isHomeworkPending ? (
          <Box
            sx={{
              width: "100%",
              height: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HashLoader size={20} color={COLORS.PRIMARY.MAIN} />
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingY: 2,
            }}
          >
            <Add module={module} homework={homework} exams={exams} />
            {data?.data?.lessons?.map((item, index) => (
              <ModuleVideo video={item} key={index} />
            ))}
            {!isFilesError &&
              !isFilesPending &&
              files?.data?.data?.map((item, index) => (
                <ModuleFile id={module?.id} file={item} key={index} />
              ))}
            {!isExamsError && !isExamsPending && (
              <ModuleExam exam={exams?.data} />
            )}
            {!isHomeworkError && !isHomeworkPending && (
              <ModuleHomework homework={homework?.data} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
