import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useUpdateQuestion({
  closeModal,
  id,
  level_id,
  refetch,
}) {
  const instance = useAxiosConfig();

  function updateQuestion(data) {
    return instance.post(`question/${id}`, data);
  }
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => updateQuestion(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["question"]);
      notifySuccess("تم تعديل المستوى بنجاح");
      refetch();
    },
    onError: (err) => {
      notifyError(err?.response?.data?.message);
    },
    onSettled: () => {
      closeModal();
    },
  });
}
