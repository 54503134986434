// import useGetSystemNumbers from "../../shared/axios/Home/hooks/useGetSystemNumbers";
import { FormControl, Select, MenuItem, Box } from "@mui/material";
import { useState } from "react";
import AreaChartComp from "./atoms/AreaChartComp";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { COLORS } from "@constants/Colors/colors";

const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export default function Chart() {
  const { data: systemData, isLoading: systemLoading } = useCustomQuery({
    url:"system/numbers",
    key:"systemNumbers",
    select: (data) => data?.data?.data
  });
  const [chartCreatedData, setChartCreatedData] = useState();
  const [chartActivatedData, setChartActivatedData] = useState();
  const [chartCoursesData, setChartCoursesData] = useState();
  const [selectedLabel, setSelectedLabel] = useState("الأكواد المنشأة");
  const [selectedColor, setSelectedColor] = useState("#2f8bc8");

  if (systemData && !chartCreatedData) {
    const newCreatedData = [];
    const newActivatedData = [];

    for (let i = 0; i < systemData?.created_stats?.length; i++) {
      newCreatedData.push({
        name: monthMap[systemData?.created_stats?.[i]?.month],
        "الأكواد المنشأة": systemData?.created_stats?.[i]?.count || 0,
      });
      newActivatedData.push({
        name: monthMap[systemData?.created_stats?.[i]?.month],
        "الأكواد المفعلة": systemData?.activated_stats?.[i]?.count || 0,
      });
    }

    const newCoursesData = [];
    systemData?.created_courses?.forEach((element) => {
      newCoursesData.push({
        name: monthMap[element?.month],
        "الكورسات المنشأة": element?.count || 0,
      });
    });

    setChartCreatedData(newCreatedData);
    setChartActivatedData(newActivatedData);
    setChartCoursesData(newCoursesData);
  }
  return (
    <Box sx={{fontFamily:"Noor-regular"}}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={selectedLabel}
          onChange={(e) => {
            setSelectedLabel(e.target.value);
            switch (e.target.value) {
              case "الأكواد المنشأة":
                setSelectedColor(COLORS.PRIMARY.MAIN);
                break;
              case "الأكواد المفعلة":
                setSelectedColor(COLORS.SECONDARY.MAIN);
                break;
              case "الكورسات المنشأة":
                setSelectedColor(COLORS.TERTIARY.MAIN);
                break;
            }
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ color: selectedColor, outline: "none",fontFamily:"Noor-regular" }}
        >
          <MenuItem
            color={COLORS.PRIMARY.MAIN}
            value={"الأكواد المنشأة"}
            sx={{ color: COLORS.PRIMARY.MAIN }}
          >
            الأكواد المنشأة
          </MenuItem>
          <MenuItem
            color={COLORS.SECONDARY.MAIN}
            value={"الأكواد المفعلة"}
            sx={{ color: COLORS.SECONDARY.MAIN }}
          >
            الأكواد المفعلة
          </MenuItem>
          <MenuItem
            color={COLORS.TERTIARY.MAIN}
            value={"الكورسات المنشأة"}
            sx={{ color: COLORS.TERTIARY.MAIN }}
          >
            الكورسات المنشأة
          </MenuItem>
        </Select>
      </FormControl>
      {systemLoading && <AreaChartComp data={[]} label={""} color={COLORS.PRIMARY.MAIN} />}
      {chartCreatedData &&
        (() => {
          switch (selectedLabel) {
            case "الأكواد المنشأة":
              return (
                <AreaChartComp
                  data={chartCreatedData}
                  label={selectedLabel}
                  color={COLORS.PRIMARY.MAIN}
                />
              );
            case "الأكواد المفعلة":
              return (
                <AreaChartComp
                  data={chartActivatedData}
                  label={selectedLabel}
                  color={COLORS.SECONDARY.MAIN}
                />
              );
            case "الكورسات المنشأة":
              return (
                <AreaChartComp
                  data={chartCoursesData}
                  label={selectedLabel}
                  color={COLORS.TERTIARY.MAIN}
                />
              );
          }
        })()}
    </Box>
  );
}
