import CourseCard from "../atoms/CourseCard";
import Header from "../atoms/Header";
import { Box, Typography } from "@mui/material";
// import { useQuery } from "@tanstack/react-query";
import { api_url } from "@constants/base_url";
import axios from "axios";
// import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { ClipLoader } from "react-spinners";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { COLORS } from "@constants/Colors/colors";

export default function TopCourses() {
  const { data, isLoading } = useCustomQuery({
    url: `${api_url}course/all?page=1`,
    key: "topCourses",
    select: (data) => data
  })

  const empty = (
    <Typography
      fontFamily="Noor-bold"
      fontSize={30}
      color="black"
      sx={{ textShadow: `0px 3px 0px ${COLORS.PRIMARY.MAIN}`, textAlign: "center" }}
    >
      لا يوجد كورسات
    </Typography>
  );

  const spinner = (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* <LoadingSpinner /> */}
      <ClipLoader color={COLORS.PRIMARY.MAIN} size={40}/>
    </div>
    
  );

  const content = (
    <Box display={"flex"} justifyContent={"center"} gap={4} px={4}>
      {data?.data?.data
        ?.slice(0, 4)
        .map(({ id, name, price, teacher, isPayByModule, image }, i) => (
          <CourseCard
            width={"350px"}
            key={id}
            img={`${api_url.slice(0, -7)}${image.slice(1)}`}
            name={name}
            price={price}
            type={isPayByModule ? "بالحصة" : "بالكورس"}
            teacher={teacher}
            link={`/login`}
            id={id}
          />
        ))}
    </Box>
  );

  return (
    <>
      <Box className={"landing"} backgroundColor={"white"} py={10} px={6}>
        <Header text={"كورسات متميزة"} sx={{ mb: "100px", width: "500px" }} />
        {isLoading ? spinner : data?.data?.data?.length === 0 ? empty : content}
      </Box>
    </>
  );
}
