import { FormControlLabel, Checkbox } from "@mui/material";
import { FONT_SIZES } from "../../../Constants/Fonts/font_sizes";
import { FONTS } from "../../../Constants/Fonts/fonts";

export default function ColorCheckBox({ label }) {
  return (
    <FormControlLabel
      label={label}
      control={<Checkbox />}
      sx={{
        mr: -1,
        "& .MuiFormControlLabel-label": {
          fontSize: FONT_SIZES.SMALL,
          fontFamily: FONTS.BOLD,
        },
        "& .MuiSvgIcon-root": { fontSize: FONT_SIZES.REGULAR },
      }}
    />
  );
}
