import { COLORS } from "@constants/Colors/colors";
import {
  Box,
  Button,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { RecordPicker } from "./components/RecordPicker";
import { notifySuccess } from "@shared/Toast/toast";
import Modal from "@shared/Modal/Modal";
import useCustomMutation from "@shared/Helpers/hooks/useCustomMutation";
import { QuestionImagePicker } from "./components/QuestionImagePicker";

export default function AddLevelQuestion({ modal, closeModal, refetch }) {
  const id = useParams()?.id;
  const [submitted, setSubmitted] = useState(false);
  const [exam, setExam] = useState({
    title: "",
    isPrerequsite: 1,
    grade: "1",
    image: "",
    answers: [
      {
        title: "",
        isCorrect: 1,
      },
      {
        title: "",
        isCorrect: 0,
      },
    ],
  });

  const { mutate, isPending, isSuccess } = useCustomMutation({
    url: `level/${id}/question`,
    method: "post",
    onSuccess: (data) => {
      notifySuccess("تم إضافة سؤال بنجاح");
      setExam({
        title: "",
        isPrerequsite: 1,
        grade: "1",
        image: "",
        answers: [
          {
            title: "",
            isCorrect: 1,
          },
          {
            title: "",
            isCorrect: 0,
          },
        ],
      });
      refetch();
      closeModal();
      queryClient.invalidateQueries(["question"]);
    },
  });

  const addAnswer = () => {
    setExam((prevExam) => ({
      ...prevExam,
      answers: [...prevExam.answers, { title: "", isCorrect: 0 }],
    }));
  };

  const removeAnswer = (index) => {
    let temp = exam;
    if (temp.answers[temp.answers.length - 1].isCorrect == 1) {
      temp.answers[temp.answers.length - 2].isCorrect = 1;
    }
    temp.answers = temp.answers.slice(0, -1);
    setExam({ ...exam, answers: temp });
  };
  const handleRadioChange = (questionIndex, answerIndex) => {
    const updatedExam = { ...exam };
    exam.answers.forEach((answer, index) => {
      answer.isCorrect = index === answerIndex ? 1 : 0;
    });
    setExam(updatedExam);
  };

  const submit = async () => {
    let examData = exam;
    console.log(examData, "examData");
    // if (!examData?.title) return;
    for (let i = 0; i < examData.length; i++) {
      if (!examData.title && !examData.image) return;
      if (examData.image) {
        examData.image = examData.image[0];
      }
      if (!examData.grade) return;
      for (let j = 0; j < examData.answers.length; j++) {
        if (!examData.answers[j].title) return;
      }
    }

    const reqFormData = new FormData();
    reqFormData.append("title", examData?.title);
    if (examData?.image) {
      reqFormData.append(`image`, examData.image);
    }
    if (examData.record) {
      reqFormData.append(`record`, examData.record[0]);
    }
    for (let i = 0; i < examData.length; i++) {
      if (examData?.title) {
        reqFormData.append("title", examData.title);
      }
      reqFormData.append(`grade`, 1);
      if (examData?.image) {
        reqFormData.append(`image`, examData.image);
      }
      if (examData?.record) {
        reqFormData.append(`record`, examData.record[0]);
      }
    }
    for (let j = 0; j < examData.answers.length; j++) {
      reqFormData.append(`answers[${j}][title]`, examData.answers[j].title);
      reqFormData.append(
        `answers[${j}][isCorrect]`,
        +exam.answers[j].isCorrect
      );
    }
    mutate(reqFormData);
  };
  return (
    <Modal maxWidth={"sm"} open={modal} closeFn={closeModal}>
      <Box sx={{ width: "100%", margin: "auto", paddingTop: "1rem" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{
            marginTop: 5,
            width: "60%",
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <Box
            fullWidth
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Typography
              sx={{
                bgcolor: COLORS.PRIMARY.MAIN,
                width: 30,
                height: 30,
                color: COLORS.WHITE,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {0 + 1}
            </Typography>
            <TextField
              label="عنوان السؤال"
              fullWidth
              type="text"
              value={exam.title}
              onChange={(e) => {
                setExam((prevExam) => ({
                  ...prevExam,
                  title: e.target.value,
                }));
              }}
              error={!exam.title && !exam.image && submitted}
              helperText={
                !exam.title &&
                !exam.image &&
                submitted &&
                "يجب إضافة عنوان للسؤال في حالة عدم إضافة صورة"
              }
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#2f8bc8",
                },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: "#2f8bc8",
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
              }}
            />
            {0 == 0 && (
              <Box sx={{ display: "flex", gap: "5px" }}>
                <QuestionImagePicker exam={exam} setExam={setExam} index={0} />
                <RecordPicker exam={exam} setExam={setExam} index={0} />
              </Box>
            )}
            <RadioGroup
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              value={exam.answers.findIndex((x) => x.isCorrect === 1)}
            >
              {exam.answers.map((answer, i) => (
                <Box
                  key={i}
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >
                  <TextField
                    label={`إجابة ${i + 1}`}
                    type="text"
                    value={answer.title}
                    onChange={(e) => {
                      let temp = { ...exam };
                      temp.answers[i].title = e.target.value;
                      setExam(temp);
                    }}
                    error={!answer.title && submitted}
                    helperText={
                      !answer.title && submitted && "يجب إضافة نص اللإجابة"
                    }
                    sx={{
                      "& .MuiFormLabel-root.Mui-focused": { color: "#2f8bc8" },
                      "& label": {
                        left: "unset",
                        right: "1.75rem",
                        transformOrigin: "right",
                        fontSize: "0.8rem",
                        color: "#2f8bc8",
                      },
                      "& legend": { textAlign: "right", fontSize: "0.6rem" },
                      direction: "rtl",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end"></InputAdornment>
                      ),
                    }}
                  />
                  <Radio
                    value={answer.isCorrect.toString()}
                    checked={answer.isCorrect === 1}
                    onChange={() => handleRadioChange(0, i)}
                  />
                </Box>
              ))}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {exam.answers.length < 4 && (
                  <Button
                    sx={{
                      bgcolor: COLORS.PRIMARY.MAIN,
                      color: COLORS.WHITE,
                      "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
                    }}
                    onClick={addAnswer}
                  >
                    أضف إجابة
                  </Button>
                )}
                {exam.answers.length > 2 && (
                  <Button
                    sx={{
                      bgcolor: COLORS.PRIMARY.MAIN,
                      color: COLORS.WHITE,
                      "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
                    }}
                    onClick={removeAnswer}
                  >
                    إحذف إجابة
                  </Button>
                )}
              </Box>
            </RadioGroup>
          </Box>

          <Button
            disabled={isPending}
            sx={{
              bgcolor: COLORS.PRIMARY.MAIN,
              color: COLORS.WHITE,
              "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
            }}
            onClick={() => {
              setSubmitted(true);
              submit();
              console.log(exam);
            }}
          >
            {isPending ? (
              <HashLoader size={20} color={COLORS.WHITE} />
            ) : (
              "إنشاء"
            )}
          </Button>
        </form>
      </Box>
    </Modal>
  );
}
