import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useAddCourseFile() {
  const instance = useAxiosConfig();

  function addCourseFile(data) {
    return instance.post("files/addFile", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addCourseFile,
    onSuccess: (res) => {
      console.log('resFile',res);
      queryClient.invalidateQueries(["course files"]);
      queryClient.invalidateQueries(["module files"]);
      notifySuccess("تم اضافة ملف بنجاح");
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message);
      console.log(error);
    },
    onSettled: () => {
    },
  });
}
