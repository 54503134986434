import { COLORS } from '@constants/Colors/colors'
import { Button, InputAdornment, TextField } from '@mui/material'
import useCustomMutation from '@shared/Helpers/hooks/useCustomMutation'
import Modal from '@shared/Modal/Modal'
import { notifySuccess } from '@shared/Toast/toast'
import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { HashLoader } from 'react-spinners'

export const AddBar = ({open, setOpen}) => {
    const [message, setMessage] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const queryClient = useQueryClient()
    const {mutate, isPending} = useCustomMutation({
        url:`bar/createMessage`,
        onSuccess:()=>{
            setOpen(false)
            notifySuccess("تم إضافة البانر بنجاح")
            queryClient.invalidateQueries(["bar"])
        },

    })
    return (
        <Modal open={open} closeFn={()=>setOpen(false)}>
            <TextField
                    label = "العنوان"
                    type='text'
                    value={message}
                    onChange={(e) => {
                        setMessage(e.target.value)
                    }}
                    error={!message && submitted}
                    helperText={!message && submitted && "يجب إضافة عنوان للبانر"}
                    sx={{
                        marginTop:2,
                        width:"70%",
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#2f8bc8",
                        },
                        "& label": {
                          left: "unset",
                          right: "1.75rem",
                          transformOrigin: "right",
                          fontSize: "0.8rem",
                          color: "#2f8bc8",
                        },
                        "& legend": {
                          textAlign: "right",
                          fontSize: "0.6rem",
                        },
                        direction: "rtl",
                      }}
                      InputProps={{
                        startAdornment: (
                            <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                />
                <Button onClick={()=>{
                    setSubmitted(true)
                    if(!message) return
                    mutate({
                        body:message
                    })
                }} sx={{bgcolor:COLORS.PRIMARY.MAIN, color:COLORS.WHITE, marginTop:2,"&:hover":{bgcolor:COLORS.PRIMARY.MAIN}}}>{isPending?<HashLoader color={COLORS.WHITE} size={20}/>:"أضف"}</Button>
        </Modal>
    )
}
