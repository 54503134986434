import learner from "@assets/images/Online learning-bro.png";
import { COLORS } from "@constants/Colors/colors";

export default function Landing() {
  return (
    <>
      <div
        className={"landing"}
        style={{
          height: 700,
          backgroundColor: COLORS.PRIMARY.MAIN,
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: 30,
          justifyContent: "center",
        }}
      >
        <img src={learner} />
        <div className="landing-text">
          <p>من الصفر</p>
          <p>
            حنقفل لك <span style={{ color: "white" }}>على الإنجليزي </span>
          </p>
        </div>
      </div>
    </>
  );
}
