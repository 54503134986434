import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useDeleteQuestion({
  closeModal,
  id,
  level_id,
  refetch,
}) {
  const instance = useAxiosConfig();

  function deleteQuestion(id) {
    return instance.delete(`/question/${id}`, {});
  }
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => deleteQuestion(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["question"]);
      notifySuccess("تم حذف السؤال");
      refetch();
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message);
    },
    onSettled: closeModal,
  });
}
