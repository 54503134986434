import { Button, Typography } from '@mui/material'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { COLORS } from '@constants/Colors/colors';
import Modal from '@shared/Modal/Modal';
import { ExamCreator } from '@/Admin/Pages/Course/ExamCreator/ExamCreator';
import { useState } from 'react';

export const AddExam = ({id, closeParent}) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Button onClick={() => setOpen(true)} sx={{bgcolor:"#892489", display:"block",color:COLORS.WHITE, width:150,height:150,"&:hover":{bgcolor:"#892489", opacity:"0.9"}}}>
                <AccessAlarmIcon fontSize='large'/>
                <Typography>امتحان</Typography>
            </Button>
            <ExamCreator id={id} open={open} setOpen={setOpen} closeParent={closeParent} type={"Lesson"}/>
        </>
    )
}
