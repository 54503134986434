import { z } from "zod";

export const loginSchema = z.object({
  phone: z.string().nonempty("رقم الهاتف مطلوب"),
  // .regex(/^01[0-2,5]{1}[0-9]{8}$/, "رقم الهاتف غير صحيح"),
  password: z
    .string()
    .min(8, "كلمة المرور يجب ان يحتوي على 8 احرف")
    .nonempty("كلمة المرور مطلوبة"),
});
