import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosConfig from "../axios/useAxiosConfig";
import { notifyError, notifySuccess } from "../../Toast/toast";

export default function useCustomMutation({
  url,
  method = "post",
  successMsg,
  invalidate,
  onSettled,
  onSuccess = () => {
    return;
  },
  ...options
}) {
  const queryClient = useQueryClient();
  const axios = useAxiosConfig();

  return useMutation({
    mutationFn: (data) => axios[method](url, method === "post" && data),
    ...options,
    onSuccess: (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
      notifySuccess(successMsg);
      invalidate.forEach((element) => {
        queryClient.invalidateQueries({ queryKey: [element] });
      });
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message || "An error occurred.");
    },
    onSettled,
  });
}
