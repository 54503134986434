import { COLORS } from "@constants/Colors/colors";
import { Box, Typography } from "@mui/material";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import React from "react";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import ModuleDetailsCollapse from "./ModuleDetailsCollapse/ModuleDetailsCollapse";
import { AddModule } from "./AddModule/AddModule";

export const CourseModules = ({ course }) => {
  const { id } = useParams();
  const { data, isPending } = useCustomQuery({
    url: `course/${+id}`,
    key: ["course modules", id],
    enabled: !isNaN(+id),
  });
  if (isPending) {
    return (
      <Box
        sx={{
          width: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HashLoader color={COLORS.PRIMARY.MAIN} />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "50%", minHeight: 400, paddingY: 2 }}>
      <AddModule price={data?.price} />
      {data?.modules?.length === 0 ? (
        <Typography
          sx={{
            height: 300,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
          fontFamily={"Noor-bold"}
          textAlign={"center"}
          fontSize={30}
          color={COLORS.DARK_GRAY}
        >
          لم يتم إضافة حصص بعد
        </Typography>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {data?.modules.map((module, i) => (
            <ModuleDetailsCollapse
              course={course}
              key={module?.id}
              module={module}
              index={i}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
