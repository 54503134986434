import { useMutation } from "@tanstack/react-query";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import { USER_TOKEN } from "@constants/tokens";

export function useLogout() {
  const axios = useAxiosConfig();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: () => axios.post(`logout`, {}),
    onSuccess: () => {
      navigate("/");
      window.localStorage.removeItem(USER_TOKEN);
      notifySuccess("تم تسجيل الخروج من الحساب");
    },
    onError: (error) => {
      notifyError(error.response.data.message);
    },
  });

  return {
    logout: mutate,
    isLoading: isPending,
  };
}
