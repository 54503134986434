import {
  Box,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ROLES } from "@constants/roles";
import { Devices, RemoveRedEye } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useLocalStorageToken from "@shared/Helpers/axios/useLocalStorageToken";
import DeleteStudent from "@/Admin/Pages/Students/DeleteStudent";
import UpdateStudent from "@/Admin/Pages/Students/UpdateStudent";
import BanStudent from "@/Admin/Pages/Students/BanStudent";
import RemoveBan from "@/Admin/Pages/Students/RemoveBan";
import StudentSubscribtions from "@/Admin/Pages/Students/StudentSubscribtions";
import DevicesBox from "@/Admin/Pages/Students/DevicesBox";
import WalletIcon from "@mui/icons-material/Wallet";
import StudentWallet from "@/Admin/Pages/Students/StudentWallet";
import { features } from "@config/features";
import ToggleCell from "./ToggleCell";

export default function CustomTableRow({
  cell,
  index,
  page,
  name,
  phone,
  placementGrade,
  // city,
  email,
  banned_until,
  id,
  refetch,
}) {
  const User = useLocalStorageToken();

  const [noteBook, setNoteBook] = useState(false);
  const [studentId, setStudentId] = useState();

  const openNote = (note) => {
    setNoteBook(note);
  };

  const closeNote = () => {
    setNoteBook("");
  };

  const getStudentId = (id) => {
    setStudentId(id);
    // console.log(id);
  };
  return (
    <>
      <Box>
        <DeleteStudent
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
        />
        <UpdateStudent
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
          name={name}
          // city={city}
        />
        <BanStudent
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
        />
        <RemoveBan
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
          refetch={refetch}
        />
        <StudentSubscribtions
          id={studentId}
          closeNote={closeNote}
          noteBook={noteBook}
        />
        {features.payment && (
          <StudentWallet
            id={studentId}
            closeNote={closeNote}
            noteBook={noteBook}
          />
        )}
        <DevicesBox id={studentId} closeNote={closeNote} noteBook={noteBook} />
      </Box>
      <TableBody>
        <TableRow>
          <TableCell align="center">{index + 1}</TableCell>
          <TableCell align="center" component="th" scope="row">
            {name}
          </TableCell>
          <TableCell align="center" dir="ltr">
            {phone}
          </TableCell>
          {/* <TableCell align="center">{city}</TableCell> */}
          <TableCell align="center">{email}</TableCell>
          <TableCell align="center">
            {placementGrade == null && "لم يجتاز الاختبار بعد"}
            {placementGrade == -1 ? "تم التعيين من قبل المشرف" : placementGrade}
            {placementGrade == -1 && (
              <ToggleCell
                placementGrade={placementGrade}
                id={id}
                refetch={refetch}
              />
            )}{" "}
            {placementGrade == null && (
              <ToggleCell
                placementGrade={placementGrade}
                id={id}
                refetch={refetch}
              />
            )}{" "}
          </TableCell>

          <TableCell align="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(ROLES.SUPERVISOR === User?.role ||
                ROLES.ADMIN === User?.role) && (
                <>
                  <IconButton
                    onClick={() => {
                      openNote("del-student");
                      getStudentId(id);
                    }}
                    className="settingIcon"
                    aria-label="delete"
                    size="small"
                    sx={{
                      bgcolor: "#db3a3c",
                      position: "relative",
                      "&:hover": {
                        bgcolor: "#db3a3c",
                      },
                    }}
                  >
                    <span className="settingHover">حذف</span>
                    <DeleteIcon fontSize="inherit" sx={{ color: "#fff" }} />
                  </IconButton>

                  <IconButton
                    className="settingIcon"
                    onClick={() => {
                      openNote("update-student");
                      getStudentId(id);
                    }}
                    aria-label="delete"
                    size="small"
                    sx={{
                      bgcolor: "#3adb44",
                      marginRight: "10px",
                      "&:hover": {
                        bgcolor: "#3adb44",
                      },
                    }}
                  >
                    <span className="settingHover">تعديل</span>
                    <EditIcon fontSize="inherit" sx={{ color: "#fff" }} />
                  </IconButton>

                  {banned_until != null ? (
                    <IconButton
                      className="settingIcon"
                      onClick={() => {
                        openNote("remove-ban"), getStudentId(id);
                      }}
                      aria-label="delete"
                      size="small"
                      sx={{
                        bgcolor: "red",
                        marginRight: "10px",
                        "&:hover": {
                          bgcolor: "red",
                        },
                      }}
                    >
                      <span className="settingHover">فك الحظر</span>
                      <BlockIcon fontSize="inherit" sx={{ color: "#fff" }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="settingIcon"
                      onClick={() => {
                        openNote("ban"), getStudentId(id);
                      }}
                      aria-label="delete"
                      size="small"
                      sx={{
                        bgcolor: "#008de2",
                        marginRight: "10px",
                        "&:hover": {
                          bgcolor: "#008de2",
                        },
                      }}
                    >
                      <span className="settingHover">حظر</span>
                      <RemoveCircleOutlineIcon
                        fontSize="inherit"
                        sx={{ color: "#fff" }}
                      />
                    </IconButton>
                  )}
                </>
              )}
              <IconButton
                className="settingIcon"
                onClick={() => {
                  openNote("courses"), getStudentId(id);
                }}
                aria-label="delete"
                size="small"
                sx={{
                  bgcolor: "black",
                  marginRight: "10px",
                  "&:hover": {
                    bgcolor: "black",
                  },
                }}
              >
                <span className="settingHover">الكورسات</span>
                <RemoveRedEye fontSize="inherit" sx={{ color: "#fff" }} />
              </IconButton>

              <IconButton
                className="settingIcon"
                onClick={() => {
                  openNote("devices"), getStudentId(id);
                }}
                aria-label="delete"
                size="small"
                sx={{
                  bgcolor: "#22A770",
                  marginRight: "10px",
                  "&:hover": {
                    bgcolor: "#22A770",
                  },
                }}
              >
                <span className="settingHover">الأجهزة</span>
                <Devices fontSize="inherit" sx={{ color: "#fff" }} />
              </IconButton>
              {features.payment && ROLES.SUPERVISOR !== User?.role && (
                <IconButton
                  className="settingIcon"
                  onClick={() => {
                    openNote("wallet"), getStudentId(id);
                  }}
                  aria-label="wallet"
                  size="small"
                  sx={{
                    bgcolor: "green",
                    marginRight: "10px",
                    "&:hover": {
                      bgcolor: "#22A770",
                    },
                  }}
                >
                  <span className="settingHover">المحفظه</span>
                  <WalletIcon fontSize="inherit" sx={{ color: "#fff" }} />
                </IconButton>
              )}
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}
