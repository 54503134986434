import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
  } from "@mui/material";
  import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
    import CloseIcon from "@mui/icons-material/Close";
import useCustomMutation from "@shared/Helpers/hooks/useCustomMutation";
import { notifySuccess } from "@shared/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";

export const DeleteExamModal = ({id, setIsConfirmOpen, isConfirmOpen, confirmMessage, deleteName}) => {
    const queryClient = useQueryClient();
    const {mutate, isPending} = useCustomMutation({
        url: `exam/deleteExam?exam_id=${id}`,
        method: "delete",
        onSuccess:()=>{
            notifySuccess("تم حذف الإمتحان بنجاح")
            queryClient.invalidateQueries([`exam of course`])
        }
    })

    return (
        <Dialog
          maxWidth="sm"
          fullWidth
          onClose={() => setIsConfirmOpen(false)}
          open={isConfirmOpen}
        >
          <DialogTitle sx={{ borderTop: "#2f8bc8 5px solid" }}>
            <Button onClick={() => setIsConfirmOpen(false)}>
              <CloseIcon />
            </Button>
          </DialogTitle>{" "}
          <DialogContent
            sx={{
              borderBottom: "#2f8bc8 5px solid",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography my={4} fontSize={"24px"}>
              {confirmMessage || ""} {deleteName || ""}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <ColorButton
                sx={{
                  bgcolor: "#db3a3c",
                  transition: "all 0.3s",
                }}
                variant="contained"
                onClick={() => {
                  setIsConfirmOpen(false);
                  mutate()
                }}
              >
                تأكيد
              </ColorButton>
              <ColorButton
                variant="contained"
                onClick={() => setIsConfirmOpen(false)}
              >
                {" "}
                رجوع
              </ColorButton>
            </Box>
          </DialogContent>
        </Dialog>
      );
}
