import { COLORS } from "@constants/Colors/colors";
import { FONT_SIZES } from "@constants/Fonts/font_sizes";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import Column from "@shared/Layout/Column/Column";
import CustomNoData from "@shared/Layout/NoDataTableRow/CustomNoData";
import Row from "@shared/Layout/Row/Row";
import Paginator from "@shared/Pagination/Paginator";
import React, { useState } from "react";
import { LevelBox } from "./components/LevelBox";

export default function PlacementTestPage() {
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const { data, error, isLoading, isError, refetch } = useCustomQuery({
    url: `level?page=${page}`,
    key: "allLevel",
    page: page,
  });

  console.log(data, "level");
  return (
    <Box backgroundColor={COLORS.OFF_WHITE} sx={{ height: "77vh" }}>
      <Grid item={true} xs={12} sm={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "20px",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <Box>
            <Stack spacing={2} direction="row">
              <Typography
                fontFamily={"Noor-bold"}
                fontSize={FONT_SIZES.LARGE}
                sx={{ color: COLORS.DARK_GRAY }}
              >
                المستوى
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Row>
          <Column
            gap={1}
            spacing={"space-between"}
            align={"flex-start"}
            width={"100%"}
            marginBottom={"20px"}
          >
            <Paginator
              page={page}
              totalPages={data?.last_page}
              setPage={setPage}
            />
          </Column>
        </Row>
        <Row>
          <Column
            gap={1}
            spacing={"space-between"}
            align={"flex-start"}
            width={"100%"}
          >
            {isLoading ? (
              <>
                <Skeleton
                  width={"100%"}
                  sx={{ marginTop: "-20px" }}
                  height={"200px"}
                />
                <Skeleton
                  width={"100%"}
                  sx={{ marginTop: "-60px" }}
                  height={"200px"}
                />
              </>
            ) : (
              <>
                {data?.data.length > 0 ? (
                  <>
                    {data?.data.map((level, i) => (
                      <Grid key={level?.id} item xs={3}>
                        <LevelBox
                          level={level}
                          path={`/dashboard/levels/${level.id}`}
                          edit={true}
                        />
                      </Grid>
                    ))}
                  </>
                ) : (
                  <CustomNoData rowText={"لا يوجد مستويات"} numOfCells={7} />
                )}
              </>
            )}
          </Column>
        </Row>
      </Grid>
    </Box>
  );
}
