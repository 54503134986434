import React, { useEffect } from "react";
import { levelAtom } from "./levelAtom";
import { useRecoilState } from "recoil";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
  Radio,
  TextField,
  RadioGroup,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { FONT_SIZES } from "@constants/Fonts/font_sizes";
import { COLORS } from "@constants/Colors/colors";
import { Item } from "@shared/Item/Item";
import useUpdateQuestion from "./hooks/useUpdateQuestion";
import { ClipLoader, HashLoader } from "react-spinners";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { SharedTextField } from "@shared/SharedTextField/SharedTextField";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import AddLevelQuestion from "./AddLevelQuestion";
import { ExamImagePicker } from "../Course/ExamCreator/ExamImagePicker/ExamImagePicker";
import Modal from "@shared/Modal/Modal";
import { QuestionImagePicker } from "./components/QuestionImagePicker";
import { useParams } from "react-router-dom";
import useDeleteQuestion from "./hooks/useDeleteQuestion";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { RecordPicker } from "./components/RecordPicker";
import { notifyError } from "@shared/Toast/toast";
export default function LevelQuestion() {
  const [activeLevel, setActiveLevel] = useRecoilState(levelAtom);
  const [openModal, setOpenModal] = useState(false);
  const id = useParams()?.id;
  const { data, error, isLoading, isError, refetch } = useCustomQuery({
    url: `level/${id}/question`,
    key: ["allLevel", id],
  });
  console.log(data?.questions, "idddd");
  useEffect(() => {
    if (data?.questions?.data.length < 3) {
      notifyError("عدد الاسئله اقل من 3");
    }
  }, [data?.questions?.data]);
  return (
    <>
      <AddLevelQuestion
        closeModal={() => setOpenModal(false)}
        modal={openModal}
        refetch={refetch}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Stack spacing={2} direction="row">
          <Typography
            fontFamily={"Noor-bold"}
            fontSize={FONT_SIZES.LARGE}
            sx={{ color: COLORS.DARK_GRAY }}
          >
            اسئلة المستوى : {activeLevel?.name}
          </Typography>
        </Stack>
        <Button
          onClick={() => setOpenModal(true)}
          sx={{
            background: COLORS.PRIMARY.MAIN,
            width: "200px",
            padding: "10px 25px",
            color: "white",
            cursor: "pointer",
            transition: "0.4s",
            "&:hover": {
              background: "white",
              color: COLORS.PRIMARY.MAIN,
            },
            position: "relative",
          }}
        >
          انشاء
        </Button>
      </Box>
      {isLoading ? (
        <>
          <Skeleton
            width={"100%"}
            sx={{ marginTop: "-20px" }}
            height={"200px"}
          />
          <Skeleton
            width={"100%"}
            sx={{ marginTop: "-60px" }}
            height={"200px"}
          />
        </>
      ) : (
        data?.questions?.data.map((q) => (
          <>
            <Item
              sx={{
                marginY: 2,
                padding: 3,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // flexDirection: "column",
                "&:hover": {
                  bgcolor: "#f2f2f2",
                  transition: "background-color 0.3s",
                },
              }}
            >
              <Box
                key={q?.id}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  fontFamily={"Noor-bold"}
                  fontSize={FONT_SIZES.LARGE}
                  sx={{ color: COLORS.DARK_GRAY }}
                >
                  {q?.title}
                </Typography>
                <Typography
                  fontFamily={"Noor-bold"}
                  fontSize={FONT_SIZES.MEDIUM}
                  sx={{ color: COLORS.DARK_GRAY }}
                >
                  الاجابات:
                </Typography>
                <Box sx={{ width: "100%", display: "flex", gap: "1rem" }}>
                  {q?.answers.map((ans) => (
                    <Typography
                      fontFamily={"Noor-bold"}
                      fontSize={FONT_SIZES.MEDIUM}
                      sx={{ color: COLORS.DARK_GRAY }}
                    >
                      {ans?.title}
                    </Typography>
                  ))}
                </Box>
                <Typography
                  fontFamily={"Noor-bold"}
                  fontSize={FONT_SIZES.LARGE}
                  sx={{ color: COLORS.DARK_GRAY }}
                >
                  {q?.answers
                    .filter((ans) => ans?.isCorrect == 1)
                    .map((ans) => ` الاجابة الصحيحة : ${ans?.title}`)}
                </Typography>
              </Box>
              <EditLevel level={q} refetch={refetch} />
              <DeleteLevel
                level={q}
                refetch={refetch}
                questions={data?.questions?.data}
              />
            </Item>
          </>
        ))
      )}
    </>
  );
}

export function EditLevel({ level, refetch }) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [exam, setExam] = useState({
    level_id: level?.level_id,
    id: level?.id,
    title: level?.title,
    isPrerequsite: 1,
    grade: "1",
    image: level?.image,
    record: level?.record,
    answers:
      level?.answers?.map((answer) => ({
        title: answer.title,
        isCorrect: answer.isCorrect,
      })) || [],
  });

  const updateLevelSchema = z.object({
    title: z.string().min(1, "يجب ادخال عنوان السؤال "),
    id: z.coerce.number(),
  });

  const addAnswer = () => {
    setExam((prevExam) => ({
      ...prevExam,
      answers: [...prevExam.answers, { title: "", isCorrect: 0 }],
    }));
  };

  const removeAnswer = () => {
    setExam((prevExam) => {
      const updatedAnswers = [...prevExam.answers];
      if (updatedAnswers[updatedAnswers.length - 1].isCorrect === 1) {
        updatedAnswers[updatedAnswers.length - 2].isCorrect = 1;
      }
      updatedAnswers.pop();
      return { ...prevExam, answers: updatedAnswers };
    });
  };

  const handleRadioChange = (answerIndex) => {
    setExam((prevExam) => {
      const updatedAnswers = prevExam.answers.map((answer, index) => ({
        ...answer,
        isCorrect: index === answerIndex ? 1 : 0,
      }));
      return { ...prevExam, answers: updatedAnswers };
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: zodResolver(updateLevelSchema),
    defaultValues: {
      id: level?.id,
      title: level?.title,
      correctAnswer: level?.correctAnswer || "",
      level_id: level?.level_id,
      // record: level?.record,
    },
  });

  const { mutate, isPending } = useUpdateQuestion({
    closeModal: () => setIsConfirmOpen(false),
    refetch,
    id: exam?.id,
  });

  const onSubmit = (data) => {
    const reqFormData = new FormData();
    reqFormData.append("title", exam?.title);
    if (exam?.image instanceof File) {
      reqFormData.append("image", exam.image);
    }

    if (exam?.record && typeof exam?.record === "object") {
      reqFormData.append(`record`, exam.record[0]);
    } else {
      reqFormData.delete("record");
    }

    for (let j = 0; j < exam.answers.length; j++) {
      reqFormData.append(`answers[${j}][title]`, exam.answers[j].title);
      reqFormData.append(
        `answers[${j}][isCorrect]`,
        +exam.answers[j].isCorrect
      );
    }

    mutate(reqFormData);
    console.log(exam, "datadatadata");
    setExam({
      level_id: level?.level_id,
      id: level?.id,
      title: level?.title,
      isPrerequsite: 1,
      grade: "1",
      image: level?.image,
      record: level?.record,
      answers:
        level?.answers?.map((answer) => ({
          title: answer.title,
          isCorrect: answer.isCorrect,
        })) || [],
    });
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setIsConfirmOpen(true);
        }}
        aria-label="delete"
        size="small"
        sx={{
          bgcolor: "#3adb44",
          borderRadius: "15%",
          padding: "7px",
          color: "white",
          display: "flex",
          "&:hover": {
            bgcolor: "#23ad2b",
          },
        }}
      >
        <EditIcon />
      </IconButton>
      <Modal open={isConfirmOpen} closeFn={() => setIsConfirmOpen(false)}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            marginTop: 5,
            width: "60%",
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <Box
            fullWidth
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              label="عنوان السؤال"
              fullWidth
              type="text"
              value={exam?.title}
              onChange={(e) => {
                setExam((prevExam) => ({
                  ...prevExam,
                  title: e.target.value,
                }));
              }}
              error={!exam?.title && !exam?.image}
              helperText={
                !exam?.title &&
                !exam?.image &&
                "يجب إضافة عنوان للسؤال في حالة عدم إضافة صورة"
              }
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#2f8bc8",
                },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: "#2f8bc8",
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: "flex", gap: "5px" }}>
              <QuestionImagePicker
                defaultImage={exam?.image}
                exam={exam}
                setExam={setExam}
              />
              <RecordPicker exam={exam} setExam={setExam} index={0} />
            </Box>
            <RadioGroup
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              value={exam.answers.findIndex((x) => x.isCorrect === 1)}
            >
              {exam.answers.map((answer, i) => (
                <Box
                  key={i}
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >
                  <TextField
                    label={`إجابة ${i + 1}`}
                    type="text"
                    value={answer.title}
                    onChange={(e) => {
                      const updatedAnswers = exam.answers.map((ans, index) =>
                        index === i ? { ...ans, title: e.target.value } : ans
                      );
                      setExam((prevExam) => ({
                        ...prevExam,
                        answers: updatedAnswers,
                      }));
                    }}
                    error={!answer.title}
                    helperText={!answer.title && "يجب إضافة نص اللإجابة"}
                    sx={{
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#2f8bc8",
                      },
                      "& label": {
                        left: "unset",
                        right: "1.75rem",
                        transformOrigin: "right",
                        fontSize: "0.8rem",
                        color: "#2f8bc8",
                      },
                      "& legend": {
                        textAlign: "right",
                        fontSize: "0.6rem",
                      },
                      direction: "rtl",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end"></InputAdornment>
                      ),
                    }}
                  />
                  <Radio
                    value={i.toString()}
                    checked={answer.isCorrect === 1}
                    onChange={() => handleRadioChange(i)}
                  />
                </Box>
              ))}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {exam.answers.length < 4 && (
                  <Button
                    sx={{
                      bgcolor: COLORS.PRIMARY.MAIN,
                      color: COLORS.WHITE,
                      "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
                    }}
                    onClick={addAnswer}
                  >
                    أضف إجابة
                  </Button>
                )}
                {exam.answers.length > 2 && (
                  <Button
                    sx={{
                      bgcolor: COLORS.PRIMARY.MAIN,
                      color: COLORS.WHITE,
                      "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
                    }}
                    onClick={removeAnswer}
                  >
                    إحذف إجابة
                  </Button>
                )}
              </Box>
            </RadioGroup>
          </Box>
          <Button
            type="submit"
            disabled={isPending}
            sx={{
              bgcolor: COLORS.PRIMARY.MAIN,
              color: COLORS.WHITE,
              "&:hover": { bgcolor: COLORS.PRIMARY.MAIN },
            }}
          >
            {isPending ? (
              <HashLoader size={20} color={COLORS.WHITE} />
            ) : (
              "تعديل"
            )}
          </Button>
        </form>
      </Modal>
    </>
  );
}

export function DeleteLevel({ level, refetch, questions }) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  console.log(questions, "questionsquestions");
  const { mutate, isPending: isLoading } = useDeleteQuestion({
    closeModal: () => setIsConfirmOpen(false),
    refetch,
    id: level?.id,
    level_id: level?.level_id,
  });

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setIsConfirmOpen(true);
        }}
        aria-label="delete"
        size="small"
        sx={{
          bgcolor: "#db3a3c",
          borderRadius: "15%",
          color: "#fff",
          padding: "7px",
          marginX: "5px",
          "&:hover": {
            bgcolor: "#a61b1c",
          },
        }}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={() => setIsConfirmOpen(false)}
        open={isConfirmOpen}
      >
        <DialogTitle sx={{ borderTop: "#6358AA 5px solid" }}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setIsConfirmOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent
          sx={{
            borderBottom: "#6358AA 5px solid",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography my={4} fontSize={"24px"}>
            هل انت متأكد انك تريد حذف السؤال {level?.title}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <ColorButton
              sx={{
                bgcolor: "#db3a3c",
                transition: "all 0.3s",
              }}
              variant="contained"
              onClick={(e) => {
                if (questions.length > 3) {
                  mutate(level?.id);
                  e.stopPropagation();
                } else {
                  notifyError("عدد السئله لا يمكن ان يكون اقل من 3");
                }
              }}
            >
              {" "}
              {isLoading ? <ClipLoader color="#000" size={20} /> : "تأكيد"}
            </ColorButton>
            <ColorButton
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                setIsConfirmOpen(false);
              }}
            >
              {" "}
              رجوع
            </ColorButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
