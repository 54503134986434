import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import { useState } from "react";
import {
  Box,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { ClipLoader } from "react-spinners";
import { editModuleSchema } from "./schema/editModuleSchema";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import useUpdateModuleData from "./ModuleDetailsCollapse/hook/useUpdateModuleData";
import EditIcon from "@mui/icons-material/Edit";
import { COLORS } from "@constants/Colors/colors";

export default function EditModule({ module, course }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: module?.name,
      overview: module?.overview,
      price: module?.price,
    },
  });

  const methods = useForm({
    zodResolver: zodResolver(editModuleSchema),
  });

  const [addModuleDialogOpen, setAddModuleDialogOpen] = useState(false);

  const { mutate, isLoading } = useUpdateModuleData({
    closeModal: () => setAddModuleDialogOpen(false),
  });

  function submit(data) {
    mutate({
      ...data,
      id: module?.id,
      price: course?.data?.price == 0 ? 0 : data?.price,
    });
  }

  return (
    <>
      <IconButton
        sx={{ bgcolor: "#01c46e", "&:hover": { bgcolor: "#01c46e" } }}
        onClick={() => setAddModuleDialogOpen(true)}
      >
        <EditIcon fontSize="small" />
      </IconButton>

      <Dialog
        maxWidth="md"
        fullWidth
        onClose={() => setAddModuleDialogOpen(false)}
        open={addModuleDialogOpen}
      >
        <>
          <DialogTitle sx={{ borderTop: "#2f8bc8 5px solid" }}>
            <Button onClick={() => setAddModuleDialogOpen(false)}>
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent
            sx={{
              borderBottom: "#2f8bc8 5px solid",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontFamily={"Noor-bold"} fontSize={"30px"}>
              تعديل الحصة {module?.name}
            </Typography>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submit)} style={{ width: "50%" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    my: 4,
                  }}
                >
                  <TextField
                    registername={"name"}
                    {...register("name", {
                      required: `هذا الحقل مطلوب`,
                    })}
                    placeholder="ادخل اسم الحصة"
                    label="اسم الحصة"
                    fullWidth
                    sx={{
                      direction: "rtl",
                    }}
                  />
                  <FormHelperText sx={{ color: COLORS.DANGER }}>
                    {errors?.name && errors?.name?.message}
                  </FormHelperText>
                  <TextField
                    register={register("overview")}
                    registername={"overview"}
                    {...register("overview", {
                      required: `هذا الحقل مطلوب`,
                    })}
                    placeholder="ادخل وصف الحصة"
                    label="وصف الحصة"
                    fullWidth
                    sx={{
                      direction: "rtl",
                    }}
                  />
                  <FormHelperText sx={{ color: COLORS.DANGER }}>
                    {errors?.overview && errors?.overview?.message}
                  </FormHelperText>

                  {course?.data?.price != 0 && (
                    <>
                      <TextField
                        registername={"price"}
                        {...register("price", {
                          required: `هذا الحقل مطلوب`,
                        })}
                        placeholder="ادخل السعر "
                        label=" السعر"
                        sx={{
                          direction: "rtl",
                        }}
                      />
                      <FormHelperText sx={{ color: COLORS.DANGER }}>
                        {errors?.price && errors?.price?.message}
                      </FormHelperText>
                    </>
                  )}
                </Box>

                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <ColorButton
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    sx={{ px: "20px" }}
                  >
                    {isLoading ? (
                      <ClipLoader color="#000" size={20} />
                    ) : (
                      "تعديل حصة"
                    )}
                  </ColorButton>
                  <ColorButton
                    disabled={isLoading}
                    onClick={() => setAddModuleDialogOpen(false)}
                    variant="contained"
                    sx={{
                      px: "30px",
                      backgroundColor: "white",
                      color: "#2f8bc8",
                      "&:hover": {
                        backgroundColor: "#2f8bc8",
                        color: "#fff",
                      },
                    }}
                  >
                    رجوع{" "}
                  </ColorButton>
                </Box>
              </form>
            </FormProvider>
          </DialogContent>
        </>
      </Dialog>
    </>
  );
}
