export const COLORS = {
  DARK_GRAY: "#2A2A28",
  LIGHT_GRAY: "#EFEFEF",
  WHITE: "#FFFFFF",
  OFF_WHITE: "#F5F5F5",
  PRIMARY: { MAIN: "#63a29b", LIGHT: "#CCE6FF" },
  SECONDARY: { MAIN: "#275151" },
  TERTIARY: { MAIN: "#E41376" },
  SUCCESS: "#80eead",
  DANGER: "#db3a3c",
  BOX: "#d1d1d1",
};
