import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "@constants/Colors/colors";

export default function Modal({
  zIndex,
  children,
  maxWidth,
  open,
  closeFn,
  ...props
}) {
  return (
    <Dialog
      maxWidth={maxWidth ? maxWidth : "md"}
      fullWidth
      onClose={closeFn}
      open={open}
      sx={{
        zIndex: zIndex || "1500",
      }}
    >
      <DialogTitle sx={{ borderTop: `${COLORS.PRIMARY.MAIN} 5px solid` }}>
        <Button onClick={closeFn}>
          <CloseIcon />
        </Button>
      </DialogTitle>{" "}
      <DialogContent
        sx={{
          borderBottom: `${COLORS.PRIMARY.MAIN} 5px solid`,
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}
