import { Box } from "@mui/material";
import Number from "./atoms/Number";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { COLORS } from "@constants/Colors/colors";

export default function Numbers() {
  const { data: systemData, isLoading: systemLoading } = useCustomQuery({
    url: "system/numbers",
    key: "systemNumbers",
    select: (data) => data?.data?.data,
  });
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: "10px",
      }}
    >
      <Number
        name={"كورس"}
        value={systemData?.numberOfCourses}
        hex={COLORS.PRIMARY.MAIN}
      />
      <Number
        name={"طالب"}
        value={systemData?.numberOfStudents}
        hex={COLORS.SECONDARY.MAIN}
      />
      <Number
        name={"اشتراك"}
        value={systemData?.numberOfStudentsInCourses}
        hex={COLORS.TERTIARY.MAIN}
      />
      <Number
        name={"سعة التخزين"}
        value={systemData?.storage + `GB`}
        hex={COLORS.SUCCESS}
      />
      <Number
        name={"الاستهلاك"}
        value={systemData?.bandwidth + `GB`}
        hex={COLORS.PRIMARY.MAIN}
      />
    </Box>
  );
}
