import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../Shared/Toast/toast";
import { encrypt } from "../../../../Shared/Helpers/encryptionHelpers";
import { USER_TOKEN } from "../../../../Constants/tokens";
import useAxiosConfig from "../../../../Shared/Helpers/axios/useAxiosConfig";
import { ROLES } from "../../../../Constants/roles";
import { features } from "@config/features";

export function useLogin({ countryCode }) {
  const navigate = useNavigate();
  const axios = useAxiosConfig();

  const { mutate, error, isError, isPending, status } = useMutation({
    mutationFn: (data) => axios.post(`login`, data),
    onSuccess: (data) => {
      const user = data?.data?.data?.user;

      const userData = encrypt(user);
      window.localStorage.setItem(USER_TOKEN, userData);

      switch (user?.role) {
        case ROLES.ADMIN:
          notifySuccess("تم تسجيل الدخول ك ادمن");
          navigate("/dashboard");
          break;
        case ROLES.TEACHER:
          notifySuccess("تم تسجيل الدخول");
          navigate(`/dashboard/courses/${user?.id}`);
          break;
        case ROLES.SUPERVISOR:
          notifySuccess("تم تسجيل الدخول");
          navigate("/dashboard/courses");
          break;
        default:
          if (!features.student) {
            notifyError("لا يمكنك تسجيل الدخول كطالب");
          } else {
            notifySuccess("تم تسجيل الدخول");
            navigate("/home");
          }
          break;
        // notifyError("لا يمكنك تسجيل الدخول بحساب طالب");
      }
    },
    onError: (error) => {
      notifyError(error.response.data.message);
    },
  });

  const onSubmit = (data) => {
    const customData = {
      password: data.password,
      phone: countryCode + data.phone,
    };
    mutate(customData);
  };
  return {
    status,
    isPending,
    error,
    isError,
    onSubmit,
  };
}
