import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useQuery } from "@tanstack/react-query";
import { api_url } from "@constants/base_url";
import axios from "axios";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { COLORS } from "@constants/Colors/colors";

function BookTeachers({ getSelectedTeacher, selectedTeacher, create }) {
  // all teachers
  const [TeacherPage, setTeacherPage] = useState(1);
  const [totalTeacherPages, setTotalTeacherPages] = useState();
  const {
    data: teacherData,
    error: teacherError,
    isLoading: teacherLoading,
    isError: teacherIsError,
    refetch: teacherRefetch,
  } = useCustomQuery({
    url: `${api_url}book/getAllTeachers?page=${TeacherPage}`,
    page: TeacherPage,
    key: "teachersBook",
    select: (data) => data,
  });

  // if (teacherData) console.log("my  book data", teacherData);

  useEffect(() => {
    if (teacherData?.data?.data?.last_page) {
      const totalCount = teacherData?.data?.data?.last_page;
      setTotalTeacherPages(totalCount);
    }
  }, [teacherData]);

  const handleTeacherPageChange = (event, page) => {
    setTeacherPage(page);
  };

  // droplist function
  const [drop, setDrop] = useState(false);
  const toggleDrop = () => {
    setDrop(!drop);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "-24px",
            right: "15px",
            fontSize: "15px",
            color: COLORS.PRIMARY.MAIN,
            background: "#F5F5F5",
            padding: "7px 15px",
          }}
        >
          المدرس
        </span>
        <Box
          onClick={toggleDrop}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: create === "cutomCreate" ? "20px 15px" : "10px 15px",
            border: `1px solid ${COLORS.PRIMARY.MAIN}`,
            color: "gray",
            width: create === "cutomCreate" ? "400px" : "250px",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <Typography>
            {selectedTeacher === "" || selectedTeacher === null
              ? "اختر اسم المدرس"
              : selectedTeacher}
          </Typography>

          <KeyboardArrowDownIcon />
        </Box>

        <Box
          sx={{
            position: "absolute",
            padding: "10px",
            border: `1px solid ${COLORS.PRIMARY.MAIN}`,
            color: "gray",
            borderRadius: "10px",
            marginTop: "5px",
            width: "100%",
            zIndex: "1500",
            background: "white",
            transition: "0.3s",
            transform: drop ? "translateY(0)" : "translateY(20px)",
            visibility: drop ? "visible" : "hidden",
            opacity: drop ? "1" : "0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "10px 0px",
              marginBottom: "10px",
            }}
          >
            <Pagination
              count={totalTeacherPages}
              color="primary"
              shape="rounded"
              onChange={handleTeacherPageChange}
            />
          </Box>

          <ul
            style={{
              listStyle: "none",
              width: "100%",
              height:
                teacherData?.data?.data?.data?.length > 7 ? "300px" : "auto",
              overflowY: "auto",
              scrollbarWidth: "thin",
              paddingLeft:
                teacherData?.data?.data?.data?.length > 7 ? "10px" : "0px",
            }}
          >
            {teacherLoading ? (
              <li
                className="dropLi"
                style={{
                  padding: "5px 10px",
                  background: "rgba(0, 0, 0, 0.09)",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <Typography>جاري تحميل المدرسين...</Typography>
              </li>
            ) : (
              <>
                {teacherData?.data?.data?.data?.length != 0 ? (
                  <>
                    <li
                      className="dropLi"
                      style={{
                        marginBottom: "10px",
                        padding: "5px 10px",
                        background: "rgba(0, 0, 0, 0.09)",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: create === "cutomCreate" ? "none" : "",
                      }}
                      onClick={() => {
                        getSelectedTeacher("");
                        setDrop(!drop);
                      }}
                    >
                      <Typography>عرض الكل</Typography>
                    </li>
                    {teacherData?.data?.data?.data?.map((i, index) => {
                      return (
                        <li
                          key={i.instructor_name}
                          className="dropLi"
                          style={{
                            marginBottom:
                              index ===
                              teacherData?.data?.data?.data?.length - 1
                                ? "0px"
                                : "10px",
                            padding: "5px 10px",
                            background: "rgba(0, 0, 0, 0.09)",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            getSelectedTeacher(i.instructor_name);
                            setDrop(!drop);
                          }}
                        >
                          <Typography>{i.instructor_name}</Typography>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <li
                    className="dropLi"
                    style={{
                      padding: "5px 10px",
                      background: "rgba(0, 0, 0, 0.09)",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography>لا يوجد مدرسين</Typography>
                  </li>
                )}
              </>
            )}
          </ul>
        </Box>
      </Box>
    </>
  );
}

export default BookTeachers;
