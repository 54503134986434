import { COLORS } from '@constants/Colors/colors'
import { Box, Button, Typography } from '@mui/material'
import useCustomMutation from '@shared/Helpers/hooks/useCustomMutation'
import Modal from '@shared/Modal/Modal'
import { notifySuccess } from '@shared/Toast/toast'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { HashLoader } from 'react-spinners'

export const DeleteBar = ({open, setOpen, id}) => {
    const queryClient = useQueryClient()
    const {mutate, isPending} = useCustomMutation({
        url:`bar/deleteMessage`,
        onSuccess:()=>{
            notifySuccess("تم حذف البانر بنجاح")
            setOpen(false)
            queryClient.invalidateQueries(["bar"])
        }
    })
  return (
    <Modal maxWidth={"sm"} open={open} closeFn={()=>setOpen(false)}>
        <Typography fontSize={30} fontFamily={"Noor-bold"}>هل تريد حذف هذا البانر؟</Typography>
        <Box sx={{display:"flex", gap:2, marginTop:2}}>
            <Button onClick={()=>mutate({id:String(id)})} sx={{bgcolor:COLORS.DANGER, color:COLORS.WHITE,"&:hover":{bgcolor:COLORS.DANGER}}}>{isPending?<HashLoader color={COLORS.WHITE} size={20}/>:"حذف"}</Button>
            <Button onClick={()=>setOpen("false")} sx={{bgcolor:COLORS.PRIMARY.MAIN, color:COLORS.WHITE,"&:hover":{bgcolor:COLORS.PRIMARY.MAIN}}}>الغاء</Button>
        </Box>
    </Modal>
  )
}
