import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { COLORS } from "@constants/Colors/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteExamModal } from "../DeleteExamModal/DeleteExamModal";
import { ExamEditor } from "../../ExamEditor/ExamEditor";
import { useParams } from "react-router-dom";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import { currentUser } from "@shared/Helpers/encryptionHelpers";

export const CourseExamBox = ({ item }) => {
  const [del, setDel] = useState(false);
  const [edit, setEdit] = useState(false);
  const { id } = useParams();
  const { data, isPending } = useCustomQuery({
    url: `exam/getExamAdmin?exam_id=${item?.id}`,
    key: ["course exam", item?.id],
    enabled: edit,
  });
  return (
    <Box
      key={item.id}
      sx={{
        width: "100%",
        height: 100,
        borderRadius: 2,
        color: COLORS.WHITE,
        bgcolor: COLORS.DARK_GRAY,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box marginTop={2} sx={{ display: "flex", gap: 2 }}>
        {currentUser()?.role !== "Supervisor" && (
          <IconButton
            onClick={() => setEdit(true)}
            sx={{
              bgcolor: "#01c46e",
              "&:hover": { bgcolor: "#01c46e" },
              width: 28,
              height: 28,
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        {new Date(item?.starts_at) - new Date() > 0 &&
          currentUser()?.role !== "Supervisor" && (
            <IconButton
              onClick={() => setDel(true)}
              sx={{
                bgcolor: COLORS.DANGER,
                "&:hover": { bgcolor: COLORS.DANGER },
                width: 28,
                height: 28,
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
      </Box>
      <Typography>{item.title}</Typography>
      <DeleteExamModal
        id={item.id}
        isConfirmOpen={del}
        setIsConfirmOpen={setDel}
        confirmMessage={"هل تريد حذف الإمتحان"}
        deleteName={item.title}
      />
      {!isPending && (
        <ExamEditor
          examQuests={data}
          id={id}
          open={edit}
          setOpen={setEdit}
          type={"Course"}
          started={new Date() - new Date(item?.starts_at) >= 0}
        />
      )}
    </Box>
  );
};
