import useCustomMutation from "@/Admin/Shared/Helpers/hooks/useCustomMutation";

export default function useAddCourse({ onSettled }) {
  const { mutate, isPending } = useCustomMutation({
    url: "course/store",
    invalidate: ["courses"],
    method: "post",
    successMsg: "تمت اضافة الكورس بنجاح",
    onSettled,
  });

  function addCourse(data) {
    const formData = new FormData();
    Object.entries(data).forEach((key) => {
      formData.append(key[0], data[key[0]]);
    });
    formData.set("image", data.image[0]);
    mutate(formData);
  }

  return { addCourse, isPending };
}
