import { Box, Typography, Button } from "@mui/material";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { COLORS } from "@constants/Colors/colors";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";

export default function Notifications() {
  const { data: notificationData, isLoading: notificationLoading } =
    useCustomQuery({
      url: "getAllNotifications",
      key: "AllNotifications",
      select: (data) => data?.data?.data,
    });
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        bgcolor: COLORS.WHITE,
        fontFamily: "Noor-regular",
        borderRadius:2,
        boxShadow:"0 0 8px rgba(0,0,0,0.1)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontFamily: "Noor-regular",
        }}
      >
        <Typography sx={{ fontSize: "28px", fontFamily: "Noor-regular", color:COLORS.PRIMARY.MAIN }}>
          الإشعارات
        </Typography>
        <Button
          onClick={() => {
            navigate("notifications");
          }}
          sx={{
            fontFamily: "Noor-regular",
            color: "white",
            bgcolor: COLORS.PRIMARY.MAIN,
            fontSize: "18px",
            "&:hover": { bgcolor: COLORS.SECONDARY.MAIN },
          }}
        >
          عرض الكل
        </Button>
      </Box>
      {notificationLoading && <ClipLoader size={60} />}
      {!notificationLoading &&
        (() => {
          if (notificationData?.length > 0) {
            return (
              <>
                {notificationData?.slice(0, 3).map((item) => (
                  <Typography
                    sx={{
                      margin: "10px",
                      fontSize: "24px",
                      fontFamily: "Noor-regular",
                    }}
                  >
                    {item.body}
                  </Typography>
                ))}
              </>
            );
          } else {
            return (
              <Typography sx={{ fontFamily: "Noor-regular" }}>
                لا توجد إشعارات
              </Typography>
            );
          }
        })()}
    </Box>
  );
}
