import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { COLORS } from "@constants/Colors/colors";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? COLORS.PRIMARY.MAIN : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
