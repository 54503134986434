import { COLORS } from "@constants/Colors/colors";
import { ROLES } from "@constants/roles";
import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import useCustomQuery from "@shared/Helpers/hooks/useCustomQuery";
import CustomNoData from "@shared/Layout/NoDataTableRow/CustomNoData";
import CustomTableHead from "@shared/Layout/TableHead/CustomTableHead";
import CustomTableLoading from "@shared/Layout/TableRowLoading/CustomTableLoading";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import useLocalStorageToken from "@shared/Helpers/axios/useLocalStorageToken";
import DeleteSubscribtion from "./DeleteSubscribtion";

export default function StudentWallet({
  id,
  closeNote,
  noteBook,
  //   refetch,
}) {
  const User = useLocalStorageToken();

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteBook === "wallet" ? "1" : "0",
          visibility: noteBook === "wallet" ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            padding: "30px",
            borderRadius: "10px",
            width: "90%",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteBook === "wallet" ? "1" : "0",
            transform: noteBook === "wallet" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: "#2f8bc8",
                },
              }}
            />
          </div>

          <h2>قريبا</h2>
        </Box>
      </Box>
    </>
  );
}
